import React, { useEffect, useRef, useState } from "react";

import Language from "assets/icons/teya/Language";
import Logout from "assets/icons/teya/Logout";
import Question from "assets/icons/teya/Question";
import User from "assets/icons/teya/User";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { useBreakpointDown } from "hooks/useWindowDimensions";

import { ComposedModal, ModalHeader, Popover, PopoverContent } from "@carbon/react";

import ModalPortal from "../commons/ModalPortal";
import MenuItem from "./MenuItem";

type UserMenu = {
  firstName?: string;
  lastName?: string | null;
  email?: string;
};

const UserMenu = ({ firstName, lastName, email }: UserMenu) => {
  const initials =
    (firstName?.trim().slice(0, 1).toUpperCase() ?? "") +
    (lastName?.trim().slice(0, 1).toUpperCase() ?? "");
  const { t } = useTranslation();
  const isMobile = useBreakpointDown("md");
  const avatarRef = useRef<HTMLButtonElement>(null);
  const userMenuRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isMobile) {
        if (
          avatarRef.current &&
          userMenuRef.current &&
          !avatarRef.current.contains(event.target as Node) &&
          !userMenuRef.current.contains(event.target as Node)
        ) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [avatarRef, isMobile]);

  const menuItems = [
    {
      icon: <User />,
      hasExternalLink: true,
      label: t("Manage account"),
      itemKey: "manage-account",
      url: "https://portal.teya.com/user",
    },
    {
      icon: <Language />,
      label: t("English"),
      itemKey: "language",
    },
    {
      icon: <Question />,
      hasExternalLink: true,
      label: t("Help centre"),
      itemKey: "help-centre",
      url: "https://support.manageyourlocation.com/hc/en-us/categories/360002016698-Listings",
    },
    {
      icon: <Logout />,
      label: t("Log out"),
      itemKey: "log-out",
      url: "https://next-admin.com/api/v3/auth/logout",
      // url: `${window.location.origin}/api/v3/auth/logout` // TODO: need to remove hard code when the project in the cloud
    },
  ];

  const logOutItem = menuItems.find(
    (item) => item.itemKey === "log-out",
  ) as (typeof menuItems)[number];

  const avatar = (
    <button
      type="button"
      ref={avatarRef}
      id="user-avatar"
      aria-label={`${firstName} ${lastName}`}
      className="tw-cursor-pointer tw-appearance-none tw-rounded-full tw-border-none tw-bg-transparent tw-p-0 tw-outline tw-outline-2 tw-outline-primary-charcoal-500"
      onClick={() => setOpen((val) => !val)}
    >
      <span
        className={cx(
          "tw-flex tw-h-8 tw-w-8 tw-cursor-pointer tw-appearance-none tw-items-center tw-justify-center tw-rounded-full tw-p-0 tw-xsmall-semibold hover:tw-bg-primary-charcoal-10",
          {
            "tw-text-primary-pure-white": open,
            "tw-text-primary-charcoal-500": !open,
            "tw-bg-primary-charcoal-500": open,
            "tw-bg-primary-pure-white": !open,
            "tw-pointer-events-none": open,
          },
        )}
      >
        {initials}
      </span>
    </button>
  );

  return (
    <>
      {isMobile ? (
        <>
          {avatar}
          <ModalPortal>
            <ComposedModal
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              className="user-menu-modal"
              id="user-menu-modal"
            >
              <ModalHeader className="tw-mb-0 tw-p-0" />
              <div className="tw-mb-4 tw-flex tw-flex-col tw-justify-between">
                <div>
                  <div className="tw-flex tw-flex-col tw-gap-1 tw-border-b tw-border-solid tw-border-primary-charcoal-10 tw-px-4 tw-py-3 tw-text-primary-charcoal-500">
                    <span
                      id="user-menu-firstname-and-lastname"
                      className="tw-antialiased tw-body-bold"
                    >{`${firstName} ${lastName}`}</span>
                    <span id="user-menu-email" className="tw-antialiased tw-body-regular">
                      {email}
                    </span>
                  </div>
                  {menuItems
                    .filter((item) => item.itemKey !== "log-out")
                    .map((menu) => {
                      return <MenuItem {...menu} />;
                    })}
                </div>

                <MenuItem {...logOutItem} noBottomBorder />
              </div>
            </ComposedModal>
          </ModalPortal>
        </>
      ) : (
        <Popover open={open} align="bottom-right">
          {avatar}
          <PopoverContent
            // @ts-ignore, PopoverContent no ref typing
            ref={userMenuRef}
            className="user-menu tw-overflow-y-auto tw-shadow-md"
            id="user-menu"
          >
            <div className="tw-flex tw-flex-col tw-border-b tw-border-solid tw-border-primary-charcoal-10 tw-p-3 tw-text-primary-charcoal-500">
              <span
                id="user-menu-firstname-and-lastname"
                className="tw-antialiased tw-body-bold"
              >{`${firstName} ${lastName}`}</span>
              <span id="user-menu-email" className="tw-body-regular">
                {email}
              </span>
            </div>
            {menuItems.map((menu, index) => {
              const noBottomBorder = index === menuItems.length - 1;
              return <MenuItem {...menu} noBottomBorder={noBottomBorder} />;
            })}
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export default UserMenu;
