import moment from "moment";
import { stringify } from "query-string";

import fetch, { getHost } from "./fetch";

export const RECEIVE_SUBSCRIPTION = "RECEIVE_SUBSCRIPTION";
export const REQUEST_SUBSCRIPTION = "REQUEST_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION_INSIGHTS = "FETCH_SUBSCRIPTION_INSIGHTS";
export const FETCH_CURRENT_SUBSCRIPTION_INSIGHTS = "FETCH_CURRENT_SUBSCRIPTION_INSIGHTS";

const subscriptionsUrl = () => `${getHost()}/subscriptions`;
const subscriptionStatsUrl = () => `${getHost()}/subscriptions/insights`;

export function receiveSubscription(subscription) {
  return {
    type: RECEIVE_SUBSCRIPTION,
    payload: subscription,
  };
}

export function postSubscription(data) {
  return (dispatch) =>
    fetch(subscriptionsUrl(), {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveSubscription(json)));
}

const DATE_FORMAT = "YYYY-MM-DD";

export function fetchSubscriptionInsights(selectedAccounts = [], startDate = null, endDate = null) {
  const params = stringify(
    {
      "filter[partner_id]": `${selectedAccounts.join(",")}`,
      "filter[from]": moment.isMoment(startDate) ? startDate.format(DATE_FORMAT) : startDate,
      "filter[to]": moment.isMoment(endDate) ? endDate.format(DATE_FORMAT) : endDate,
    },
    { encode: false },
  );

  return (dispatch) =>
    dispatch({
      type: FETCH_SUBSCRIPTION_INSIGHTS,
      payload: fetch(`${subscriptionStatsUrl()}?${params}`).then((res) => res.json()),
    });
}

export function fetchCurrentSubscriptionInsights(selectedAccounts = []) {
  const params = stringify(
    {
      "filter[partner_id]": `${selectedAccounts.join(",")}`,
      "filter[from]": moment().format(DATE_FORMAT),
      "filter[to]": moment().format(DATE_FORMAT),
    },
    { encode: false },
  );

  return (dispatch) =>
    dispatch({
      type: FETCH_CURRENT_SUBSCRIPTION_INSIGHTS,
      payload: fetch(`${subscriptionStatsUrl()}?${params}`).then((res) => res.json()),
    });
}

/**
 * @deprecated use the V3 endpoint instead
 */
export function cancelSubscription(subscriptionId) {
  return (dispatch) =>
    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: fetch(`${subscriptionsUrl()}/${subscriptionId}/cancel`, {
        method: "POST",
      }).then((res) => res.json()),
      meta: { subscriptionId },
    });
}
