import { MutableRefObject, useEffect, useRef, useState } from "react";

import { captureMessage } from "@sentry/react";

import { loadGoogleMapsLib } from "utils/general";

const useGooglePlaceService = (language: string) => {
  const [placeServiceReady, setPlaceServiceReady] = useState(false);
  const placeDetailsServiceRef = useRef<google.maps.places.PlacesService>();
  const placeLibraryRef = useRef<google.maps.PlacesLibrary>();
  const mapLibraryRef = useRef<google.maps.MapsLibrary["Map"]>();
  const markerLibraryRef = useRef<google.maps.MarkerLibrary["AdvancedMarkerElement"]>();

  useEffect(() => {
    const initGoogleMapAPI = async () => {
      await loadGoogleMapsLib({ language });
      const PlacesLibrary = (await google.maps.importLibrary(
        "places",
      )) as google.maps.PlacesLibrary;
      const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        "marker",
      )) as google.maps.MarkerLibrary;
      placeDetailsServiceRef.current = new PlacesLibrary.PlacesService(
        document.createElement("div"),
      );
      placeLibraryRef.current = PlacesLibrary;
      mapLibraryRef.current = Map;
      markerLibraryRef.current = AdvancedMarkerElement;
      setPlaceServiceReady(true);
    };

    try {
      initGoogleMapAPI();
    } catch (err) {
      captureMessage("Import Google Map Library Error.", { extra: { errorReason: err } });
    }
  }, [language]);

  return {
    placeServiceReady,
    placeDetailsServiceRef,
    placeLibraryRef,
    mapLibraryRef,
    markerLibraryRef,
  };
};

export const PlacesServiceStatus = {
  // This request was invalid.
  INVALID_REQUEST: "INVALID_REQUEST",

  // The place referenced was not found.
  NOT_FOUND: "NOT_FOUND",

  // The response contains a valid result.
  OK: "OK",

  // The application has gone over its request quota.
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",

  // The application is not allowed to use the <code>PlacesService</code>.
  REQUEST_DENIED: "REQUEST_DENIED",

  // The <code>PlacesService</code> request could not be processed due to a server error. The request may succeed if you try again.
  UNKNOWN_ERROR: "UNKNOWN_ERROR",

  // No reslt was found for this request.
  ZERO_RESULTS: "ZERO_RESULTS",
};

export const placeDetailsFields = [
  "address_components",
  "formatted_address",
  "international_phone_number",
  "opening_hours",
  "website",
  "geometry",
  "name",
  "place_id",
  "photo",
  "rating",
  "user_ratings_total",
  "url",
  "reviews",
];

export const useGoogleQueryPlaceDetails = ({
  placeDetailsServiceRef,
  placeServiceReady,
  placeOptions,
}: {
  placeDetailsServiceRef: MutableRefObject<google.maps.places.PlacesService | undefined>;
  placeServiceReady: boolean;
  placeOptions: google.maps.places.PlaceDetailsRequest;
}) => {
  const [placeDetailsData, setPlaceDetailsData] = useState<google.maps.places.PlaceResult | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (placeDetailsServiceRef.current && placeServiceReady && placeOptions.placeId) {
      setIsLoading(true);
      placeDetailsServiceRef.current.getDetails(placeOptions, (place, queryStatus) => {
        setIsLoading(false);
        setStatus(queryStatus);
        if (queryStatus === PlacesServiceStatus.OK) {
          setIsError(false);
          setPlaceDetailsData(place);
        }
        if (
          queryStatus === PlacesServiceStatus.ZERO_RESULTS ||
          queryStatus === PlacesServiceStatus.NOT_FOUND
        ) {
          setIsError(false);
          setPlaceDetailsData(null);
        }
        if (
          queryStatus === PlacesServiceStatus.INVALID_REQUEST ||
          queryStatus === PlacesServiceStatus.OVER_QUERY_LIMIT ||
          queryStatus === PlacesServiceStatus.REQUEST_DENIED ||
          queryStatus === PlacesServiceStatus.UNKNOWN_ERROR
        ) {
          setIsError(true);
          captureMessage("Google Place API Error", { extra: { errorReason: queryStatus } });
        }
      });
    }
  }, [placeOptions, placeDetailsServiceRef, placeServiceReady]);

  return { isLoading, data: placeDetailsData, isError, status };
};

export default useGooglePlaceService;
