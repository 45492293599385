import React, { ReactElement, useContext } from "react";

import { CurrentUserType } from "types/UserTypes";

import { useUserQuery } from "hooks/queries/useUserQueries";

import { EmptyObjectType } from "utils/general";

type UserContextType = {
  user: CurrentUserType | EmptyObjectType;
  isLoadingUser: boolean;
} | null;

const UserContext = React.createContext<UserContextType>(null);

const UserProvider = ({ children }: { children: ReactElement }) => {
  const userQuery = useUserQuery();
  const user = userQuery.data?.data || {};
  const isLoadingUser = userQuery.isLoading;

  return <UserContext.Provider value={{ user, isLoadingUser }}>{children}</UserContext.Provider>;
};

const UserConsumer = ({ children }: { children: (context: UserContextType) => ReactElement }) => (
  <UserContext.Consumer>
    {(context) => {
      if (context === undefined) {
        throw new Error("CountConsumer must be used within a UserProvider");
      }
      return children(context);
    }}
  </UserContext.Consumer>
);

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  if (context === null) {
    throw new Error("useUserQuery somehow returned null");
  }
  return context;
};

export { UserProvider, useUser, UserConsumer };
