export const READ_FROM_STORE = "READ_FROM_STORE";
export const SET_ITEM = "SET_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";

export const pendingRequestKey = (index, locationId) =>
  ["pendingRequests", index, locationId].join("_");

export function readFromStore() {
  return {
    type: READ_FROM_STORE,
  };
}

export function setItem(key, item) {
  return {
    type: SET_ITEM,
    key,
    item,
  };
}

export function removeItem(key) {
  return {
    type: REMOVE_ITEM,
    key,
  };
}
