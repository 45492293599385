import isNil from "lodash/isNil";

const requiredValidation = (value) => value === "" || isNil(value);
export const phoneRegex = /^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const emailRegex = /^$|^\S+@\S+$/;
export const colorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

const primaryCategoryValidation = (categories) =>
  Array.isArray(categories) ? requiredValidation(categories[0]) : requiredValidation(categories);

const phoneValidation = (phoneNumber) => {
  if (isNil(phoneNumber)) {
    return false;
  }

  return !phoneRegex.test(phoneNumber);
};
const emailValidation = (email) => {
  if (isNil(email)) {
    return false;
  }

  return !emailRegex.test(email);
};

const urlValidation = (url) => {
  if (isNil(url) || url === "") {
    return false;
  }
  const urlRegex = new RegExp(
    "^$|^https?:\\/\\/(([A-Z0-9][A-Z0-9_-]*)(\\.[A-Z0-9][A-Z0-9_-]*)+)(?::\\d{1,5})?(?:$|[?\\/#])",
    "i",
  );
  return !urlRegex.test(url);
};

const facebookUrlValidation = (url) => {
  if (isNil(url)) {
    return false;
  }
  const urlRegex = new RegExp(
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/,
    "ig",
  );
  return !urlRegex.test(url);
};

export const httpValidation = (url) => {
  if (isNil(url) || url === "") {
    return false;
  }
  const httpRegex = new RegExp("^(http|https)://", "i");
  return !httpRegex.test(url);
};

const timeValidation = (time) => {
  const timeRegex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
  return !timeRegex.test(time);
};

const attributeValidation = (attributes = []) => {
  if (attributes.length === 0) {
    return false;
  }

  const errorsFound = attributes
    .map((attribute) => {
      if (attribute.valueType === "BOOL") {
        return null;
      }
      if (attribute.valueType === "URL" && urlValidation(attribute.value)) {
        return attribute.externalId;
      }
      return requiredValidation(attribute.value);
    })
    .filter(Boolean);
  return Boolean(errorsFound.length);
};

const socialValidation = (socialProfiles = []) => {
  if (socialProfiles.length === 0) {
    return false;
  }
  return socialProfiles.some(({ url = "" }) => {
    if (url.length === 0) {
      return false;
    }
    return httpValidation(url) || urlValidation(url);
  });
};

const minLengthValidation = (value, minLength) => {
  if (typeof value !== "string" && !Array.isArray(value)) {
    return true;
  }
  return value.length < minLength;
};

const maxLengthValidation = (value, maxLength) => {
  if (typeof value !== "string" && !Array.isArray(value)) {
    return true;
  }
  return value.length > maxLength;
};

const validate = (rules, value, t) => {
  const validators = [
    {
      name: "required",
      message: t("This field is required "),
      validatorHandler: requiredValidation,
    },
    { name: "phone", message: t("Invalid phone number "), validatorHandler: phoneValidation },
    { name: "email", message: t("Invalid email address "), validatorHandler: emailValidation },
    { name: "time", message: t("Invalid time "), validatorHandler: timeValidation },
    { name: "url", message: t("Invalid url "), validatorHandler: urlValidation },
    {
      name: "primaryCategory",
      message: t("This field is required "),
      validatorHandler: primaryCategoryValidation,
    },
    {
      name: "facebookUrl",
      message: t("Invalid facebook url "),
      validatorHandler: facebookUrlValidation,
    },
    {
      name: "http",
      message: t("Please make sure your URL begins with ‘http://’ or 'https:// "),
      validatorHandler: httpValidation,
    },
    {
      name: "attributes",
      message: t("One or more invalid attributes fields"),
      validatorHandler: attributeValidation,
    },
    {
      name: "socialProfiles",
      message: t("One or more invalid fields"),
      validatorHandler: socialValidation,
    },
    // only usable with object rules
    {
      name: "minLength",
      message: (number) => t("Min {number} characters", { number }),
      validatorHandler: minLengthValidation,
    },
    {
      name: "maxLength",
      message: (number) => t("Max {number} characters", { number }),
      validatorHandler: maxLengthValidation,
    },
  ];

  // array validation check
  if (Array.isArray(rules)) {
    let errors = "";
    validators.forEach((validator) => {
      if (rules.includes(validator.name)) {
        if (validator.validatorHandler(value)) {
          errors += validator.message;
        }
      }
    });
    return errors;
  }

  // object validation check
  const errors = [];
  Object.entries(rules).forEach(([rule, options]) => {
    // find validator
    const validator = validators.find((key) => key.name === rule);
    if (validator && validator.validatorHandler(value, options)) {
      // function are to allow messages with values like numbers
      const message =
        typeof validator.message === "function" ? validator.message(options) : validator.message;
      errors.push(message);
    }
  });

  return errors.length ? errors.shift() : ""; // just use the first message of the array if there are any errors
};

export default validate;
