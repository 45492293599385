const langCodeMapping = {
  af: "Afrikaans",
  am: "Amarico",
  an: "Aragonese",
  ar: "Arabo",
  as: "Assamese",
  az: "Azerbaigiano",
  be: "Bielorusso",
  bg: "Bulgaro",
  bn: "Bengalese",
  br: "Bretone",
  bs: "Bosniaco",
  ca: "Catalano",
  cs: "Ceco",
  cy: "Gallese",
  da: "Danese",
  de: "Tedesco",
  dz: "Dzongkha",
  el: "Greco",
  en: "Inglese",
  eo: "Esperanto",
  es: "Spagnolo",
  et: "Estone",
  eu: "Basco",
  fa: "Persiano",
  fi: "Finlandese",
  fo: "Faroese",
  fr: "Francese",
  ga: "Irlandese",
  gl: "Galiziano",
  gu: "Gujarati",
  he: "Ebraico",
  hi: "Hindi",
  hr: "Croato",
  ht: "Haitiano",
  hu: "Ungherese",
  hy: "Armeno",
  id: "Indonesiano",
  is: "Islandese",
  it: "Italiano",
  ja: "Giapponese",
  jv: "Giavanese",
  ka: "Georgiano",
  kk: "Kazako",
  km: "Khmer",
  kn: "Kannada",
  ko: "Coreano",
  ku: "Curdo",
  ky: "Kirghiso",
  la: "Latino",
  lb: "Lussemburghese",
  lo: "Lao",
  lt: "Lituano",
  lv: "Lettone",
  mg: "Malgascio",
  mk: "Macedone",
  ml: "Malayalam",
  mn: "Mongolo",
  mr: "Marathi",
  ms: "Malese",
  mt: "Maltese",
  nb: "Norvegese bokm\u00e5l",
  ne: "Nepalese",
  nl: "Olandese",
  nn: "Norvegese nynorsk",
  no: "Norvegese",
  oc: "Occitano",
  or: "Odia",
  pa: "Punjabi",
  pl: "Polacco",
  ps: "Pashto",
  pt: "Portoghese",
  qu: "Quechua",
  ro: "Rumeno",
  ru: "Russo",
  rw: "Kinyarwanda",
  se: "Sami del nord",
  si: "Singalese",
  sk: "Slovacco",
  sl: "Sloveno",
  sq: "Albanese",
  sr: "Serbo",
  sv: "Svedese",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tl: "Tagalog",
  tr: "Turco",
  ug: "Uiguro",
  uk: "Ucraino",
  ur: "Urdu",
  vi: "Vietnamita",
  vo: "Volap\u00fck",
  wa: "Vallone",
  xh: "Xhosa",
  zh: "Cinese",
  zu: "Zulu",
};

export default langCodeMapping;
