const langCodeMapping = {
  af: "Afric\u00e2ner",
  am: "Am\u00e1rico",
  an: "Aragon\u00eas",
  ar: "\u00e1rabe",
  as: "Assam\u00eas",
  az: "Azerbaijano",
  be: "Bielorrusso",
  bg: "B\u00falgaro",
  bn: "Bengali",
  br: "Bret\u00e3o",
  bs: "B\u00f3snio",
  ca: "Catal\u00e3o",
  cs: "Tcheco",
  cy: "Gal\u00eas",
  da: "Dinamarqu\u00eas",
  de: "Alem\u00e3o",
  dz: "Dzonga",
  el: "Grego",
  en: "Ingl\u00eas",
  eo: "Esperanto",
  es: "Espanhol",
  et: "Estoniano",
  eu: "Basco",
  fa: "Persa",
  fi: "Finland\u00eas",
  fo: "Fero\u00eas",
  fr: "Franc\u00eas",
  ga: "Irland\u00eas",
  gl: "Galego",
  gu: "Guzerate",
  he: "Hebraico",
  hi: "H\u00edndi",
  hr: "Croata",
  ht: "Haitiano",
  hu: "H\u00fangaro",
  hy: "Arm\u00eanio",
  id: "Indon\u00e9sio",
  is: "Island\u00eas",
  it: "Italiano",
  ja: "Japon\u00eas",
  jv: "Javan\u00eas",
  ka: "Georgiano",
  kk: "Cazaque",
  km: "Khmer",
  kn: "Canarim",
  ko: "Coreano",
  ku: "Curdo",
  ky: "Quirguiz",
  la: "Latim",
  lb: "Luxemburgu\u00eas",
  lo: "Laosiano",
  lt: "Lituano",
  lv: "Let\u00e3o",
  mg: "Malgaxe",
  mk: "Maced\u00f4nio",
  ml: "Malaiala",
  mn: "Mongol",
  mr: "Marati",
  ms: "Malaio",
  mt: "Malt\u00eas",
  nb: "Bokm\u00e5l noruegu\u00eas",
  ne: "Nepal\u00eas",
  nl: "Holand\u00eas",
  nn: "Nynorsk noruegu\u00eas",
  no: "Noruegu\u00eas",
  oc: "Occit\u00e2nico",
  or: "Ori\u00e1",
  pa: "Panjabi",
  pl: "Polon\u00eas",
  ps: "Pashto",
  pt: "Portugu\u00eas",
  qu: "Qu\u00edchua",
  ro: "Romeno",
  ru: "Russo",
  rw: "Quiniaruanda",
  se: "Sami setentrional",
  si: "Cingal\u00eas",
  sk: "Eslovaco",
  sl: "Esloveno",
  sq: "Alban\u00eas",
  sr: "S\u00e9rvio",
  sv: "Sueco",
  sw: "Sua\u00edli",
  ta: "T\u00e2mil",
  te: "T\u00e9lugo",
  th: "Tailand\u00eas",
  tl: "Tagalo",
  tr: "Turco",
  ug: "Uigur",
  uk: "Ucraniano",
  ur: "Urdu",
  vi: "Vietnamita",
  vo: "Volapuque",
  wa: "Val\u00e3o",
  xh: "Xhosa",
  zh: "Chin\u00eas",
  zu: "Zulu",
};

export default langCodeMapping;
