import { captureMessage } from "@sentry/react";
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { reactQueryFetch as fetch, getHost, getV3Host } from "actions/fetch";
import isNil from "lodash/isNil";
import { stringify } from "query-string";
import meSchema from "schemas/meSchema";
import bspSchema from "schemas/userSchemas";
import { z } from "zod";

import { requestAndValidate } from "utils/general";

const userKeys = {
  user: () => ["user", "general"] as const,
  bsps: (userId: number, query?: string) => ["bsps", { userId, query }] as const,
  userNotificationPreferences: () => ["user", "notificationPreferences"] as const,
};

type QueryReturnType<K extends keyof typeof userKeys> = QueryFunctionContext<
  ReturnType<(typeof userKeys)[K]>
>;

async function fetchMe() {
  return requestAndValidate({
    invalidMessage: "Slash me not match with spec",
    requestUrl: `${getV3Host()}/me`,
    schema: meSchema,
  });
}

async function fetchUserNotificationPreferences() {
  const data = await fetch(`${getHost()}/users/notifications`);
  return data.json();
}

async function fetchUserBsps({ queryKey }: QueryReturnType<"bsps">) {
  const [, { userId, query }] = queryKey;

  const data = await fetch(
    `${getHost()}/customers/${userId}/bsp${query ? `?${stringify({ query })}` : ""}`,
  );
  const jsonData = await data.json();
  const parsedData = bspSchema.safeParse(jsonData);
  if (!parsedData.success) {
    captureMessage("user data not up to spec");
    return jsonData as z.infer<typeof bspSchema>;
  }
  return parsedData.data;
}

// changed the name format since useUser is already
//  used by the context hook that extracts this data
export const useUserQuery = () =>
  useQuery({
    queryKey: userKeys.user(),
    queryFn: fetchMe,
    cacheTime: 30 * 60 * 1000,
  });

export const useUserNotifications = () =>
  useQuery({
    queryKey: userKeys.userNotificationPreferences(),
    queryFn: fetchUserNotificationPreferences,
  });

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userData) =>
      fetch(`${getHost()}/users/me`, {
        method: "PATCH",
        body: JSON.stringify(userData),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user());
    },
  });
};

export const useUpdateUserPassword = () =>
  useMutation({
    mutationFn: (userData) =>
      fetch(`${getHost()}/users/me/password`, {
        method: "POST",
        body: JSON.stringify(userData),
      }),
  });

export const useUpdateNotificationPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userData) =>
      fetch(`${getHost()}/users/me/notifications`, {
        method: "PATCH",
        body: JSON.stringify(userData),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.userNotificationPreferences());
    },
  });
};

export const useUserBsps = (userId: number, query?: string) =>
  useQuery({
    queryFn: fetchUserBsps,
    queryKey: userKeys.bsps(userId, query),
    enabled: !isNil(userId),
    retry: 1,
  });
