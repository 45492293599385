import { ActionType } from "redux-promise-middleware";

import productName from "utils/products";

import {
  CANCEL_SUBSCRIPTION,
  FETCH_CURRENT_SUBSCRIPTION_INSIGHTS,
  FETCH_SUBSCRIPTION_INSIGHTS,
  RECEIVE_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION,
} from "../actions";

function subscriptionReducer(
  state = {
    isFetching: false,
    payload: {},
  },
  action,
) {
  switch (action.type) {
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Pending}`:
    case REQUEST_SUBSCRIPTION:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        errors: null,
      };
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Rejected}`:
      return {
        ...state,
        errors: action.payload.messageData.errors,
        isFetching: false,
        didInvalidate: false,
      };
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Fulfilled}`:
    case RECEIVE_SUBSCRIPTION:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        subscription: action.payload,
        errors: null,
      };
    default:
      return state;
  }
}

const formatSubscriptionInsights = (payload) => {
  const getDefaultList = () => {
    const emptyList = {};
    Object.values(productName).forEach((product) => {
      emptyList[product] = [];
    });
    return emptyList;
  };
  const active = getDefaultList();
  const churn = getDefaultList();
  const trial = getDefaultList();

  payload.forEach((entry) => {
    // We get websiteoptimizer from the payload instead of weboptimizer. Here we update the key
    // if the productname is websiteoptimizer
    const key = entry.product === "websiteoptimizer" ? "weboptimizer" : entry.product;
    active[key]?.push({ date: entry.date, value: entry.active });
    churn[key]?.push({ date: entry.date, value: entry.churn });
    trial[key]?.push({ date: entry.date, value: entry.trial });
  });
  return { active, churn, trial };
};

export default function subscriptions(state = {}, action) {
  const { type, payload = {}, meta = {} } = action;
  const subscriptionId = payload.id || meta.subscriptionId;
  switch (type) {
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Pending}`:
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Rejected}`:
    case `${CANCEL_SUBSCRIPTION}_${ActionType.Fulfilled}`:
    case REQUEST_SUBSCRIPTION:
    case RECEIVE_SUBSCRIPTION:
      return {
        ...state,
        [subscriptionId]: subscriptionReducer(state[subscriptionId], action),
      };
    case `${FETCH_SUBSCRIPTION_INSIGHTS}_${ActionType.Pending}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          isFetching: true,
        },
      };
    case `${FETCH_SUBSCRIPTION_INSIGHTS}_${ActionType.Rejected}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          isFetching: false,
        },
      };
    case `${FETCH_SUBSCRIPTION_INSIGHTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          ...formatSubscriptionInsights(action.payload.data),
          meta: action.payload.meta,
          isFetching: false,
        },
      };
    case `${FETCH_CURRENT_SUBSCRIPTION_INSIGHTS}_${ActionType.Pending}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          current: {
            ...state.insights?.current,
            isFetching: true,
          },
        },
      };
    case `${FETCH_CURRENT_SUBSCRIPTION_INSIGHTS}_${ActionType.Rejected}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          current: {
            ...state.insights?.current,
            isFetching: false,
          },
        },
      };
    case `${FETCH_CURRENT_SUBSCRIPTION_INSIGHTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        insights: {
          ...state.insights,
          current: {
            data: action.payload.data,
            meta: action.payload.meta,
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
}
