import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

const CardBody = (props) => {
  const { children, className, unpadded } = props;
  const classes = cx("card-body", { "p-0": unpadded }, className);
  return <div className={classes}>{children}</div>;
};

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  unpadded: PropTypes.bool,
};

CardBody.defaultProps = {
  children: "",
  className: "",
  unpadded: false,
};

export default CardBody;
