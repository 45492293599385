import { captureException } from "@sentry/react";

import { getCookie } from "hooks/useCookieWatcher";

import log, { LOG_LEVEL } from "../utils/logger";

export const getHost = () => import.meta.env.VITE_API_HOST;
export const getV3Host = () => import.meta.env.VITE_API_HOST_V3;

export class FetchError extends Error {
  constructor(response, message) {
    const code = response.status;
    const text = response.statusText;
    super(`Fetch request failed with code ${code} : ${text}`);

    this.status = code;
    this.message = text;
    this.messageData = message;
  }
}

// add default hostname as current hostname, but still add the parameter for unit test
export const isTeyaDomain = (hostname = window.location.hostname) => {
  const allowDomainRegex = new RegExp("(teya\\.xyz|teya\\.com|next-admin\\.soconnect\\.com)$", "i");
  // todo remove this
  return true;
};

export const handle401Redirection = (status) => {
  if (status === 401) {
    if (isTeyaDomain()) {
      const windowOrigin = window.location.origin;
      const windowSearch = decodeURI(window.location.search);
      const destinationUrl = `${window.location.pathname.slice(1)}${windowSearch}`;
      const teyaAuthUrl = `${windowOrigin}/api/v3/auth/teya-id?destination=${destinationUrl}`;
      window.location.href = teyaAuthUrl;
    } else {
      // window.location.reload();
    }
  }
};

export const handleFetchError = (err) => {
  handle401Redirection(err.status);
};

const csrfToken = getCookie("XSRF-TOKEN");

export const reactQueryFetch = (url, options = {}, useCsrfToken = true) => {
  const csrfParams = useCsrfToken
    ? {
        mode: "cors",
        headers: {
          Accept: "application/json",
          origin: window.location.origin,
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": csrfToken,
        },
      }
    : {};
  return fetch(url, {
    headers: {
      Accept: "application/json",
      origin: window.location.origin,
      "Content-Type": "application/json",
    },
    credentials: "include",
    ...csrfParams,
    ...options,
  }).then((response) => {
    const { status } = response;

    if (status >= 400) {
      // handle401Redirection(status);

      return response.json().then((err) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...err, status });
      });
    }
    return response;
  });
};

export default (url, options = {}) => {
  let apiUrl = url;

  if (apiUrl.startsWith("/api/")) {
    apiUrl = `https://local.boost.teya.xyz${url}`;
  }
  return fetch(apiUrl, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    ...options,
  }).then((response) => {
    const { status } = response;

    if (status >= 400) {
      return response
        .json()
        .then((data) => {
          const err = new FetchError(response, data);
          log(
            "Fetch error",
            {
              data,
              status,
              url: apiUrl,
              options,
            },
            LOG_LEVEL.ERROR,
          );
          throw err;
        })
        .then((data, onRejected) => {
          // Added an onRejected to see if sentry will see this as a handled rejection
          onRejected(() => {
            captureException(data, {
              tags: {
                data,
                status,
                url: apiUrl,
                options,
                code: data.status,
                errorText: data?.messageData?.errors?.[0],
              },
            });
          });
          return data;
        });
    }
    return response;
  });
};
