import React from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type StoreItemProps = {
  storeId: string;
  isSelected: boolean;
  description: string;
  isOnboard?: boolean;
  isMobile?: boolean;
};

const StoreItem = ({ storeId, isSelected, description, isOnboard, isMobile }: StoreItemProps) => {
  const { t } = useTranslation();

  const storeLink = isOnboard
    ? `/admin/app/location/${storeId}/listings/results`
    : `/admin/app/onboarding/${storeId}/store`;

  return (
    <Link
      key={storeId}
      to={storeLink}
      data-testid="store-item"
      className={cx(
        "tw-block tw-min-w-72 tw-cursor-pointer tw-border-b tw-border-solid tw-border-primary-charcoal-10 tw-px-4 tw-py-3 tw-text-primary-charcoal-500 tw-no-underline hover:tw-bg-primary-off-white",
        {
          "tw-pointer-events-none tw-bg-primary-lime-50": isSelected,
          "tw-body-semibold": isMobile,
          "tw-body-regular ": !isMobile,
        },
      )}
    >
      <div>{description}</div>
      <div className={cx("tw-small-regular", { "tw-text-primary-charcoal-100": isMobile })}>
        {t("ID")}: {storeId}
      </div>
    </Link>
  );
};

export default StoreItem;
