import i18n from "i18next";
import csTranslations from "lang/cs.json";
import deTranslations from "lang/de.json";
import enTranslations from "lang/en.json";
import esTranslations from "lang/es.json";
import frTranslations from "lang/fr.json";
import itTranslations from "lang/it.json";
import nlTranslations from "lang/nl.json";
import ptBrTranslations from "lang/pt-br.json";
// this can be removed the moment pt is removed
import ptTranslations from "lang/pt.json";
import trTranslations from "lang/tr.json";
import { initReactI18next } from "react-i18next";

import { getSetLanguage } from "utils/languages";

const resources = {
  en: {
    translation: enTranslations || {},
  },
  fr: {
    translation: frTranslations || {},
  },
  nl: {
    translation: nlTranslations || {},
  },
  de: {
    translation: deTranslations || {},
  },
  // make sure to make "BR" capitalized: https://www.i18next.com/how-to/faq#how-should-the-language-codes-be-formatted
  "pt-BR": {
    translation: ptBrTranslations || {},
  },
  pt: {
    translation: ptTranslations || {},
  },
  it: {
    translation: itTranslations || {},
  },
  es: {
    translation: esTranslations || {},
  },
  tr: {
    translation: trTranslations || {},
  },
  cs: {
    translation: csTranslations || {},
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getSetLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  saveMissing: true,
  returnEmptyString: false,
});

export default i18n;
