import all from "./all";
import cs from "./cs";
import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import it from "./it";
import nl from "./nl";
import pt from "./pt";
import tr from "./tr";

export default { all, cs, de, en, es, fr, it, nl, pt, tr };
