import { z } from "zod";

import { paginationSchema } from "./utilSchemas";

export const locationDataV3Schema = z.object({
  id: z.number(),
  name: z.string().min(1),
  phone: z.string().nullable(),
  timezone: z.string().nullable(),
  external_reference: z.string().nullable(),
  comment_admin: z.string().nullable(),
  comment: z.string().nullable(),
  account: z
    .object({
      id: z.number(),
      name: z.string(),
      listings_business_id: z.string(),
      is_demo: z.boolean(),
    })
    .nullable(),
  address: z
    .object({
      address_line: z.string().nullable(),
      address_line_extra: z.string().nullable(),
      postal_code: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string().nullable(),
    })
    .nullable(),
  advanced_settings: z.object({
    place_id: z.string().nullable(),
    listing_id: z.number().nullable(),
    property_id: z.number().nullable(),
    mcc_code: z.string().nullable(),
    listings_category_id: z.number().nullable(),
  }),
});

export const locationV3Schema = z.object({
  data: locationDataV3Schema,
});

export const locationV3STolegacySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
  phone: z.string().nullable(),
  timezone: z.string().nullable(),
  external_reference: z.string().nullable(),
  comment_admin: z.string().nullable(),
  comment: z.string().nullable(),
  account: z
    .object({
      id: z.number(),
      name: z.string(),
      listings_business_id: z.string(),
      is_demo: z.boolean(),
    })
    .nullable(),
  address: z.string().nullable(),
  address2: z.string().nullable(),
  postalcode: z.string().nullable(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  property_id: z.number().nullable(),
  listing_id: z.number().nullable(),
  place_id: z.string().nullable(),
});

export const locationSchema = z.object({
  account: z.object({ id: z.number(), name: z.string() }).nullable(),
  address_line: z.string().nullable(),
  address_line_extra: z.string().nullable(),
  city: z.string().nullable(),
  comment_admin: z.string().nullable(),
  comment: z.string().nullable(),
  country: z.string().nullable(),
  external_reference: z.string().nullable(),
  id: z.number(),
  name: z.string().min(1),
  phone: z.string().nullable(),
  place_id: z.string().nullable(),
  postal_code: z.string().nullable(),
  timezone: z.string(),
});

export const createLocationModalSchema = z.object({
  address_line: z.string().nullish(),
  address_line_extra: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  external_reference: z.string().nullish(),
  id: z.string().nullable().optional(),
  name: z.string().min(1, { message: "Location name is required" }),
  place_id: z.string().nullish(),
  postal_code: z.string().nullish(),
});

export const locationsDataSchema = z.object(
  {
    account: z
      .object({ id: z.string(), name: z.string(), created_at: z.string().nullable() })
      .nullable(),
    active_products: z.array(z.string()),
    address_line: z.string().nullable(),
    city: z.string().nullable(),
    country: z.string().nullable(),
    external_reference: z.string().nullable(),
    id: z.string(),
    name: z.string().min(1, { message: "Please assign location name" }),
    phone: z.string().nullable(),
    postal_code: z.string().nullable(),
    status: z.array(z.string()),
  },
  {
    errorMap: () => ({ message: "Please select a location" }),
  },
);

export const createLocationSchema = createLocationModalSchema.extend({
  account_id: z.number(),
});

export const locationAndSubscriptionSchema = z.object({
  customerPlanId: z
    .number({
      required_error: "Please select a subscription",
      invalid_type_error: "Please select a subscription",
    })
    .nullable()
    .refine(
      (val) => {
        if (val === null) {
          return false;
        }

        return true;
      },
      { message: "Please select a subscription" },
    ),
  location: z
    .union([createLocationModalSchema, locationsDataSchema])
    .nullable()
    .refine(
      (val) => {
        if (val === null) {
          return false;
        }

        return true;
      },
      { message: "Please select or create a location" },
    ),
});

export const locationSubscriptionsSchema = z.array(
  z
    .object({
      client_name: z.string(),
      id: z.number(),
      is_trial: z.union([z.boolean(), z.string()]),
      // not sure if payment_status is still used
      payment_status: z.string().optional(),
      product: z.enum(["listing", "soreview", "sowifi"]),
      status: z.enum(["active", "ended", "deleted"]),
      template_id: z.number(),
    })
    .optional(),
);

export const websiteAndSubscriptionSchema = z.object({
  customerPlanId: z.number({
    required_error: "Please select a subscription",
    invalid_type_error: "Please select a subscription",
  }),
  website: z
    .string({
      required_error: "Website url is required",
      invalid_type_error: "Website url is required",
    })
    .url(),
});

export const locationsSchema = z.object({
  data: z.array(locationsDataSchema),
  meta: paginationSchema,
});

export const legacyLocationSchema = z.object({
  id: z.number(),
  device_id: z.string(),
  device_status: z.string(),
  parent_id: z.string().nullable(),
  name: z.string(),
  status: z.string(),
  type: z.string(),
  facebook_page_id: z.string().nullable(),
  facebook_page_url: z.string().nullable(),
  facebook_place_id: z.string().nullable(),
  auth_email_country: z.string(),
  instant_access_enabled: z.string(),
  twitter_user_id: z.string().nullable(),
  instagram_user_id: z.string().nullable(),
  instagram_username: z.string().nullable(),
  tripadvisor_url: z.string().nullable(),
  linkedin_company_id: z.string().nullable(),
  vk_community_id: z.string().nullable(),
  email_mandatory: z.string(),
  google_login_enabled: z.string(),
  app_id_android: z.string().nullable(),
  app_id_ios: z.string().nullable(),
  autologin_timeout: z.string(),
  lat: z.string().nullable(),
  lon: z.string().nullable(),
  manual_lat: z.string().nullable(),
  manual_lon: z.string().nullable(),
  partner_ref: z.string().nullable(),
  partner_location_id: z.string().nullable(),
  tripadvisor_id: z.string().nullable(),
  timezone: z.string().nullable(),
  external_ref: z.string().nullable(),
  portal_display_name: z.string().nullable(),
  portal_display_description: z.string().nullable(),
  portal_display_phone: z.string().nullable(),
  portal_display_website: z.string().nullable(),
  cover_url: z.string().nullable(),
  address: z.string(),
  address2: z.string().nullable(),
  postalcode: z.string(),
  city: z.string(),
  country: z.string(),
  property_id: z.string().nullable(),
  listing_id: z.string(),
  place_id: z.string(),
  network_over_wan: z.string(),
  watchlist: z.string(),
  account: z.object({
    listings_business_id: z.string(),
    listings_plan_id: z.string(),
    listings_default_plan_id: z.string().nullable(),
    is_corporate: z.string(),
    location_analytics_enabled: z.string(),
    agree_terms: z.string(),
    provision_email: z.string().nullable(),
    can_add_venue: z.string(),
    updated_at: z.string(),
    allow_add_devices: z.string(),
    is_high_profile: z.string(),
    is_demo: z.string(),
    send_customers_communication: z.string(),
    id: z.string(),
    industry_id: z.string().nullable(),
    type: z.string().nullable(),
    name: z.string(),
    address: z.string(),
    address2: z.string().nullable(),
    postalcode: z.string(),
    city: z.string(),
    country: z.string(),
    phone: z.string(),
    email: z.string().nullable(),
    website: z.string(),
    domain_parts: z.string().nullable(),
    optin_company_name: z.string().nullable(),
    reseller_id: z.string().nullable(),
    reseller_code: z.string(),
    reseller_name: z.string(),
    customer_since: z.string().nullable(),
    last_login: z.string().nullable(),
    primary_user_id: z.string(),
    sales_user_id: z.string().nullable(),
    subscription_editor: z.string().nullable(),
    is_reseller: z.string(),
    external_reference: z.string().nullable(),
    deleted_at: z.string().nullable(),
    created_at: z.string(),
    cancel_days: z.string().nullable(),
    server_host: z.string().nullable(),
    kb_url: z.string().nullable(),
    support_url: z.string().nullable(),
    phone_support: z.string().nullable(),
    email_support: z.string().nullable(),
    display_name: z.string().nullable(),
    whitelabel_primary_color: z.string().nullable(),
    whitelabel_secondary_color: z.string().nullable(),
    whitelabel_icon: z.string().nullable(),
    system_email: z.string().nullable(),
    is_whitelabel: z.string().nullable(),
    skin_name: z.string().nullable(),
    date_format: z.string(),
    locale: z.string(),
    delivery_name: z.string().nullable(),
    delivery_address: z.string().nullable(),
    delivery_address2: z.string().nullable(),
    delivery_postalcode: z.string().nullable(),
    delivery_city: z.string().nullable(),
    delivery_country: z.string().nullable(),
    group_locations: z.string().nullable(),
    has_promotions: z.string().nullable(),
    timezone: z.string(),
    place_id: z.string(),
    dashboard_domain: z.string().nullable(),
    sender_email: z.string().nullable(),
    cancellation_email: z.string().nullable(),
    social_media: z.string().nullable(),
    admin_metadata: z.string().nullable(),
  }),
});

export const locationOnboardingInsightsSchema = z.object({
  account_created: z.coerce.date(),
  last_engagement: z.coerce.date().nullable(),
  products: z.object({
    listing: z.object({
      active: z.boolean(),
      onboarded: z.boolean(),
      subscription_id: z.number(),
      // can be "read" or "sent"
      onboarding_email_status: z.union([z.literal("sent"), z.literal("read")]),
      data_enriched: z.boolean(),
      automated_seo_improvements: z.boolean(),
      profile_completeness: z.number().nullable(),
      performance_scan: z
        .object({
          correct: z.string().nullable(),
          incorrect: z.string().nullable(),
          missing: z.string().nullable(),
        })
        .nullable(),
      active_passive_status: z.boolean(),
    }),
  }),
});

export default locationSchema;
