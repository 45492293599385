import isEmpty from "lodash/isEmpty";
import { stringify } from "query-string";

export function filtersToKey({ monitorId, timeRange, language, country } = {}) {
  return [monitorId, timeRange ? Object.values(timeRange) : null, language, country].join("_");
}

/**
 * Takes query params and turns them into hash
 * to be used as a key in the store
 *
 * @param params object
 * @returns {string}
 */
export const paramsToHash = (params) => {
  if (!isEmpty(params)) {
    return btoa(stringify(params));
  }
  return "no-params";
};
