const langCodeMapping = {
  af: "Afrikaans",
  am: "Amharique",
  an: "Aragonais",
  ar: "Arabe",
  as: "Assamais",
  az: "Az\u00e9ri",
  be: "Bi\u00e9lorusse",
  bg: "Bulgare",
  bn: "Bengali",
  br: "Breton",
  bs: "Bosniaque",
  ca: "Catalan",
  cs: "Tch\u00e8que",
  cy: "Gallois",
  da: "Danois",
  de: "Allemand",
  dz: "Dzongkha",
  el: "Grec",
  en: "Anglais",
  eo: "Esp\u00e9ranto",
  es: "Espagnol",
  et: "Estonien",
  eu: "Basque",
  fa: "Persan",
  fi: "Finnois",
  fo: "F\u00e9ro\u00efen",
  fr: "Fran\u00e7ais",
  ga: "Irlandais",
  gl: "Galicien",
  gu: "Goudjerati",
  he: "H\u00e9breu",
  hi: "Hindi",
  hr: "Croate",
  ht: "Cr\u00e9ole ha\u00eftien",
  hu: "Hongrois",
  hy: "Arm\u00e9nien",
  id: "Indon\u00e9sien",
  is: "Islandais",
  it: "Italien",
  ja: "Japonais",
  jv: "Javanais",
  ka: "G\u00e9orgien",
  kk: "Kazakh",
  km: "Khmer",
  kn: "Kannada",
  ko: "Cor\u00e9en",
  ku: "Kurde",
  ky: "Kirghize",
  la: "Latin",
  lb: "Luxembourgeois",
  lo: "Lao",
  lt: "Lituanien",
  lv: "Letton",
  mg: "Malgache",
  mk: "Mac\u00e9donien",
  ml: "Malayalam",
  mn: "Mongol",
  mr: "Marathi",
  ms: "Malais",
  mt: "Maltais",
  nb: "Norv\u00e9gien bokm\u00e5l",
  ne: "N\u00e9palais",
  nl: "N\u00e9erlandais",
  nn: "Norv\u00e9gien nynorsk",
  no: "Norv\u00e9gien",
  oc: "Occitan",
  or: "Odia",
  pa: "Pendjabi",
  pl: "Polonais",
  ps: "Pachto",
  pt: "Portugais",
  qu: "Quechua",
  ro: "Roumain",
  ru: "Russe",
  rw: "Kinyarwanda",
  se: "Same du Nord",
  si: "Cingalais",
  sk: "Slovaque",
  sl: "Slov\u00e8ne",
  sq: "Albanais",
  sr: "Serbe",
  sv: "Su\u00e9dois",
  sw: "Swahili",
  ta: "Tamoul",
  te: "T\u00e9lougou",
  th: "Tha\u00ef",
  tl: "Tagalog",
  tr: "Turc",
  ug: "Ou\u00efghour",
  uk: "Ukrainien",
  ur: "Ourdou",
  vi: "Vietnamien",
  vo: "Volap\u00fck",
  wa: "Wallon",
  xh: "Xhosa",
  zh: "Chinois",
  zu: "Zoulou",
};

export default langCodeMapping;
