import { useRef, useMemo, useCallback } from "react";

import { useLocation, useHistory } from "react-router";

/**
 *
 * @param {*} init
 * @returns
 */
export function createSearchParams(init = "") {
  return new URLSearchParams(
    typeof init === "string" || Array.isArray(init)
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
        }, []),
  );
}
/**
 * @param {string|Array|Record<string, any>} defaultInit
 * @returns {[URLSearchParams, (newParams: any, updateType?: string | undefined, url?: any) => void]}
 */
const useFilterParams = (defaultInit = "") => {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const { search, pathname } = useLocation();
  const history = useHistory();

  const params = useMemo(() => {
    const searchParams = createSearchParams(search);
    // add defaultsearchparams to params
    // eslint-disable-next-line no-restricted-syntax
    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    }
    return searchParams;
  }, [search]);

  const updateFunction = (newParams, updateType = "push", url) => {
    let newUrl = url || pathname;
    if (url && !url?.startsWith("/")) {
      newUrl = `${pathname}/${url}`;
    }
    const urlWithParams = `${newUrl}?${createSearchParams(newParams)}`;
    if (updateType === "push") {
      history.push(urlWithParams);
    } else {
      history.replace(urlWithParams);
    }
  };

  const updateParams = useCallback(updateFunction, [history, pathname]);

  return [params, updateParams];
};

export default useFilterParams;
