import { z } from "zod";

export const allowedProductsSchema = z.array(
  z.union([
    z.literal("listings"),
    z.literal("reviews"),
    z.literal("website-optimizer"),
    z.literal("wifi"),
  ]),
);

export const meSchema = z.object({
  data: z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string().nullable(),
    email: z.string(),
    role: z.union([
      z.literal("super_admin"),
      z.literal("account_manager"),
      z.literal("location_manager"),
    ]),
    timezone: z.string(),
    is_primary: z.boolean(),
    allowed_products: allowedProductsSchema,
    language: z.string(),
    permissions: z.array(z.string()),
    created_at: z.string().nullable(),
    account: z.object({
      id: z.number(),
      name: z.string(),
      type: z.string(),
      advanced_settings: z.object({
        is_high_profile: z.boolean(),
        is_demo: z.boolean(),
      }),
    }),
    reseller: z
      .object({
        id: z.number(),
        name: z.string(),
        is_main: z.boolean(),
      })
      .nullable()
      .optional(),
    whitelabel: z
      .object({
        support_url: z.string().nullable(),
        knowledge_base_url: z.string().nullable(),
        logo_url: z.string().nullable(),
        primary_color: z.string().nullable(),
        secondary_color: z.string().nullable(),
      })
      .nullable()
      .optional(),
  }),
});

export default meSchema;
