/* eslint-disable no-underscore-dangle */
/**
 * customer.io
 * @see https://customer.io/docs/api/
 * @type {{track: function(), identify: function()}}
 */

const customerIO =
  typeof window !== "undefined" && window?._cio
    ? window._cio
    : {
        track: () => {},
        identify: () => {},
        page: () => {},
      };

const customerIOTracker = {
  track: (event, payload) => {
    customerIO.track(event, payload);
  },
  identify: (payload) => {
    const { userId: id, ...data } = payload;
    customerIO.identify({ id, ...data });
  },
  pageView: (url, payload) => {
    customerIO.page(url, payload);
  },
};

export const domainsToNotTriggerCustomerIoEvents = ["listings.ueni.com"];

export default customerIOTracker;
