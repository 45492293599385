import * as beaver from "beaver-logger";

export const LOG_LEVEL = {
  DEBUG: "debug",
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
};

const $logger = beaver.Logger({
  url: `${import.meta.env.VITE_API_GATEWAY_HOST}/logger`,
  flushInterval: 5 * 1000, // 5 sec
});

$logger.addMetaBuilder(() => ({
  token: import.meta.env.VITE_LOGZIO_TOKEN,
  type: import.meta.env.VITE_LOGZIO_TYPE,
}));

/**
 * Log message to Logz.io ELK
 */
const log = (message = "", payload = {}, level = LOG_LEVEL.DEBUG) => {
  if (import.meta.env.VITE_LOGZIO_ENABLED === "true") {
    $logger[level](message, { message, ...payload });
  } else {
    /* eslint-disable no-console */
    console[level](message, payload);
    /* eslint-enable */
  }
};

export default log;

export class LoggerError extends Error {
  constructor(message, payload) {
    super(message);
    this.name = "LoggerError";
    this.payload = payload;
  }
}
