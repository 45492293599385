import { captureMessage } from "@sentry/react";
import isNil from "lodash/isNil";
import moment from "moment";
import { stringify } from "query-string";

import { getCookie } from "hooks/useCookieWatcher";

import { getCleansingCommentValue } from "utils/listings/locationUtils";

import { updateLocationListingId } from ".";
import trackers, { EVENT_LL_LOCATION_UPDATED } from "../trackers";
import { getLangCodeForUberallApi } from "../utils/languages";
import { getLocationUpdatedTrackingPayload } from "../utils/listingUtils";
import listingsFetch, { getV3Host, handle401Redirection } from "./fetch";

export const CREATE_LISTING_LOCATION = "CREATE_LISTING_LOCATION";
export const RECEIVE_LISTING_LOCATION = "RECEIVE_LISTING_LOCATION";
export const LISTING_LOCATION_FLUSH_ERRORS = "LISTING_LOCATION_FLUSH_ERRORS";
export const SET_LISTING_LOCATION_ERROR = "SET_LISTING_LOCATION_ERROR";
export const RECEIVE_LISTING_LOCATIONS = "RECEIVE_LISTING_LOCATIONS";
export const SAVE_LISTING_LOCATION = "SAVE_LISTING_LOCATION";
export const FETCH_LISTING_INSIGHTS = "FETCH_LISTING_INSIGHTS";
export const FETCH_LISTING_LOCATION_INSIGHTS = "FETCH_LISTING_LOCATION_INSIGHTS";
export const RECEIVE_LISTING_PAGE = "RECEIVE_LISTING_PAGE";
export const DELETE_LISTING_PAGE = "DELETE_LISTING_PAGE";
export const REQUEST_LISTING_CATEGORIES = "REQUEST_LISTING_CATEGORIES";
export const RECEIVE_LISTING_CATEGORIES = "RECEIVE_LISTING_CATEGORIES";
export const REQUEST_LISTING_ATTRIBUTES = "REQUEST_LISTING_ATTRIBUTES";
export const RECEIVE_LISTING_ATTRIBUTES = "RECEIVE_LISTING_ATTRIBUTES";
export const FETCH_LISTING_HEALTH = "FETCH_LISTING_HEALTH";
export const RECEIVE_LISTINGS = "RECEIVE_LISTINGS";
export const SYNC_LISTING_LOCATION = "SYNC_LISTING_LOCATION";
export const FETCH_LISTING_PLANS = "FETCH_LISTING_PLANS";
export const FETCH_LISTING_SUGGESTIONS = "FETCH_LISTING_SUGGESTIONS";
export const SAVE_LISTING_SUGGESTIONS = "SAVE_LISTING_SUGGESTIONS";
export const UPDATE_LISTING_FROM_REACT_QUERY = "UPDATE_LISTING_FROM_REACT_QUERY";
export const FETCH_AVAILABLE_PHOTO_TYPES = "FETCH_AVAILABLE_PHOTO_TYPES";

export const LISTING_SESSION_KEY = "listing-session-key";

const csrfToken = getCookie("XSRF-TOKEN");

// Since the v3 proxy for listings needs the XSRF token whenever we perform a POST request,
// we update the fetch here to include the token in the headers.
const fetch = (url, options) =>
  listingsFetch(url, {
    ...options,
    ...{
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        origin: window.location.origin,
        "X-XSRF-TOKEN": csrfToken,
      },
    },
  }).catch((err) => {
    handle401Redirection(err.status);
    return Promise.reject(err);
  });

const listingsUrl = (accountId) => {
  if (!isNil(accountId)) {
    return `${getV3Host()}/listings/${accountId}`;
  }

  captureMessage("called listings endpoint without an accountId");
  return `${getV3Host()}/listings`;
};

function createListingLocation(location, accountId) {
  return {
    type: CREATE_LISTING_LOCATION,
    payload: fetch(`${listingsUrl(accountId)}/locations`, {
      method: "POST",
      body: JSON.stringify(location),
    })
      .then((res) => res.json())
      .then((res) => res.response),
  };
}
export function fetchListingCategories(params = {}, accountId) {
  return (dispatch, getState) => {
    const lang = getState().i18nState.lang || "en";
    // from v3, it should be pt-br, no need getLangCodeForUberallApi
    // lang = getLangCodeForUberallApi(lang);
    dispatch({ type: REQUEST_LISTING_CATEGORIES });
    let query = `language=${lang}`;
    Object.keys(params).forEach((key) => {
      query = `${query}&${key}=${encodeURIComponent([].concat(params[key]).join(","))}`;
    });
    return fetch(`${listingsUrl(accountId)}/categories?${query}`)
      .then((res) => res.json())
      .then(({ response: { results } }) =>
        dispatch({
          type: RECEIVE_LISTING_CATEGORIES,
          categories: results.results || results,
        }),
      );
  };
}

export function fetchListingAttributes(categoryId, country, accountId) {
  return (dispatch, getState) => {
    let lang = getState().i18nState.lang || "en";
    lang = getLangCodeForUberallApi(lang);
    dispatch({ type: REQUEST_LISTING_ATTRIBUTES });
    const query = `language=${lang}&country=${country}`;
    return fetch(`${listingsUrl(accountId)}/categories/${categoryId}/attributes?${query}`)
      .then((res) => res.json())
      .then(({ response: { attributes } }) => {
        dispatch({ type: RECEIVE_LISTING_ATTRIBUTES, attributes });
      });
  };
}

export function addListingLocation(data, placeId, locationId, customerId, accountId) {
  const newListing = { ...data };

  if (data?.streetChanged) {
    newListing.street = data.streetAndNumber;
    newListing.streetNo = null;
    delete newListing.streetChanged;
  }
  return (dispatch) =>
    dispatch(createListingLocation(newListing, accountId)).then(
      ({
        value: {
          location: { id: listingId },
        },
      }) => {
        // only update redux states, no any request, because backend will handle list_id updating in database when craete new location
        // when fetch the same location next time, it should be with listing_id
        dispatch(
          updateLocationListingId({
            locationId,
            customerId,
            listingId,
          }),
        );
      },
    );
}

export function fetchListingLocations(params, accountId) {
  return (dispatch) =>
    dispatch({
      type: RECEIVE_LISTING_LOCATIONS,
      payload: fetch(`${listingsUrl(accountId)}/locations?${stringify(params)}`)
        .then((res) => res.json())
        .then((res) => res.response),
      meta: { params },
    });
}

function fetchLocation(id, type, accountId) {
  return {
    type,
    payload: fetch(`${listingsUrl(accountId)}/locations/${id}`)
      .then((res) => res.json())
      .then((res) => res.response),
  };
}

export function fetchAvailablePhotoTypes(accountId, categoryId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_AVAILABLE_PHOTO_TYPES,
      payload: fetch(`${listingsUrl(accountId)}/categories/${categoryId}/available-photo-types`)
        .then((res) => res.json())
        .then((res) => res.response),
    });
}

export function fetchListingLocation(locationId, accountId) {
  return (dispatch) => dispatch(fetchLocation(locationId, RECEIVE_LISTING_LOCATION, accountId));
}

export function fetchListingAndCategories(locationId, accountId) {
  return (dispatch) =>
    dispatch(fetchLocation(locationId, RECEIVE_LISTING_LOCATION, accountId)).then(
      ({ value: { location = {} } }) => {
        return dispatch(fetchListingCategories({ categories: location.categories }, accountId));
      },
    );
}

export function saveListingLocation(id, listing, accountId) {
  const newListing = { ...listing };

  if (listing?.streetChanged) {
    newListing.street = listing.streetAndNumber;
    newListing.streetNo = null;
    delete newListing.streetChanged;
  }
  // todo remove serviceFields when uberall doesn't return a array of objects for services anymore
  const serviceFields =
    listing?.services?.map((serviceField) => {
      if (typeof serviceField === "string") {
        return serviceField;
      }
      return serviceField?.title;
    }) ?? [];

  if (listing?.cleansingStatus === "INVALID_DATA") {
    newListing.cleansingComment = getCleansingCommentValue(listing?.cleansingComment);
  }

  const retryDelay = 10000;

  const patchListingLocation = (retryCount) => {
    return fetch(`${listingsUrl(accountId)}/locations/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ ...{ v: 20191203 }, ...newListing, services: serviceFields }),
    })
      .then((res) => res.json())
      .then((res) => res.response)
      .catch((err) => {
        const messageData = err?.messageData;
        if (messageData && messageData.status === "RESOURCE_LOCKED" && retryCount > 0) {
          // When RESOURCE_LOCKED, retry after delay
          return new Promise((resolve) => setTimeout(resolve, retryDelay)).then(() =>
            patchListingLocation(retryCount - 1),
          );
        }
        throw err;
      });
  };

  return (dispatch) =>
    dispatch({
      type: SAVE_LISTING_LOCATION,
      payload: patchListingLocation(6),
    })
      .then(({ value: { location = {} } }) => {
        dispatch(fetchListingCategories({ categories: location.categories }, accountId));
        return location;
      })
      .then((location) => {
        trackers.track(EVENT_LL_LOCATION_UPDATED, getLocationUpdatedTrackingPayload(location));
      });
}

export function syncListingLocation(locationId, accountId) {
  return (dispatch) =>
    dispatch({
      type: SYNC_LISTING_LOCATION,
      meta: { locationId },
      payload: fetch(`${listingsUrl(accountId)}/locations/sync`, {
        method: "POST",
        body: JSON.stringify({ locationIds: locationId }),
      })
        .then((res) => res.json())
        .then((res) => res.response),
    });
}

export function listingLocationFlushErrors() {
  return (dispatch) => dispatch({ type: LISTING_LOCATION_FLUSH_ERRORS });
}

export function setListingLocationError(error) {
  return (dispatch) => dispatch({ type: SET_LISTING_LOCATION_ERROR, meta: { error } });
}

export function fetchListings(id, accountId) {
  return (dispatch) => dispatch(fetchLocation(id, RECEIVE_LISTINGS, accountId));
}

const DATE_FORMAT = "YYYY-MM-DD";

export function fetchInsightsData(filters, accountId) {
  const { startDate, endDate } = filters;
  const params = stringify({
    ...{ type: "google", group: "YEAR" },
    ...filters,
    startDate: moment.isMoment(startDate) ? startDate.format(DATE_FORMAT) : startDate,
    endDate: moment.isMoment(endDate) ? endDate.format(DATE_FORMAT) : endDate,
  });
  return (dispatch) =>
    dispatch({
      type: FETCH_LISTING_INSIGHTS,
      payload: fetch(`${listingsUrl(accountId)}/dashboard/insightsData?${params}`)
        .then((res) => res.json())
        .then((res) => res.response),
    });
}

export function deleteListingPage(locationId, network, accountId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_LISTING_PAGE,
      meta: { network },
      payload: fetch(`${listingsUrl(accountId)}/locations/${locationId}/${network}/page`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((res) => res.response),
    }).then(() => dispatch(fetchListingLocation(locationId, accountId)));
}

export function fetchListingPage(locationId, network, accountId) {
  return (dispatch) =>
    dispatch({
      type: RECEIVE_LISTING_PAGE,
      meta: { network },
      payload: fetch(`${listingsUrl(accountId)}/locations/${locationId}/${network}/page`)
        .then((res) => res.json())
        .then((res) => res.response),
      // Errors are fine as a result of this fetch, we don't need to perform an action on error
    });
}

export function fetchHealthData(params = {}, accountId) {
  const query = stringify(params);
  return (dispatch) =>
    dispatch({
      type: FETCH_LISTING_HEALTH,
      payload: fetch(`${listingsUrl(accountId)}/dashboard/listingHealth?${query}`)
        .then((res) => res.json())
        .then((res) => res.response),
      meta: { params },
    });
}

export function fetchListingPlans(accountId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_LISTING_PLANS,
      payload: fetch(`${listingsUrl(accountId)}/productplans`)
        .then((res) => res.json())
        .then((res) => res.response?.plans),
    });
}

export function fetchListingSuggestions(locationId, accountId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_LISTING_SUGGESTIONS,
      payload: fetch(`${listingsUrl(accountId)}/locations/${locationId}/suggestions`)
        .then((res) => res.json())
        .then((res) => res.response),
    });
}

export function saveListingSuggestions(locationId, suggestions, accountId) {
  return (dispatch) =>
    dispatch({
      type: SAVE_LISTING_SUGGESTIONS,
      meta: {
        locationId,
      },
      payload: fetch(`${listingsUrl(accountId)}/locations/${locationId}/suggestions`, {
        method: "PUT",
        body: JSON.stringify(suggestions),
      })
        .then((res) => res.json())
        .then((res) => res.response),
    });
}

export function updateListingReactQuery(listing) {
  return {
    type: UPDATE_LISTING_FROM_REACT_QUERY,
    payload: listing,
  };
}
