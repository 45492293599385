import React, { Suspense } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pt";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import I18n from "redux-i18n";

import { getSetLanguage } from "utils/languages";

import App from "./App";
import LoadingPopup from "./components/commons/LoadingPopup";
import PopupPage from "./components/commons/PopupPage";
import "./i18n";
import { translations } from "./lang/translations";
import configureStore from "./store/configureStore";

/* eslint-disable-next-line no-underscore-dangle */
const preloadedState = window.__PRELOADED_STATE__;

const lang = getSetLanguage();

// Simple mock for fetch wrapper used in server side rendering
window.enqueueResourceRequest = (callback) => new Promise((resolve) => callback(resolve));

const store = configureStore(preloadedState);
moment.locale(lang);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const renderLoading = () => (
  <PopupPage color="white">
    <LoadingPopup />
  </PopupPage>
);

/*
  Resolve Chrome Google translate automatically insert <font> tag then break React DOM tree reference issue
  https://github.com/facebook/react/issues/11538#issuecomment-417504600
*/

if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error("Cannot remove a child from a different parent", child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this,
        );
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

async function deferRender() {
  if (import.meta.env.VITE_ENABLE_BROWSER_MOCKING === "true") {
    const { worker } = await import("./mocks/browser");
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      // serviceWorker: { options: {scope: "/"}, url: "https://local.dashboard.sowifi.com/mockServiceWorker.js" },
      onUnhandledRequest: (req, print) => {
        /* eslint-disable no-console */
        console.log(req);
        /* eslint-disable no-console */
        console.log("handler", `${import.meta.env.VITE_API_HOST_V2}/customers/*`);
        const ignoredPatterns = [/localhost:3000/, /maps\.googleapis\.com/];
        if (ignoredPatterns.some((pattern) => req.url.match(pattern))) {
          return;
        }
        print.warning();
      },
    });
  }
  return undefined;
}

deferRender().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <I18n translations={translations} initialLang={lang} fallbackLang="en">
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter>
            <Suspense fallback={renderLoading()}>
              <App />
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </I18n>
    </Provider>,
    document.getElementById("SoreviewApp"),
  );
});
