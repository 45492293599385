import { z } from "zod";

export const paginationSchema = z
  .object({
    current_page: z.number(),
    per_page: z.number(),
    total: z.number(),
    from: z.number().nullable(),
    to: z.number().nullable(),
    last_page: z.number(),
  })
  .partial();

export default paginationSchema;
