import React from "react";

import { ExternalLink } from "assets/icons/teya";
import cx from "classnames";

type MenuItemProps = {
  icon: JSX.Element;
  label: string;
  itemKey: string;
  hasExternalLink?: boolean;
  url?: string;
  noBottomBorder?: boolean;
};

const MenuItem = ({
  icon,
  hasExternalLink,
  label,
  itemKey,
  url,
  noBottomBorder,
}: MenuItemProps) => {
  if (url) {
    return (
      <a
        key={itemKey}
        href={url}
        target={hasExternalLink ? "_blank" : "_self"}
        className={cx(
          "tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-border-b tw-border-primary-charcoal-10  tw-px-4 tw-py-3 tw-text-primary-charcoal-500 tw-no-underline tw-body-regular hover:tw-bg-primary-off-white c-md:tw-min-w-72 c-md:tw-p-3",
          {
            "tw-border-solid": !noBottomBorder,
          },
        )}
        rel="noreferrer"
      >
        <div className="tw-flex tw-items-center tw-gap-3">
          {icon}
          <span>{label}</span>
        </div>
        {hasExternalLink && <ExternalLink className="tw-fill-primary-charcoal-60" />}
      </a>
    );
  }
  return (
    <span
      key={itemKey}
      data-testid={`user-menu-item-${itemKey}`}
      className={cx(
        "tw-flex tw-min-w-72 tw-items-center tw-justify-between tw-border-b  tw-border-primary-charcoal-10 tw-p-3 tw-text-primary-charcoal-500 tw-body-regular hover:tw-bg-primary-off-white",
        {
          "tw-border-solid": !noBottomBorder,
        },
      )}
    >
      <div className="tw-flex tw-items-center tw-gap-3">
        {icon}
        <span>{label}</span>
      </div>
    </span>
  );
};

export default MenuItem;
