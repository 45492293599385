import { captureMessage } from "@sentry/react";
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getHost, getV3Host, reactQueryFetch as fetch } from "actions/fetch";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { stringify } from "query-string";
import {
  createLocationSchema,
  legacyLocationSchema,
  locationOnboardingInsightsSchema,
  locationSchema,
  locationSubscriptionsSchema,
  locationsSchema,
  locationV3Schema,
} from "schemas/locationSchemas";
import { z } from "zod";

import { PaginationType, requestAndValidate, validId, validIds } from "utils/general";

import { retentionEvents } from "components/carbon/accounts/retentions/ListingsRetentionContent";

const locationsUrl = `${getHost()}/locations` as const;
const venuesUrl = `${getHost()}/venues` as const;
const v3LocationsUrl = `${getV3Host()}/locations` as const;

const devicesUrl = (id: string | number): string => `${locationsUrl}/${id}/wifi/devices`;

export type LegacyLocationsParams = {
  "filter[query]": string;
};

const locationKeys = {
  location: (locationId: number) => ["location", locationId] as const,
  locationV3: (locationId: number) => ["locationV3", locationId] as const,
  mulitpleLocations: (locationIds: number[]) => ["multipleLocations", locationIds] as const,
  legacyLocation: (locationId: number) => ["legacyLocation", locationId] as const,
  legacyLocations: (customerId: number, params: Partial<LegacyLocationsParams>) =>
    ["legacyLocations", { customerId, params }] as const,
  allLocations: (params: Partial<LocationParams>) => ["allLocations", params] as const,
  devices: (locationId: number | undefined) => ["devices", locationId] as const,
  locationSubscriptions: (locationId: number) => ["locationSubscriptions", locationId] as const,
  locationWifi: (locationId: number) => ["wifi", locationId] as const,
  locationOnboardingInsights: (locationId: number | null) =>
    ["locationOnboardingInsights", locationId] as const,
};

type QueryReturnType<K extends keyof typeof locationKeys> = QueryFunctionContext<
  ReturnType<(typeof locationKeys)[K]>
>;

async function fetchLocation({ queryKey }: QueryReturnType<"location">) {
  const [, locationId] = queryKey;
  return requestAndValidate({
    invalidMessage: "location data not up to spec",
    requestUrl: `${venuesUrl}/${locationId}`,
    schema: locationSchema,
  });
}

async function fetchMultipleLocations({ queryKey }: QueryReturnType<"mulitpleLocations">) {
  const [, locationIds] = queryKey;
  return Promise.all(
    locationIds.map((locationId) => {
      return requestAndValidate({
        invalidMessage: "location data not up to spec",
        requestUrl: `${venuesUrl}/${locationId}`,
        schema: locationSchema,
      });
    }),
  );
}

type LegacyLocation = z.infer<typeof legacyLocationSchema>;
async function fetchLegacyLocations({ queryKey }: QueryReturnType<"legacyLocations">) {
  const [, { customerId, params }] = queryKey;

  const data = await fetch(`${getHost()}/customers/${customerId}/locations?${stringify(params)}`);
  const jsonData = await data.json();
  return jsonData as LegacyLocation[];
}

async function fetchLegacyLocation({ queryKey }: QueryReturnType<"legacyLocation">) {
  const [, locationId] = queryKey;

  return requestAndValidate({
    invalidMessage: "Legacy Location data not match with spec",
    requestUrl: `${locationsUrl}/${locationId}`,
    schema: legacyLocationSchema,
  });
}

async function fetchLocationV3({ queryKey }: QueryReturnType<"locationV3">) {
  const [, locationId] = queryKey;

  return requestAndValidate({
    invalidMessage: "Location v3 data not match with spec",
    requestUrl: `${v3LocationsUrl}/${locationId}`,
    schema: locationV3Schema,
  });
}

async function fetchAllLocations({ queryKey }: QueryReturnType<"allLocations">) {
  const [, params] = queryKey;

  return requestAndValidate({
    invalidMessage: "location data not up to spec",
    requestUrl: `${locationsUrl}?${stringify(params)}`,
    schema: locationsSchema,
  });
}

async function fetchDevices({ queryKey }: QueryReturnType<"devices">) {
  const [, locationId] = queryKey;

  if (typeof locationId === "undefined") {
    return Promise.reject(new Error("Invalid location id"));
  }
  const data = await fetch(`${devicesUrl(`${locationId}`)}`);
  return data.json();
}

async function fetchLocationSubscriptions({ queryKey }: QueryReturnType<"locationSubscriptions">) {
  const [, locationId] = queryKey;
  const data = await fetch(`${locationsUrl}/${locationId}/subscriptions`);
  const jsonData = await data.json();

  const parsedData = locationSubscriptionsSchema.safeParse(jsonData);
  if (!parsedData.success) {
    captureMessage("location data not up to spec");
    return jsonData as z.infer<typeof locationSubscriptionsSchema>;
  }
  return parsedData.data;
}

async function deleteLocation({ locationId }: { locationId: number }) {
  const data = await fetch(`${venuesUrl}/${locationId}`, { method: "DELETE" });
  return data.json();
}

async function updateLocation({
  locationId,
  data: formData,
}: {
  locationId: number;
  data: Partial<z.infer<typeof locationSchema>>;
}) {
  const data = await fetch(`${getHost()}/venues/${locationId}`, {
    method: "PUT",
    body: JSON.stringify(formData),
  });
  return data.json();
}

async function removeLocationDevice({
  locationId,
  deviceId,
}: {
  locationId: number | undefined;
  deviceId: number;
}) {
  if (locationId === undefined) {
    throw new Error("Invalid locationId given");
  }

  const data = await fetch(`${devicesUrl(locationId)}/remove`, {
    method: "DELETE",
    body: JSON.stringify({ device_id: deviceId }),
  });
  return data.json();
}

async function assignLocationDevice({
  locationId,
  deviceId,
}: {
  locationId: number | undefined;
  deviceId: number;
}) {
  if (locationId === undefined) {
    throw new Error("Invalid locationId given");
  }

  const data = await fetch(`${devicesUrl(locationId)}/assign`, {
    method: "POST",
    body: JSON.stringify({ device_id: deviceId }),
  });
  return data.json();
}

async function fetchOnboardingInsights({
  queryKey,
}: QueryReturnType<"locationOnboardingInsights">) {
  const [, locationId] = queryKey;

  return requestAndValidate({
    invalidMessage: "Onboarding insights data not up to spec",
    requestUrl: `${locationsUrl}/${locationId}/onboarding-insights`,
    schema: locationOnboardingInsightsSchema,
  });
}
export type CreateLocationParams = z.infer<typeof createLocationSchema>;
async function createLocation(locationParams: CreateLocationParams) {
  const data = await fetch(venuesUrl, {
    method: "POST",
    body: JSON.stringify(locationParams, (key, value) => (value === "" ? null : value)),
  });
  return data.json();
}

function reassignLocation({ locationId, accountId }: { locationId: number; accountId: number }) {
  return fetch(`${v3LocationsUrl}/${locationId}/reassign`, {
    method: "POST",
    body: JSON.stringify({ customer_id: accountId }),
  });
}

export const useLocation = (locationId: number | null) =>
  useQuery({
    queryKey: locationKeys.location(locationId as number),
    queryFn: fetchLocation,
    enabled: validId(locationId),
  });

export const useLocationV3 = (locationId: number | null) =>
  useQuery({
    queryKey: locationKeys.locationV3(Number(locationId)),
    queryFn: fetchLocationV3,
    enabled: !isNil(locationId),
    staleTime: 1000,
  });

export const useMultipleLocations = (locationIds: number[]) =>
  useQuery({
    queryKey: locationKeys.mulitpleLocations(locationIds),
    queryFn: fetchMultipleLocations,
    enabled: validIds(locationIds),
  });

export const useLegacyLocation = (locationId: number) =>
  useQuery({
    queryKey: locationKeys.legacyLocation(Number(locationId)),
    queryFn: fetchLegacyLocation,
    enabled: !isNil(locationId),
    staleTime: 1000,
  });

type LocationParams = {
  sort: string;
  query: string;
  "filter[account]": number;
  "filter[without_products]": string;
} & PaginationType;

export const useAllLocations = (params: Partial<LocationParams>) => {
  const defaultParams = {
    page: "1",
    per_page: "10",
    sort: "",
    query: "",
  };

  const urlParams = omitBy({ ...defaultParams, ...params }, (val) => val === "" || val === "null");

  return useQuery({
    queryKey: locationKeys.allLocations(urlParams),
    queryFn: fetchAllLocations,
    retry: 1,
    staleTime: 3 * 1000,
  });
};

export const useLegacyLocations = (customerId: number, params: Partial<LegacyLocationsParams>) => {
  return useQuery({
    queryKey: locationKeys.legacyLocations(customerId, params),
    queryFn: fetchLegacyLocations,
    retry: 1,
    staleTime: 3 * 1000,
  });
};

export const useLocationDevices = (locationId: number | undefined) =>
  useQuery({
    queryFn: fetchDevices,
    queryKey: locationKeys.devices(locationId),
    enabled: !isNil(locationId),
    retry: 1,
  });

export const useDeleteLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allLocations"] });
    },
  });
};

export const useLocationSubscriptions = (locationId: number) =>
  useQuery({
    queryFn: fetchLocationSubscriptions,
    queryKey: locationKeys.locationSubscriptions(locationId),
    enabled: !isNil(locationId),
    retry: 1,
  });

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateLocation,
    onSuccess: (_, queryVars) => {
      queryClient.invalidateQueries({
        queryKey: locationKeys.location(queryVars.locationId),
      });
    },
  });
};

export const useRemoveLocationDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeLocationDevice,
    onSuccess: (_, queryVars) => {
      queryClient.invalidateQueries({
        queryKey: locationKeys.devices(queryVars.locationId),
      });
    },
  });
};

export const useAssignLocationDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: assignLocationDevice,
    onSuccess: (_, queryVars) => {
      queryClient.invalidateQueries({
        queryKey: locationKeys.devices(queryVars.locationId),
      });
    },
  });
};

export const useLocationOnboardingInsights = (locationId: number | null) => {
  return useQuery({
    queryKey: locationKeys.locationOnboardingInsights(locationId),
    queryFn: fetchOnboardingInsights,
    enabled: validId(locationId),
    retry: 1,
  });
};

async function retentionTriggerEvent({
  locationId,
  event,
}: {
  locationId: number;
  event: (typeof retentionEvents)[keyof typeof retentionEvents];
}) {
  const data = await fetch(`${locationsUrl}/${locationId}/trigger/${event}`, {
    method: "POST",
  });
  return data.json();
}

export const useListingsRetentionTriggerEvent = ({
  locationId,
  event,
}: {
  locationId: number;
  event: (typeof retentionEvents)[keyof typeof retentionEvents];
}) => {
  return useMutation({
    mutationFn: () => retentionTriggerEvent({ locationId, event }),
  });
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allLocations"] });
      queryClient.invalidateQueries({ queryKey: ["allSubscriptions"] });
    },
  });
};

export const useReassignLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reassignLocation,
    onSuccess: (_, { locationId }) => {
      queryClient.invalidateQueries({ queryKey: ["allLocations"] });
      queryClient.invalidateQueries({ queryKey: ["location", locationId] });
      queryClient.invalidateQueries({ queryKey: ["allSubscriptions"] });
    },
  });
};
