import { ActionType } from "redux-promise-middleware";

import {
  CREATE_LISTING_LOCATION,
  FETCH_LISTING_HEALTH,
  FETCH_LISTING_INSIGHTS,
  FETCH_LISTING_LOCATION_INSIGHTS,
  FETCH_LISTING_PLANS,
  FETCH_LISTING_SUGGESTIONS,
  LISTING_LOCATION_FLUSH_ERRORS,
  RECEIVE_LISTINGS,
  RECEIVE_LISTING_ATTRIBUTES,
  RECEIVE_LISTING_CATEGORIES,
  RECEIVE_LISTING_LOCATION,
  RECEIVE_LISTING_LOCATIONS,
  RECEIVE_LISTING_PAGE,
  REQUEST_LISTING_ATTRIBUTES,
  REQUEST_LISTING_CATEGORIES,
  SAVE_LISTING_LOCATION,
  SAVE_LISTING_SUGGESTIONS,
  SET_LISTING_LOCATION_ERROR,
  SYNC_LISTING_LOCATION,
  UPDATE_LISTING_FROM_REACT_QUERY,
  FETCH_AVAILABLE_PHOTO_TYPES,
} from "../actions";
import { paramsToHash } from "../utils/reducerUtils";

export function locations(state = { data: {} }, action) {
  switch (action.type) {
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Pending}`:
    case `${CREATE_LISTING_LOCATION}_${ActionType.Pending}`:
    case `${SAVE_LISTING_LOCATION}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
        isRejected: false,
        error: undefined,
      };
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Fulfilled}`:
    case `${CREATE_LISTING_LOCATION}_${ActionType.Fulfilled}`:
    case `${SAVE_LISTING_LOCATION}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        isRejected: false,
        error: undefined,
        data: {
          ...state.data,
          [action.payload.location.id]: {
            ...action.payload.location,
          },
        },
      };
    case `${RECEIVE_LISTING_LOCATIONS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        isRejected: false,
        error: undefined,
        data: {
          ...state.data,
          ...action.payload.locations.reduce(
            (acc, location) => ({
              ...acc,
              [location.id]: location,
            }),
            {},
          ),
        },
      };
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Rejected}`:
    case `${CREATE_LISTING_LOCATION}_${ActionType.Rejected}`:
    case `${SAVE_LISTING_LOCATION}_${ActionType.Rejected}`:
      return {
        ...state,
        isRejected: true,
        isLoading: false,
        error: action.payload,
      };
    case `${SYNC_LISTING_LOCATION}_${ActionType.Fulfilled}`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.locationId]: {
            ...state.data[action.meta.locationId],
            locationSyncable: false,
          },
        },
      };
    case `${LISTING_LOCATION_FLUSH_ERRORS}`:
      return {
        ...state,
        isRejected: false,
        error: state?.error?.isCustom ? state.error : undefined,
      };
    case `${SET_LISTING_LOCATION_ERROR}`:
      return {
        ...state,
        isRejected: true,
        error: { messageData: action.meta.error, isCustom: true },
      };
    case `${UPDATE_LISTING_FROM_REACT_QUERY}`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export function listingsOverview(state = { data: {} }, action) {
  switch (action.type) {
    case `${RECEIVE_LISTING_LOCATIONS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${RECEIVE_LISTING_LOCATIONS}_${ActionType.Rejected}`:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case `${RECEIVE_LISTING_LOCATIONS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        totalCount: !action.meta.params.query ? action.payload.count : state.totalCount,
        count: action.payload.count,
        offset: action.payload.offset,
        data: {
          ...(action.payload.offset === 0 ? {} : state.data),
          ...action.payload.locations.reduce(
            (acc, location) => ({
              ...acc,
              [location.id]: location,
            }),
            {},
          ),
        },
      };
    default:
      return state;
  }
}

export function listingsByLocationId(state = { data: {} }, action) {
  switch (action.type) {
    case `${RECEIVE_LISTINGS}_${ActionType.Pending}`:
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${RECEIVE_LISTINGS}_${ActionType.Fulfilled}`:
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [action.payload.location.id]: {
            ...action.payload.location.listings,
          },
        },
      };
    case `${RECEIVE_LISTINGS}_${ActionType.Rejected}`:
    case `${RECEIVE_LISTING_LOCATION}_${ActionType.Rejected}`:
      return {
        ...state,
        isRejected: true,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function categories(state = {}, action) {
  switch (action.type) {
    case REQUEST_LISTING_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_LISTING_CATEGORIES: {
      const newCategories = action.categories.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: category,
        }),
        {},
      );

      return {
        ...state,
        isLoading: false,
        data: { ...newCategories },
      };
    }
    default:
      return state;
  }
}

export function attributes(state = {}, action) {
  switch (action.type) {
    case REQUEST_LISTING_ATTRIBUTES:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_LISTING_ATTRIBUTES: {
      return {
        ...state,
        isLoading: false,
        data: [...action.attributes],
      };
    }
    default:
      return state;
  }
}

export function pages(state = {}, action) {
  switch (action.type) {
    case `${RECEIVE_LISTING_PAGE}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: {
          [action.meta.network]: true,
        },
      };
    case `${RECEIVE_LISTING_PAGE}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: {
          [action.meta.network]: false,
        },
        data: {
          ...state.data,
          [action.meta.network]: { page: { error: true } },
        },
      };
    case `${RECEIVE_LISTING_PAGE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: {
          [action.meta.network]: false,
        },
        data: { ...state.data, [action.meta.network]: action.payload },
      };
    default:
      return state;
  }
}

export function insightsData(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case `${FETCH_LISTING_INSIGHTS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_LISTING_INSIGHTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.payload.metrics.reduce(
            (acc, metric) => ({ ...acc, [metric.name]: metric.data }),
            {},
          ),
        },
      };
    case `${FETCH_LISTING_INSIGHTS}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function healthData(state = {}, { type, payload = {}, meta: { params } = {} }) {
  const key = paramsToHash(params);
  switch (type) {
    case `${FETCH_LISTING_HEALTH}_${ActionType.Pending}`:
      return {
        ...state,
        [key]: { isLoading: true },
      };
    case `${FETCH_LISTING_HEALTH}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        [key]: {
          isLoading: false,
          data: payload,
        },
      };
    }
    case `${FETCH_LISTING_HEALTH}_${ActionType.Rejected}`:
      return {
        ...state,
        [key]: {
          isLoading: false,
          error: payload,
        },
      };
    default:
      return state;
  }
}

export function listingPlans(state = {}, action) {
  switch (action.type) {
    case `${FETCH_LISTING_PLANS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_LISTING_LOCATION_INSIGHTS}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${FETCH_LISTING_PLANS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
      };
    default:
      return state;
  }
}

export function suggestions(state = {}, action) {
  switch (action.type) {
    case `${FETCH_LISTING_SUGGESTIONS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${FETCH_LISTING_SUGGESTIONS}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${FETCH_LISTING_SUGGESTIONS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case `${SAVE_LISTING_SUGGESTIONS}_${ActionType.Pending}`:
      return {
        ...state,
        isSavingSuggestions: true,
        isSaved: false,
        error: null,
      };
    case `${SAVE_LISTING_SUGGESTIONS}_${ActionType.Rejected}`:
      return {
        ...state,
        isSavingSuggestions: false,
        error: action.payload,
      };
    case `${SAVE_LISTING_SUGGESTIONS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        suggestionData: {
          ...state?.data,
          [action.meta.locationId]: {
            ...action.payload.data,
          },
        },
        isSaved: true,
        isSavingSuggestions: false,
        error: null,
      };
    default:
      return state;
  }
}

export function availablePhotoTypes(state = {}, action) {
  switch (action.type) {
    case `${FETCH_AVAILABLE_PHOTO_TYPES}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${FETCH_AVAILABLE_PHOTO_TYPES}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case `${FETCH_AVAILABLE_PHOTO_TYPES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    default:
      return state;
  }
}
