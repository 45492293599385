import React from "react";

import TeyaLogo from "assets/icons/teya/TeyaLogo";
import { useUser } from "context/UserContext";
import { Link, useLocation } from "react-router-dom";

import { useLocationV3 } from "hooks/queries/useLocationQueries";
import { createSearchParams } from "hooks/useFilterParams";

import StoreSelector from "./StoreSelector";
import UserMenu from "./UserMenu";

const getSelectedLocationId = (path: string, search?: string): number | null => {
  const detailRegex = /\/location\/(\d+)\/\w+/;
  const matchDetailPath = path.match(detailRegex);
  const onboardingRegex = /\/onboarding\/(\d+)\/\w+/;
  const matchOnboardingPath = path.match(onboardingRegex);

  if (matchDetailPath) {
    const locationId = matchDetailPath[1];
    return Number(locationId);
  }

  if (matchOnboardingPath) {
    const locationId = matchOnboardingPath[1];
    return Number(locationId);
  }

  if (search) {
    // when listing is invalid, the URLSearchParams will include locationId and notification[code]
    // use locationId from URLSearchParams, it can keep store selector to display invalid store name
    // let user has chance to select other store
    const searchParams = createSearchParams(search);
    if (searchParams.has("locationId")) {
      return Number(searchParams.get("locationId"));
    }
  }

  return null;
};

const BoostTopNav = () => {
  const { user } = useUser();
  const { first_name, last_name, email, account } = user;
  const { pathname, search } = useLocation();

  const selectedLocationId = getSelectedLocationId(pathname, search);
  const locationData = useLocationV3(selectedLocationId);
  const selectedLocationName = locationData.data?.data.name;

  return (
    <div className="top-navbar tw-sticky tw-left-0 tw-right-0 tw-top-0 tw-z-10 tw-body-semibold">
      <div className="tw-flex tw-h-[4rem] tw-items-center tw-justify-between tw-border-0 tw-border-b-2 tw-border-solid tw-border-primary-off-white tw-bg-primary-pure-white tw-px-4 tw-text-primary-charcoal-500 c-md:tw-px-c-9">
        <Link to="/">
          <TeyaLogo />
        </Link>
        <StoreSelector
          accountId={account?.id}
          selectedLocationName={selectedLocationName}
          selectedLocationId={selectedLocationId}
        />
        <UserMenu firstName={first_name} lastName={last_name} email={email} />
      </div>
    </div>
  );
};

export default BoostTopNav;
