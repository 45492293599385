import React from "react";

import { createPortal } from "react-dom";
import "styles/carbon/index.scss";

import EnableTeyaSkin from "utils/EnableTeyaSkin";

function ModalPortal({ children }) {
  return createPortal(
    <EnableTeyaSkin>
      <div className="soco modal-portal">{children}</div>
    </EnableTeyaSkin>,
    document.getElementById("SoreviewApp"),
  );
}

export default ModalPortal;
