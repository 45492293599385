import { loadingBarReducer } from "react-redux-loading-bar";
import { combineReducers } from "redux";
import { i18nState } from "redux-i18n";

import { customersById, customerLocationsById, customerProducts, bsp } from "./customer";
import * as listingManager from "./listings";
import { properties, locationsById, onboardingInsights, locationEvents } from "./locations";
import storage from "./storage";
import subscriptions from "./subscriptions";

const rootReducer = combineReducers({
  subscriptions,
  i18nState,
  loadingBar: loadingBarReducer,
  listingManager: combineReducers(listingManager),
  properties,
  locationsById,
  onboardingInsights,
  locationEvents,
  storage,
  customersById,
  customerLocationsById,
  customerProducts,
  bsp,
});

export default rootReducer;
