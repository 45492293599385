const langCodeMapping = {
  af: "Afrik\u00e1ans",
  am: "Am\u00e1rico",
  an: "Aragon\u00e9s",
  ar: "\u00e1rabe",
  as: "Asam\u00e9s",
  az: "Azerbaiyano",
  be: "Bielorruso",
  bg: "B\u00falgaro",
  bn: "Bengal\u00ed",
  br: "Bret\u00f3n",
  bs: "Bosnio",
  ca: "Catal\u00e1n",
  cs: "Checo",
  cy: "Gal\u00e9s",
  da: "Dan\u00e9s",
  de: "Alem\u00e1n",
  dz: "Dzongkha",
  el: "Griego",
  en: "Ingl\u00e9s",
  eo: "Esperanto",
  es: "Espa\u00f1ol",
  et: "Estonio",
  eu: "Euskera",
  fa: "Persa",
  fi: "Fin\u00e9s",
  fo: "Fero\u00e9s",
  fr: "Franc\u00e9s",
  ga: "Irland\u00e9s",
  gl: "Gallego",
  gu: "Guyarat\u00ed",
  he: "Hebreo",
  hi: "Hindi",
  hr: "Croata",
  ht: "Criollo haitiano",
  hu: "H\u00fangaro",
  hy: "Armenio",
  id: "Indonesio",
  is: "Island\u00e9s",
  it: "Italiano",
  ja: "Japon\u00e9s",
  jv: "Javan\u00e9s",
  ka: "Georgiano",
  kk: "Kazajo",
  km: "Jemer",
  kn: "Canar\u00e9s",
  ko: "Coreano",
  ku: "Kurdo",
  ky: "Kirgu\u00eds",
  la: "Lat\u00edn",
  lb: "Luxemburgu\u00e9s",
  lo: "Lao",
  lt: "Lituano",
  lv: "Let\u00f3n",
  mg: "Malgache",
  mk: "Macedonio",
  ml: "Malayalam",
  mn: "Mongol",
  mr: "Marat\u00ed",
  ms: "Malayo",
  mt: "Malt\u00e9s",
  nb: "Noruego bokmal",
  ne: "Nepal\u00ed",
  nl: "Neerland\u00e9s",
  nn: "Noruego nynorsk",
  no: "Noruego",
  oc: "Occitano",
  or: "Oriya",
  pa: "Punyab\u00ed",
  pl: "Polaco",
  ps: "Past\u00fan",
  pt: "Portugu\u00e9s",
  qu: "Quechua",
  ro: "Rumano",
  ru: "Ruso",
  rw: "Kinyarwanda",
  se: "Sami septentrional",
  si: "Cingal\u00e9s",
  sk: "Eslovaco",
  sl: "Esloveno",
  sq: "Alban\u00e9s",
  sr: "Serbio",
  sv: "Sueco",
  sw: "Suajili",
  ta: "Tamil",
  te: "Telugu",
  th: "Tailand\u00e9s",
  tl: "Tagalo",
  tr: "Turco",
  ug: "Uigur",
  uk: "Ucraniano",
  ur: "Urdu",
  vi: "Vietnamita",
  vo: "Volap\u00fck",
  wa: "Val\u00f3n",
  xh: "Xhosa",
  zh: "Chino",
  zu: "Zul\u00fa",
};

export default langCodeMapping;
