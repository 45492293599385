import has from "lodash/has";

import customerIOTracker, { domainsToNotTriggerCustomerIoEvents } from "./customerIO";
import loggerTracker from "./trackLogger";

const eventTrackers = [];

// Only add customerIO event if domain is not included in the black list
if (
  !domainsToNotTriggerCustomerIoEvents.some((domain) => window.location.origin.includes(domain))
) {
  eventTrackers.push(customerIOTracker);
}

const trackers = import.meta.env.VITE_TRACKING_DEBUG === "true" ? [loggerTracker] : eventTrackers;

export const trackUser = {
  /**
   * Default track function. Triggers all the trackers based off of the tracker event
   * @param {string} event - one of the tracker events to find which tracker needs to get triggered
   * @param {object} payload - to send to the handler of the tracker
   */
  track: (event, payload) => {
    trackers.forEach((plugin) => {
      if (has(plugin, "track")) {
        plugin.track(event, payload);
      }
    });
  },
  /**
   * Default identify function. Triggers all the trackers that have the identify key
   * @param {object} payload - for extra information for the identify function
   */
  identify: (payload) => {
    trackers.forEach((plugin) => {
      if (has(plugin, "identify")) {
        plugin.identify(payload);
      }
    });
  },
  /**
   * Default pageView function. Triggers all the trackers that have the pageView key
   * @param {string} url - url that corresponds to the page that the user visited
   * @param {object} payload - for extra information for the pageView function
   */
  pageView: (url, payload) => {
    trackers.forEach((plugin) => {
      if (has(plugin, "pageView")) {
        plugin.pageView(url, payload);
      }
    });
  },
};

// tracker events; exporting them all here so they can be easily imported with the tracker import
export * from "./events";

export default trackUser;
