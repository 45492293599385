import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

const CardHeader = (props) => {
  const { primary, centered, children, className, as: Element } = props;
  const classes = cx(
    "card-header",
    {
      "bg-primary text-white": primary,
      "text-center": centered,
    },
    className,
  );
  return <Element className={classes}>{children}</Element>;
};

CardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  primary: PropTypes.bool,
  centered: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

CardHeader.defaultProps = {
  children: "",
  className: "",
  as: "div",
  primary: false,
  centered: false,
};

export default CardHeader;
