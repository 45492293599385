import React, { useEffect } from "react";

import { isTeyaDomain } from "actions/fetch";
import { useLocation } from "react-router";

import { isCarbonPath } from "./general";

export const EnableTeyaSkin = ({ children }: { children: React.ReactNode }) => {
  const isTeya = isTeyaDomain();
  const { pathname } = useLocation();

  // TODO: need to remove useEffect for stand-alone, it may affect url redirection
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const htmlTag = document.getElementsByTagName("html")[0];
    const isCarbonRoute = isCarbonPath(pathname);
    const splitPathnames = pathname.split("/");
    const listingsIndex = splitPathnames.findIndex((pathName) => pathName === "listings");
    const lastRouteName = splitPathnames[splitPathnames.length - 1];
    const isEngageOrResults = lastRouteName === "reviews" || lastRouteName === "results";

    if (isTeya) {
      body.style.fontSize = "16px";
      htmlTag.style.fontSize = "16px";
    }
    return () => {
      if ((!isTeya && !isCarbonRoute) || (!isTeya && isEngageOrResults && listingsIndex > 0)) {
        body.style.fontSize = "14px";
        htmlTag.style.fontSize = "14px";
      }
    };
  }, [isTeya, pathname]);

  if (isTeya) {
    return <div className="teya-skin">{children}</div>;
  }
  return children;
};

export default EnableTeyaSkin;
