import { loadingBarMiddleware } from "react-redux-loading-bar";
import { createStore, applyMiddleware, compose } from "redux";
import promise from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";

import rootReducer from "../reducers";

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
/* eslint-enable */

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, promise, loadingBarMiddleware())),
  );
}
