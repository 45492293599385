import React, { MutableRefObject, ReactElement, useContext } from "react";

import useGooglePlaceService from "hooks/useGooglePlaceService";

export type GoogleLibContextType = {
  placeServiceReady: boolean;
  placeDetailsServiceRef: MutableRefObject<google.maps.places.PlacesService | undefined>;
  placeLibraryRef: MutableRefObject<google.maps.PlacesLibrary | undefined>;
  mapLibraryRef: MutableRefObject<google.maps.MapsLibrary["Map"] | undefined>;
  markerLibraryRef: MutableRefObject<
    google.maps.MarkerLibrary["AdvancedMarkerElement"] | undefined
  >;
} | null;

const GoogleLibContext = React.createContext<GoogleLibContextType>(null);

const GoogleLibProvider = ({
  children,
  language,
}: {
  children: ReactElement;
  language: string;
}) => {
  const {
    placeServiceReady,
    placeDetailsServiceRef,
    placeLibraryRef,
    mapLibraryRef,
    markerLibraryRef,
  } = useGooglePlaceService(language);

  return (
    <GoogleLibContext.Provider
      value={{
        placeServiceReady,
        placeDetailsServiceRef,
        placeLibraryRef,
        mapLibraryRef,
        markerLibraryRef,
      }}
    >
      {children}
    </GoogleLibContext.Provider>
  );
};

const useGoogleLib = () => {
  const context = useContext(GoogleLibContext);
  if (context === undefined) {
    throw new Error("useGoogleLib must be used within a GoogleLibProvider");
  }
  if (context === null) {
    throw new Error("GoogleLibContext somehow is null");
  }
  return context;
};

export { useGoogleLib, GoogleLibProvider };
