import React, { useEffect } from "react";

import { showReportDialog, ErrorBoundary } from "@sentry/react";
import { Information } from "assets/icons/teya";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import EnableTeyaSkin from "utils/EnableTeyaSkin";
import { getSetLanguage } from "utils/languages";

import log, { LOG_LEVEL } from "../../utils/logger";
import { Card, CardBody } from "./card";
import PopupPage from "./PopupPage";

const ErrorModal = ({ sentryError, customer }, { t }) => {
  const {
    error: { message },
  } = sentryError;
  const { firstname, lastname, email } = customer;

  useEffect(() => {
    log(message, { sentryError }, LOG_LEVEL.ERROR);
  }, [message, sentryError]);

  // const timeout = useRef(null);
  // useEffect(() => {
  //   // This is a hack to enable the teya skin on the sentry error form
  //   timeout.current = setTimeout(() => {
  //     document.querySelector(".sentry-error-embed-wrapper")?.classList?.add("teya-skin");
  //   }, 500);
  //   return () => {
  //     if (timeout.current) {
  //       clearTimeout(timeout.current);
  //     }
  //   };
  // }, []);

  return (
    <PopupPage>
      <Card className="error-card">
        <CardBody className="p-5">
          <Information className="tw-h-10 tw-w-10" />
          <p>
            {t(
              "Oops, an error occured in the app. If you haven't filled in our crash report form yet, {x}.",
              {
                x: (
                  <button
                    type="button"
                    className="show-report-button"
                    onClick={() => {
                      showReportDialog({
                        user: {
                          name: `${firstname} ${lastname}`,
                          email: `${email}`,
                        },
                        lang: getSetLanguage(),
                      });
                    }}
                  >
                    {t("click here to open the form")}
                  </button>
                ),
              },
            )}
          </p>
          {message && <p className="text-center text-danger">{message}</p>}
          <div style={{ marginTop: "2rem" }}>
            <a href={`${window.location.origin}/admin`} className="back-to-overview-button">
              {t("Go back to the overview")}
            </a>
          </div>
        </CardBody>
      </Card>
    </PopupPage>
  );
};

ErrorModal.propTypes = {
  sentryError: PropTypes.object.isRequired,
  customer: PropTypes.object,
};

ErrorModal.defaultProps = {
  customer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
  }),
};

ErrorModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const SentryErrorBoundary = ({ children }) => {
  // Since we can't use useParams, we have to manually get the customerId
  // From the url
  const { pathname } = useLocation();
  const splitPaths = pathname?.split("/") ?? [];
  const customerUrlIndex = splitPaths.findIndex((pathPiece) => pathPiece === "customer");
  const customerId = customerUrlIndex === -1 ? null : splitPaths?.[customerUrlIndex + 1];
  // get data from both the old and new user api
  const { customer, user } = useSelector((state) => ({
    customer: state?.customersById?.[customerId]?.customer ?? {},
    user: state?.user?.data ?? {},
  }));

  const {
    firstname: customerFirstname,
    lastname: customerLastName,
    email: customerEmail,
  } = customer;
  const { first_name: userFirstname, last_name: userLastName, email: userEmail } = user;
  const firstName = userFirstname || customerFirstname || "";
  const lastName = userLastName || customerLastName || "";
  const email = userEmail || customerEmail || "";

  return (
    <ErrorBoundary
      showDialog
      dialogOptions={{
        user: {
          name: `${firstName} ${lastName}`,
          email: `${email}`,
        },
        lang: getSetLanguage(),
      }}
      fallback={(error) => (
        <EnableTeyaSkin>
          <ErrorModal sentryError={error} customer={{ firstName, lastName, email }} />
        </EnableTeyaSkin>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

SentryErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SentryErrorBoundary;
