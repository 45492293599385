import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

const PopupWrapper = ({ children, wide, color }) => {
  return (
    <div>
      <div className="popup fade show d-flex">
        <div className={cx("popup-dialog", { wide })}>{children}</div>
      </div>
      <div
        className={cx("popup-backdrop fade show", {
          "bg-white": color === "white",
        })}
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

PopupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  color: PropTypes.string,
};

PopupWrapper.defaultProps = {
  wide: false,
  color: "rgba(0, 0, 0, 0.5)",
};

export default PopupWrapper;
