import React, { SVGProps } from "react";

import { useBreakpointDown } from "hooks/useWindowDimensions";

const TeyaLogo = (props: SVGProps<SVGSVGElement>) => {
  const isMobile = useBreakpointDown("md");

  return (
    <svg
      width={isMobile ? "36" : "98"}
      height="36"
      viewBox={isMobile ? "0 0 36 36" : "0 0 98 36"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {!isMobile && (
        <g>
          <path
            d="M65.0807 21.9329C64.7216 22.4062 64.1505 23.01 63.3019 23.418C61.5068 24.2665 59.4995 23.7443 58.4061 22.9447C58.0634 22.6999 57.835 22.4225 57.786 22.3735C57.3128 21.835 56.9864 21.1496 56.8232 20.3336H68.2955C68.3771 19.7788 68.4098 19.3382 68.4098 18.9955C68.4098 16.825 67.7407 14.9646 66.3699 13.447C65.0154 11.9293 63.2856 11.1623 61.1641 11.1623C58.9284 11.1623 57.1169 11.913 55.6809 13.398C54.2611 14.8994 53.543 16.7434 53.543 18.9628C53.543 21.2149 54.2611 23.0916 55.6972 24.5603C56.1215 24.9846 56.5784 25.3599 57.0843 25.67C58.9773 26.8286 61.0172 26.796 61.3762 26.7797C62.029 26.7634 64.2321 26.6491 66.0925 24.9683C66.8105 24.3318 67.2511 23.6464 67.5122 23.1732C66.7452 22.7652 65.9129 22.3409 65.0807 21.9329ZM58.2756 15.0462C59.0426 14.3935 60.0054 14.0671 61.1641 14.0671C62.1759 14.0671 63.0734 14.3772 63.8241 14.981C64.5911 15.6011 65.0643 16.4986 65.2765 17.7063H56.8069C57.019 16.5966 57.5086 15.699 58.2756 15.0462Z"
            fill="#151515"
          />

          <path
            d="M94.553 11.6519H97.6537V26.3228H94.553C94.553 25.7516 94.553 25.1804 94.553 24.6256C94.1777 24.9846 93.6228 25.4579 92.8395 25.8658C91.2729 26.6818 89.8531 26.7144 89.543 26.7144C87.5684 26.7144 85.8876 25.9638 84.4841 24.4624C83.0807 22.961 82.3953 21.1496 82.3953 18.9955C82.3953 16.825 83.097 14.9973 84.4841 13.5123C85.2511 12.6963 86.116 12.0925 87.0788 11.7335C88.1722 11.3092 89.0861 11.2929 89.543 11.2929C90.1795 11.2929 91.6156 11.2929 93.1006 12.1578C93.786 12.5658 94.2593 13.0227 94.5693 13.3491C94.553 12.7616 94.553 12.2067 94.553 11.6519ZM86.7688 22.3409C87.6337 23.2385 88.7108 23.6791 90.0163 23.6791C91.3218 23.6791 92.3989 23.2385 93.2638 22.3409C94.1287 21.4434 94.553 20.3337 94.553 18.9792C94.553 17.6247 94.1287 16.515 93.2638 15.6174C92.3989 14.7199 91.3218 14.2793 90.0163 14.2793C88.7108 14.2793 87.6337 14.7199 86.7688 15.6174C85.9039 16.515 85.4796 17.6247 85.4796 18.9792C85.4796 20.3337 85.9039 21.4434 86.7688 22.3409Z"
            fill="#151515"
          />
          <path
            d="M45.1876 18C45.1713 18.2774 45.1713 18.6691 45.1876 19.126C45.2692 21.0843 45.3508 22.7652 46.4769 24.2665C48.0761 26.388 50.6872 26.7144 50.9157 26.747C51.291 26.7797 51.6011 26.7797 51.8295 26.7797H52.6292V23.6627H51.8948C50.932 23.6464 50.3282 23.3037 50.0671 23.1242C48.7452 22.243 48.5167 20.5784 48.4515 20.1867C48.4188 20.0072 48.3862 19.6645 48.3699 19.2892C48.3372 18.7507 48.3372 18.31 48.3372 18V14.2303H52.6292V11.1949H48.3372V7.14776V6.77242C47.2439 7.06617 46.1831 7.45783 45.1713 7.9474V18H45.1876Z"
            fill="#151515"
          />
          <path
            d="M79.4252 11.3418C78.185 14.932 76.9447 18.5385 75.7044 22.1287C74.7579 20.1215 73.8277 18.0163 72.9139 15.7806C72.2937 14.2629 71.7389 12.7942 71.2004 11.3418H67.7081C69.1931 14.883 70.3681 17.7063 71.2493 19.7625C71.8694 21.1333 72.4896 22.5204 73.1097 23.8912C73.2076 24.0707 73.3218 24.3808 73.4198 24.7398C73.534 25.1478 73.6645 25.67 73.5666 26.2738C73.3871 27.2856 72.6038 27.8894 72.2774 28.1342C71.592 28.6727 70.874 28.8522 70.4497 28.9175C70.4497 30.0109 70.4497 31.1043 70.4497 32.1976C70.9719 32.1324 71.6899 32.0018 72.5059 31.6591C73.2892 31.3327 74.35 30.7289 75.5086 29.3907C76.6346 28.1015 77.3037 26.7797 78.4297 23.9075C79.6374 20.8069 80.2085 18.8486 81.5467 14.932C82.0689 13.398 82.5095 12.1414 82.8033 11.3255L79.4252 11.3418Z"
            fill="#151515"
          />
        </g>
      )}
      <path
        d="M28.4769 0H7.5068C3.36174 0 0 3.36174 0 7.5068V28.4932C0 32.6383 3.36174 36 7.5068 36H28.4932C32.6383 36 36 32.6383 36 28.4932V7.5068C35.9837 3.36174 32.6219 0 28.4769 0ZM7.45784 15.34V9.10607C10.9991 9.10607 14.2303 10.4606 16.6618 12.6636C15.2257 14.3445 14.1487 16.3354 13.5449 18.5059C12.1904 16.5966 9.97099 15.34 7.45784 15.34ZM28.5258 15.34C24.4297 15.34 21.117 18.6691 21.117 22.7489V26.8776H14.8667V22.7489C14.8667 19.4524 16.0417 16.417 17.9837 14.0508C20.4968 11.0154 24.2829 9.08976 28.5258 9.08976V15.34Z"
        fill="#151515"
      />
      <path
        d="M28.5258 15.34C24.4297 15.34 21.1169 18.6691 21.1169 22.7488V26.8776H14.8667V22.7488C14.8667 19.4524 16.0417 16.417 17.9837 14.0508C20.4968 11.0154 24.2829 9.08974 28.5258 9.08974V15.34Z"
        fill="#DDE048"
      />
      <path
        d="M7.45786 15.34V9.10606C10.9991 9.10606 14.2303 10.4606 16.6618 12.6636C15.2258 14.3445 14.1487 16.3354 13.5449 18.5059C12.1904 16.5965 9.971 15.34 7.45786 15.34Z"
        fill="white"
      />
    </svg>
  );
};

export default TeyaLogo;
