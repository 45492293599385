const langCodeMapping = {
  af: "Afrik\u00e1n\u0161tina",
  am: "Amhar\u0161tina",
  an: "Aragon\u0161tina",
  ar: "Arab\u0161tina",
  as: "\u00e1s\u00e1m\u0161tina",
  az: "\u00e1zerb\u00e1jd\u017e\u00e1n\u0161tina",
  be: "B\u011bloru\u0161tina",
  bg: "Bulhar\u0161tina",
  bn: "Beng\u00e1l\u0161tina",
  br: "Breton\u0161tina",
  bs: "Bosen\u0161tina",
  ca: "Katal\u00e1n\u0161tina",
  cs: "\u010de\u0161tina",
  cy: "Vel\u0161tina",
  da: "D\u00e1n\u0161tina",
  de: "N\u011bm\u010dina",
  dz: "Dzongk\u00e4",
  el: "\u0159e\u010dtina",
  en: "Angli\u010dtina",
  eo: "Esperanto",
  es: "\u0161pan\u011bl\u0161tina",
  et: "Eston\u0161tina",
  eu: "Baski\u010dtina",
  fa: "Per\u0161tina",
  fi: "Fin\u0161tina",
  fo: "Faer\u0161tina",
  fr: "Francouz\u0161tina",
  ga: "Ir\u0161tina",
  gl: "Galicij\u0161tina",
  gu: "Gud\u017ear\u00e1t\u0161tina",
  he: "Hebrej\u0161tina",
  hi: "Hind\u0161tina",
  hr: "Chorvat\u0161tina",
  ht: "Hait\u0161tina",
  hu: "Ma\u010far\u0161tina",
  hy: "Arm\u00e9n\u0161tina",
  id: "Indon\u00e9\u0161tina",
  is: "Island\u0161tina",
  it: "Ital\u0161tina",
  ja: "Japon\u0161tina",
  jv: "Jav\u00e1n\u0161tina",
  ka: "Gruz\u00edn\u0161tina",
  kk: "Kaza\u0161tina",
  km: "Khm\u00e9r\u0161tina",
  kn: "Kannad\u0161tina",
  ko: "Korej\u0161tina",
  ku: "Kurd\u0161tina",
  ky: "Kyrgyz\u0161tina",
  la: "Latina",
  lb: "Lucembur\u0161tina",
  lo: "Lao\u0161tina",
  lt: "Litev\u0161tina",
  lv: "Loty\u0161tina",
  mg: "Malga\u0161tina",
  mk: "Makedon\u0161tina",
  ml: "Malaj\u00e1lam\u0161tina",
  mn: "Mongol\u0161tina",
  mr: "Mar\u00e1th\u0161tina",
  ms: "Malaj\u0161tina",
  mt: "Malt\u0161tina",
  nb: "Nor\u0161tina (bokm\u00e5l)",
  ne: "Nep\u00e1l\u0161tina",
  nl: "Nizozem\u0161tina",
  nn: "Nor\u0161tina (nynorsk)",
  no: "Nor\u0161tina",
  oc: "Okcit\u00e1n\u0161tina",
  or: "Urij\u0161tina",
  pa: "Pa\u0148d\u017e\u00e1b\u0161tina",
  pl: "Pol\u0161tina",
  ps: "Pa\u0161t\u0161tina",
  pt: "Portugal\u0161tina",
  qu: "Ke\u010du\u00e1n\u0161tina",
  ro: "Rumun\u0161tina",
  ru: "Ru\u0161tina",
  rw: "Ki\u0148arwand\u0161tina",
  se: "S\u00e1m\u0161tina (severn\u00ed)",
  si: "Sinh\u00e1l\u0161tina",
  sk: "Sloven\u0161tina",
  sl: "Slovin\u0161tina",
  sq: "Alb\u00e1n\u0161tina",
  sr: "Srb\u0161tina",
  sv: "\u0161v\u00e9d\u0161tina",
  sw: "Svahil\u0161tina",
  ta: "Tamil\u0161tina",
  te: "Telug\u0161tina",
  th: "Thaj\u0161tina",
  tl: "Tagalog",
  tr: "Ture\u010dtina",
  ug: "Ujgur\u0161tina",
  uk: "Ukrajin\u0161tina",
  ur: "Urd\u0161tina",
  vi: "Vietnam\u0161tina",
  vo: "Volap\u00fck",
  wa: "Valon\u0161tina",
  xh: "Xho\u0161tina",
  zh: "\u010d\u00edn\u0161tina",
  zu: "Zulu\u0161tina",
};

export default langCodeMapping;
