import { Loader } from "@googlemaps/js-api-loader";
import { captureException, addBreadcrumb } from "@sentry/react";
import { isTeyaDomain, reactQueryFetch as fetch } from "actions/fetch";
import moment from "moment";
import { z } from "zod";

export const validId = (id: unknown): boolean => {
  if (typeof id === "string") {
    // not empty string
    if (id.length > 0) {
      const NumberId = Number(id);
      return Number.isInteger(NumberId) && NumberId >= 0;
    }
    return false;
  }

  if (typeof id === "number") {
    const NumberId = Number(id);
    return Number.isInteger(NumberId) && NumberId >= 0;
  }

  // null, array, object, undefined
  return false;
};

export const validIds = (ids: unknown[] | undefined): boolean => {
  if (ids === undefined) {
    return false;
  }
  if (ids.length === 0) {
    return false;
  }
  return ids.every((id: unknown) => {
    return validId(id);
  });
};

// Utility type to format display nested types
export type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};

export type EmptyObjectType = Record<string, never>;

export type PaginationType = {
  page: string;
  per_page: string;
};

export type ReactNodeNext =
  | Exclude<React.ReactNode, React.ReactFragment | React.ReactChild>
  | string
  | number;

export const schemaParse = <SchemaGeneric extends z.ZodTypeAny>({
  validSchema,
  incomingData,
  invalidMessage,
}: {
  validSchema: SchemaGeneric;
  incomingData: unknown;
  invalidMessage: string;
}): z.infer<SchemaGeneric> => {
  const parsedData = validSchema.safeParse(incomingData);
  if (!parsedData.success) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.error(invalidMessage, { incomingData, errors: parsedData.error.issues });
    }

    addBreadcrumb({
      category: "zod",
      data: incomingData as Record<string, unknown>,
      level: "debug",
    });

    addBreadcrumb({
      category: "zod",
      data: {
        data: JSON.stringify(
          parsedData.error.issues.map((issue) => ({ code: issue.code, path: issue.path })),
        ),
      },
      level: "debug",
    });

    const { error } = parsedData;
    error.name = invalidMessage;
    captureException(error, {
      tags: {
        section: "zod error",
      },
    });
    return incomingData as z.infer<SchemaGeneric>;
  }

  return parsedData.data;
};

type OpeningHour = {
  closed: boolean;
  dayOfWeek: number;
  from1?: string;
  to1?: string;
};

export type FormatGooglePlaceAddress = {
  name: string;
  city: string;
  country: string;
  streetAndNumber: string;
  zip: string;
  lat: number;
  lng: number;
  website: string;
  phone: string;
  openingHours: OpeningHour[] | undefined;
};

export const requestAndValidate = async <SchemaGeneric extends z.ZodTypeAny>({
  invalidMessage,
  requestUrl,
  schema,
}: {
  invalidMessage: string;
  requestUrl: string;
  schema: SchemaGeneric;
}) => {
  const data = await fetch(requestUrl);
  const jsonData = await data.json();
  return schemaParse({
    validSchema: schema,
    incomingData: jsonData,
    invalidMessage,
  });
};

export function belongsToArray<TValue>(
  value: unknown,
  allowedValues: ReadonlyArray<TValue>,
): value is TValue {
  return (allowedValues as ReadonlyArray<unknown>).includes(value);
}

export const loadGoogleMapsLib = async ({
  language,
  reload,
}: {
  language: string;
  reload?: boolean;
}) => {
  // https://github.com/googlemaps/js-api-loader/issues/5#issuecomment-1672032132
  // Check if Google Maps has already been loaded
  if (!window?.google?.maps || reload) {
    return new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
      version: "weekly",
      libraries: ["places"],
      language,
      // Use .importLibrary('core'); in place of .load() to make google.maps available in the global scope.
    }).importLibrary("core");
  }

  return Promise.resolve();
};

export const isCarbonPath = (currentPath: string): boolean => {
  const nonCarbonPattern = /(\/)?admin\/app\/customer(\/\w+)?/;

  return !nonCarbonPattern.test(currentPath);
};

export const convertDateformat = (value: string | null | undefined) => {
  const isTeyaUser = isTeyaDomain();
  let formattedDate = "N/A";
  if (value) {
    if (isTeyaUser) {
      formattedDate = moment(value).format("DD/MM/YYYY");
    } else {
      formattedDate = moment(value).format("ll");
    }
  }

  return formattedDate;
};
