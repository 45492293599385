import React, { SVGProps } from "react";

import cx from "classnames";

const ChevronUp = (props?: SVGProps<SVGSVGElement>) => {
  const { className, ...otherProps } = props ?? {};
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("tw-fill-primary-charcoal-300", className)}
      {...otherProps}
    >
      <path d="M16.9999 13.4101L12.7099 9.17007C12.617 9.07634 12.5064 9.00194 12.3845 8.95117C12.2627 8.90041 12.132 8.87427 11.9999 8.87427C11.8679 8.87427 11.7372 8.90041 11.6154 8.95117C11.4935 9.00194 11.3829 9.07634 11.2899 9.17007L7.04995 13.4101C6.95622 13.503 6.88183 13.6136 6.83106 13.7355C6.78029 13.8573 6.75415 13.9881 6.75415 14.1201C6.75415 14.2521 6.78029 14.3828 6.83106 14.5046C6.88183 14.6265 6.95622 14.7371 7.04995 14.8301C7.23731 15.0163 7.49076 15.1209 7.75495 15.1209C8.01913 15.1209 8.27259 15.0163 8.45995 14.8301L11.9999 11.2901L15.5399 14.8301C15.7262 15.0148 15.9776 15.119 16.2399 15.1201C16.3716 15.1208 16.502 15.0956 16.6239 15.0458C16.7457 14.9961 16.8565 14.9227 16.9499 14.8301C17.047 14.7404 17.1254 14.6325 17.1805 14.5124C17.2356 14.3923 17.2664 14.2625 17.271 14.1305C17.2757 13.9985 17.2541 13.8668 17.2076 13.7432C17.161 13.6195 17.0905 13.5063 16.9999 13.4101Z" />
    </svg>
  );
};

export default ChevronUp;
