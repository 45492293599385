import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Spinner = ({ style, scale, className, withOverlay }) => {
  // It's necessary to use scale here instead of width / height.
  // Mainly since the ratio between width, height and borderwidth needs to stay consistent
  const baseSize = 2;
  const baseBorderWidth = 0.25;
  const size = `${baseSize * scale}em`;
  const borderWidth = `${baseBorderWidth * scale}em`;
  const { t } = useTranslation();

  const SpinnerWrapper = () => (
    <div className={cx("SpinnerWrapper", className)}>
      <div
        className="spinner-border text-primary"
        style={{
          width: size,
          height: size,
          borderWidth,
          ...style,
        }}
        role="status"
      >
        <span className="sr-only">{t("Loading")}</span>
      </div>
    </div>
  );

  if (withOverlay) {
    return (
      <div className="spinner-overlay">
        <SpinnerWrapper />
      </div>
    );
  }

  return <SpinnerWrapper />;
};

Spinner.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  scale: PropTypes.number,
  withOverlay: PropTypes.bool,
};

Spinner.defaultProps = {
  style: {},
  className: "",
  scale: 1,
  withOverlay: false,
};

export default Spinner;
