/**
 * For more info about events, go to: https://sowifi.atlassian.net/wiki/spaces/TDW/pages/1626963969/Customer+IO+events+guidelines
 */
// These should be renamed to the naming convention when we fully migrate to customerIO
export const EVENT_LL_FINISH_LATER = "ll_finish_later";
export const EVENT_LL_LOCATION_UPDATED = "local_listing_location_updated";

export const EVENT_RM_ADD_COMPETITOR = "re_competitor_added";
export const EVENT_RM_PROPERTY_UPDATED = "re_property_channels_updated";
export const EVENT_RM_SUBMITTED_SENTIMENT_FEEDBACK = "re_sentiment_comment_feedback_submitted";
export const EVENT_RR_START_CAMPAIGN = "rr_campaign_started";
export const EVENT_LI_SUGGESTIONS_ACCEPTED = "li_suggestions_accepted";
