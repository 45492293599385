import React from "react";

import PropTypes from "prop-types";

import Spinner from "./Spinner";

const LoadingPopup = ({ text }) => (
  <div className="d-flex align-items-center justify-content-center flex-column">
    <div className="line mb-2">
      <Spinner scale={1.2} />
    </div>
    {text && (
      <div className="line">
        <p className="text">{text}</p>
      </div>
    )}
  </div>
);

LoadingPopup.propTypes = {
  text: PropTypes.string,
};

LoadingPopup.defaultProps = {
  text: "",
};

export default LoadingPopup;
