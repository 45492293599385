import React, { useCallback } from "react";

import { setListingLocationError } from "actions";
import { useUser } from "context/UserContext";
import { TFunction, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

type cleansingInvalidDataReasonType =
  | "INCOMPLETE_ADDRESS"
  | "ADDRESS_DOES_NOT_EXIST"
  | "ADDRESS_IS_PO_BOX"
  | "INFORMATION_IS_WRONG"
  | "NON_LATIN_CHARACTERS_ADDRESS"
  | "LOCATION_IS_CLOSED"
  | "UNDEFINED";

const cleansingInvalidDataMap = {
  INCOMPLETE_ADDRESS: "ADDRESS_DETAILS_VERIFIED",
  ADDRESS_DOES_NOT_EXIST: "ADDRESS_DETAILS_VERIFIED",
  ADDRESS_IS_PO_BOX: "NAME_ADDRESS_DETAILS_UPDATED",
  INFORMATION_IS_WRONG: "NAME_ADDRESS_DETAILS_UPDATED",
  NON_LATIN_CHARACTERS_ADDRESS: "NAME_ADDRESS_DETAILS_UPDATED",
  LOCATION_IS_CLOSED: "NON_CLOSURE_CONFIRMATION",
  UNDEFINED: "ADDRESS_DETAILS_VERIFIED",
} as const;

// based on this uberall article http://ubr.al/fix_invalid_data
export const useDispatchCleansingError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: { id: userId },
  } = useUser();

  const dispatchCleansingError = useCallback(
    (cleansingInvalidDataReason: cleansingInvalidDataReasonType, locationId: string) => {
      const redirect = (step: "location" | "details") => {
        history.replace(
          `/admin/app/customer/${userId}/location/${locationId}/listings/edit/${step}`,
        );
      };
      switch (cleansingInvalidDataReason) {
        case "INCOMPLETE_ADDRESS":
        case "ADDRESS_DOES_NOT_EXIST":
          dispatch(
            setListingLocationError({
              message:
                cleansingInvalidDataReason === "ADDRESS_DOES_NOT_EXIST"
                  ? t(
                      "We can't find a business with the given address details. Please move the marker on the map and check the address again.",
                    )
                  : t(
                      "The address isn't filled in completely. Please move the marker on the map and check the address again.",
                    ),
              customAction: (
                <Link
                  to={`/admin/app/customer/${userId}/location/${locationId}/listings/edit/details`}
                >
                  {t("Click here to change your address")}
                </Link>
              ),
              response: {
                street: true,
              },
            }),
          );
          redirect("location");
          break;
        case "ADDRESS_IS_PO_BOX":
        case "INFORMATION_IS_WRONG":
          dispatch(
            setListingLocationError({
              message:
                cleansingInvalidDataReason === "ADDRESS_IS_PO_BOX"
                  ? t(
                      "The given address points to a PO box. Please set the name and address based on your business.",
                    )
                  : t(
                      "The given name or address doesn't point to a business. Please check the address and name again.",
                    ),
              customAction: <></>,
              response: {
                street: true,
              },
            }),
          );
          redirect("details");
          break;
        case "NON_LATIN_CHARACTERS_ADDRESS":
          dispatch(
            setListingLocationError({
              message: t(
                "Your address contains non-Latin characters. Please change your address to use Latin characters.",
              ),
              customAction: <></>,
              response: {
                street: true,
              },
            }),
          );
          redirect("details");
          break;
        default:
          dispatch(
            setListingLocationError({
              message: t(
                "This address could not be located. Please move the marker on the map and check the given address.",
              ),
              customAction: (
                <Link
                  to={`/admin/app/customer/${userId}/location/${locationId}/listings/edit/details`}
                >
                  {t("Click here to check your address")}
                </Link>
              ),
              response: {
                street: true,
              },
            }),
          );
          redirect("details");
      }
    },
    [dispatch, history, t, userId],
  );

  return dispatchCleansingError;
};

export const getCleansingCommentValue = (
  cleansingInvalidDataReason: cleansingInvalidDataReasonType,
) => {
  return cleansingInvalidDataMap[cleansingInvalidDataReason] ?? "ADDRESS_DETAILS_VERIFIED";
};

export const logoTypes = {
  TYPE_LOGO: "LOGO",
  TYPE_SQUARED_LOGO: "SQUARED_LOGO",
  TYPE_MAIN: "MAIN",
  TYPE_LANDSCAPE: "LANDSCAPE",
  TYPE_PHOTO: "PHOTO",
};

export const photoTypes = (t: TFunction) => ({
  LOGO: {
    id: "LOGO",
    label: t("Logo"),
    pluralLabel: t("Logos"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_LOGO"),
    pickerOptions: {
      transformations: {
        crop: {
          aspectRatio: 1,
        },
      },
    },
  },
  SQUARED_LOGO: {
    id: "SQUARED_LOGO",
    label: t("Squared Logo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_SQUARED_LOGO"),
    pickerOptions: {
      transformations: {
        crop: {
          force: true,
          aspectRatio: 1,
        },
      },
    },
  },
  MAIN: {
    id: "MAIN",
    label: t("Main photo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_MAIN_PHOTO"),
    pickerOptions: {
      imageMin: [480, 480],
    },
  },
  LANDSCAPE: {
    id: "LANDSCAPE",
    label: t("Landscape photo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_LANDSCAPE_PHOTO"),
    pickerOptions: {
      imageMin: [0, 480],
      transformations: {
        crop: {
          force: true,
          aspectRatio: 16 / 9,
        },
      },
    },
  },
  PHOTO: {
    id: "PHOTO",
    label: t("Photos"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_OTHER_PHOTOS"),
    pickerOptions: {
      imageMin: [480, 480],
    },
  },
});

export type PhotoTypesMapping = ReturnType<typeof photoTypes>;

export default useDispatchCleansingError;
