import langCodes from "langCodes";
import memoizeOne from "memoize-one";

export const dashboardLanguages = (t) => [
  { code: "en", name: t("English") },
  { code: "nl", name: t("Dutch") },
  { code: "fr", name: t("French") },
  { code: "de", name: t("German") },
  { code: "es", name: t("Spanish") },
  { code: "it", name: t("Italian") },
  { code: "pt", name: t("Portuguese") },
];

const getLanguageObject = (code) => ({
  code,
  name: langCodes.all[code],
});

export const memoizedGetLanguage = memoizeOne(getLanguageObject);

export const getLangCodeForUberallApi = (lang) => {
  if (lang === "pt-br") {
    return "pt";
  }
  return lang;
};

export const getSetLanguage = () => document?.getElementsByTagName("html")[0].lang || "en";

const getLanguage = (lang) => {
  const currentLang = getLangCodeForUberallApi(getSetLanguage());
  const langCodeMapping = { ...langCodes.all, ...langCodes[currentLang] };

  return langCodeMapping[lang];
};

export default getLanguage;
