import head from "lodash/head";

import { getCookie } from "hooks/useCookieWatcher";

import fetch, { getHost, handleFetchError, getV3Host, handle401Redirection } from "./fetch";
import { receiveSubscription } from "./subscriptions";

export const REQUEST_LOCATION = "REQUEST_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const RECEIVE_LOCATION = "RECEIVE_LOCATION";
export const REQUEST_LOCATION_SUBSCRIPTIONS = "REQUEST_LOCATION_SUBSCRIPTIONS";
export const RECEIVE_LOCATION_SUBSCRIPTIONS = "RECEIVE_LOCATION_SUBSCRIPTIONS";
export const REQUEST_PROPERTY_INFO = "REQUEST_PROPERTY_INFO";
export const RECEIVE_PROPERTY_INFO = "RECEIVE_PROPERTY_INFO";
export const REQUEST_PROPERTY_SEARCH = "REQUEST_PROPERTY_SEARCH";
export const RECEIVE_PROPERTY_SEARCH = "RECEIVE_PROPERTY_SEARCH";
export const REQUEST_PROPERTY_HEALTH = "REQUEST_PROPERTY_HEALTH";
export const RECEIVE_PROPERTY_HEALTH = "RECEIVE_PROPERTY_HEALTH";
export const RECEIVE_LOCATION_CAMPAIGN = "RECEIVE_LOCATION_CAMPAIGN";
export const REQUEST_LOCATION_CAMPAIGNS_LINKAGE = "REQUEST_LOCATION_CAMPAIGNS_LINKAGE";
export const RECEIVE_LOCATION_CAMPAIGNS_LINKAGE = "RECEIVE_LOCATION_CAMPAIGNS_LINKAGE";
export const PROPERTY_ACTIVATED = "PROPERTY_ACTIVATED";
export const REQUEST_LOCATION_UPDATE = "REQUEST_LOCATION_UPDATE";
export const RECEIVE_LOCATION_UPDATE = "RECEIVE_LOCATION_UPDATE";
export const FETCH_LOCATION_ONBOARDING_INSIGHTS = "FETCH_LOCATION_ONBOARDING_INSIGHTS";
export const LOCATION_EVENT_TRIGGER = "LOCATION_EVENT_TRIGGER";

const LISTINGS_ONBOARDING_EMAIL = "listings_onboarding_email";
const LISTINGS_EMAIL_ACTIVATION = "listings_email_activation";
const LISTINGS_INFO_EMAIL = "listings_info_email";

export const events = {
  LISTINGS_ONBOARDING_EMAIL,
  LISTINGS_EMAIL_ACTIVATION,
  LISTINGS_INFO_EMAIL,
};

const csrfToken = getCookie("XSRF-TOKEN");

const listingsFetch = (url, options) =>
  fetch(url, {
    ...options,
    ...{
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        origin: window.location.origin,
        "X-XSRF-TOKEN": csrfToken,
      },
    },
  }).catch((err) => {
    handle401Redirection(err.status);
    return Promise.reject(err);
  });

const locationUrl = (locationId) => `${getHost()}/locations/${locationId}`;
const locationUrlV3 = (locationId) => `${getV3Host()}/locations/${locationId}`;
const locationCampaignsUrl = (id) => `${getHost()}/location-campaigns/${id}`;
const propertyInfoUrl = () => `${getHost()}/reviews/propertyInfo`;
const activationUrl = () => `${getHost()}/reviews/activation`;
const propertyCheckUrl = () => `${getHost()}/reviews/propertyCheck`;
const propertySearchUrl = () => `${getHost()}/reviews/propertySearch`;
const campaignsListUrl = (locationId) => `${locationUrl(locationId)}/location-campaigns`;

/**
 * Locations are DEPRECATED and slowly will be removed in favor of
 * new SO Connect core and Venues entity.
 * @deprecated
 */

export function requestLocationUpdate(locationId, customerId, data) {
  return {
    type: REQUEST_LOCATION_UPDATE,
    locationId,
    customerId,
    data,
  };
}

export function receiveLocationUpdate(location, customerId) {
  return {
    type: RECEIVE_LOCATION_UPDATE,
    receivedAt: Date.now(),
    location,
    customerId,
  };
}

export function receiveLocationUpdateV3({ locationId, placeId, customerId, listingId }) {
  const updateLocationValue = {
    id: locationId,
    ...(placeId ? { place_id: placeId } : {}),
    ...(listingId ? { listing_id: listingId } : {}),
  };

  return {
    type: RECEIVE_LOCATION_UPDATE,
    receivedAt: Date.now(),
    location: updateLocationValue,
    customerId,
  };
}

export function receiveLocation(location) {
  return {
    type: RECEIVE_LOCATION,
    location,
  };
}

export function requestLocationCampaign(locationId) {
  return {
    type: REQUEST_LOCATION,
    locationId,
  };
}

function receiveLocationSubscriptions(locationId, subscriptions) {
  return {
    type: RECEIVE_LOCATION_SUBSCRIPTIONS,
    locationId,
    subscriptions,
    receivedAt: Date.now(),
  };
}

function receiveProperty(propertyId, property) {
  return {
    type: RECEIVE_PROPERTY_INFO,
    propertyId,
    property,
  };
}

function receivePropertyHealth(propertyId, health) {
  return {
    type: RECEIVE_PROPERTY_HEALTH,
    propertyId,
    health,
  };
}

function requestPropertyHealth(propertyId) {
  return {
    type: REQUEST_PROPERTY_HEALTH,
    propertyId,
  };
}

function propertyActivated(propertyId, status) {
  return {
    type: PROPERTY_ACTIVATED,
    propertyId,
    status,
  };
}

function requestProperty(propertyId) {
  return {
    type: REQUEST_PROPERTY_INFO,
    propertyId,
  };
}

export function receiveCampaignLinkage(linkage) {
  return {
    type: RECEIVE_LOCATION_CAMPAIGN,
    linkage,
  };
}

export function receiveLocationCampaignLinkage(linkage, locationId) {
  return {
    type: RECEIVE_LOCATION_CAMPAIGNS_LINKAGE,
    linkage,
    locationId,
  };
}

function requestPropertySearch(channelName, channelId) {
  return {
    type: REQUEST_PROPERTY_SEARCH,
    channelName,
    channelId,
  };
}

export function receivePropertySearch(json) {
  // Note: In the case property search returns multiple properties,
  // always take first one to streamline user experience
  const property = head(json.properties);
  return {
    type: RECEIVE_PROPERTY_SEARCH,
    property,
  };
}

export function updateLocation(data, locationId, customerId) {
  return (dispatch) => {
    dispatch(requestLocationUpdate(locationId, customerId, data));
    return fetch(locationUrl(locationId), {
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveLocationUpdate(json, customerId)));
  };
}

export function updateLocationPlaceId({ placeId, locationId, customerId }) {
  return (dispatch) => {
    dispatch(requestLocationUpdate(locationId, customerId, { place_id: placeId }));
    return listingsFetch(locationUrlV3(locationId), {
      method: "PATCH",
      body: JSON.stringify({ place_id: placeId }),
    })
      .then((response) => response.json())
      .then(() => dispatch(receiveLocationUpdateV3({ locationId, customerId, placeId })));
  };
}

export function updateLocationListingId({ locationId, customerId, listingId }) {
  return (dispatch) => {
    dispatch(receiveLocationUpdateV3({ locationId, customerId, listingId }));
  };
}

export function createLocation(data) {
  return (dispatch) =>
    dispatch({
      type: CREATE_LOCATION,
      payload: fetch(`${getHost()}/locations`, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((location) => ({ location })),
    });
}

export function fetchSubscriptionsForLocation(id) {
  return (dispatch) => {
    dispatch({ type: REQUEST_LOCATION_SUBSCRIPTIONS, locationId: id });
    return fetch(`${locationUrl(id)}/subscriptions`)
      .then((response) => response.json())
      .then((json) => {
        json.forEach((subscription) => dispatch(receiveSubscription(subscription)));
        dispatch(
          receiveLocationSubscriptions(
            id,
            json.map((subscription) => subscription.id),
          ),
        );
      });
  };
}

export function linkCampaign(locationId, linkage) {
  return (dispatch) =>
    fetch(campaignsListUrl(locationId), {
      method: "POST",
      body: JSON.stringify(linkage),
    })
      .then((response) => response.json())
      .then((data) => dispatch(receiveCampaignLinkage(data)));
}

export function fetchLinkage(locationId) {
  return (dispatch) =>
    fetch(campaignsListUrl(locationId))
      .then((response) => response.json())
      .then((data) => {
        data.forEach((linkage) => {
          dispatch(receiveCampaignLinkage(linkage));
          dispatch(receiveLocationCampaignLinkage(linkage, locationId));
        });
        return data;
      });
}

export function updateLinkage(id, linkage) {
  return (dispatch) =>
    fetch(`${locationCampaignsUrl(id)}`, {
      method: "PATCH",
      body: JSON.stringify(linkage),
    })
      .then((response) => response.json())
      .then((data) => dispatch(receiveCampaignLinkage(data)));
}

export function fetchPropertyInfo(propertyId) {
  return (dispatch) => {
    dispatch(requestProperty(propertyId));
    return fetch(propertyInfoUrl(), {
      method: "POST",
      body: JSON.stringify({
        id: propertyId,
      }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveProperty(propertyId, json)))
      .catch(handleFetchError);
  };
}

export function activateProperty(propertyId) {
  return (dispatch) =>
    fetch(activationUrl(), {
      method: "POST",
      body: JSON.stringify({
        id: propertyId,
        enabled: true,
      }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(propertyActivated(propertyId, json)))
      .catch(handleFetchError);
}

export function healthCheckProperty(propertyId) {
  return (dispatch) => {
    dispatch(requestPropertyHealth(propertyId));
    return fetch(propertyCheckUrl(), {
      method: "POST",
      body: JSON.stringify({
        id: propertyId,
      }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receivePropertyHealth(propertyId, json)))
      .catch(handleFetchError);
  };
}

export function dispatchPropertySearch(channelName, channelId) {
  return (dispatch) => {
    dispatch(requestPropertySearch(channelName, channelId));
    return fetch(propertySearchUrl(), {
      method: "POST",
      body: JSON.stringify({
        channel_name: channelName,
        channel_id: channelId,
      }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receivePropertySearch(json)))
      .catch(handleFetchError);
  };
}

export function fetchOnboardingInsights(locationId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_LOCATION_ONBOARDING_INSIGHTS,
      payload: fetch(`${locationUrl(locationId)}/onboarding-insights`).then((res) => res.json()),
      meta: { locationId },
    });
}

export function triggerEvent(locationId, event) {
  return (dispatch) =>
    dispatch({
      type: LOCATION_EVENT_TRIGGER,
      payload: fetch(`${locationUrl(locationId)}/trigger/${event}`, { method: "POST" }),
      meta: { locationId, event },
    });
}
