import { captureMessage } from "@sentry/react";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { getUberallCountryCode } from "./countries";

export const STEP_DETAILS = "details";
export const STEP_CONTACT_DETAILS = "contact";
export const STEP_HOURS = "hours";
export const STEP_SUMMARY = "summary";
export const STEP_SPECIAL_HOURS = "special-hours";
export const STEP_DESC = "description";
export const STEP_HEALTHCARE = "health";
export const STEP_SOCIAL = "social";
export const STEP_AMENITIES = "amenities";
export const STEP_SERVICES = "services";
export const STEP_BRANDS = "brands";
export const STEP_ATTRIBUTES = "attributes";
export const STEP_PAYMENT_METHODS = "payment-methods";
export const STEP_IMAGES = "images";
export const STEP_LOCATION = "location";
export const STEP_CHANNELS = "channels";
export const STEP_SYNC = "sync";
export const STEP_CAT = "categories";
export const STEP_CONFIRM = "confirm";

export const INFO_CATEGORY_BASIC = "basic";
export const INFO_CATEGORY_ADDITIONAL = "additional";

export const SERVICE_AREA_RADIUS = 300000;

export const photoTypes = (t) => ({
  LOGO: {
    id: "LOGO",
    label: t("Logo"),
    pluralLabel: t("Logos"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_LOGO"),
    pickerOptions: {
      transformations: {
        crop: {
          aspectRatio: 1,
        },
      },
    },
  },
  SQUARED_LOGO: {
    id: "SQUARED_LOGO",
    label: t("Squared Logo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_SQUARED_LOGO"),
    pickerOptions: {
      transformations: {
        crop: {
          force: true,
          aspectRatio: 1,
        },
      },
    },
  },
  MAIN: {
    id: "MAIN",
    label: t("Main photo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_MAIN_PHOTO"),
    pickerOptions: {
      imageMin: [480, 480],
    },
  },
  LANDSCAPE: {
    id: "LANDSCAPE",
    label: t("Landscape photo"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_LANDSCAPE_PHOTO"),
    pickerOptions: {
      imageMin: [0, 480],
      transformations: {
        crop: {
          force: true,
          aspectRatio: 16 / 9,
        },
      },
    },
  },
  PHOTO: {
    id: "PHOTO",
    label: t("Photos"),
    tooltipMessage: t("#LISTINGS_IMAGES_TOOLTIP_OTHER_PHOTOS"),
    pickerOptions: {
      imageMin: [480, 480],
    },
  },
});

export const profileCompleteFields = [
  "name",
  "streetAndNumber",
  "phone",
  "zip",
  "city",
  "country",
  "email",
  "languages",
  "keywords",
  "descriptionShort",
  "descriptionLong",
  "photos",
  "openingHours",
  "categories",
  "website",
  "services",
  "attributes",
];

export const overviewInsights = [
  "QUERIES_DIRECT",
  /* 'QUERIES_INDIRECT', */ "VIEWS_MAPS",
  "VIEWS_SEARCH",
  "ACTIONS_PHONE",
  "ACTIONS_WEBSITE",
  "ACTIONS_DRIVING_DIRECTIONS",
];

export const formatGooglePlace = (place, options) => {
  if (isEmpty(place)) {
    return {};
  }
  const formatTime = (time) => `${time.slice(0, 2)}:${time.slice(2)}`;

  const getAddrComp = (components, type, prop, def = "") =>
    get(components?.filter((component) => component.types.includes(type))?.[0], prop, def);

  const addr = place?.address_components;
  const loc = place?.geometry?.location;
  const streetName = getAddrComp(addr, "route", "long_name");
  const streetNum = getAddrComp(addr, "street_number", "long_name");
  const addressExtra = `${getAddrComp(addr, "subpremise", "long_name")} ${getAddrComp(
    addr,
    "premise",
    "long_name",
  )}`.trim();
  const formattedAddress = place?.formatted_address;
  const numberFirst =
    streetNum &&
    streetName &&
    formattedAddress.indexOf(Number(streetNum)) < formattedAddress.indexOf(Number(streetName));
  let streetAndNum = "";

  if (streetName || streetNum) {
    streetAndNum = `${numberFirst ? streetNum : ""} ${streetName} ${
      numberFirst ? "" : streetNum
    }`.trim();
  }
  const periods = get(place, "opening_hours.periods");
  const hours =
    periods &&
    Array(7)
      .fill("")
      .map((entry, index) => {
        let dayGoogle = index;
        // Swapping Sunday for Monday as first day of the week (US format)
        if (dayGoogle === 6) {
          dayGoogle = 0;
        } else {
          dayGoogle = index + 1;
        }
        const opened = periods.filter((period) => period.open.day === dayGoogle);
        const timings = opened.reduce(
          (acc, period, i) => ({
            ...acc,
            [`from${i + 1}`]: formatTime(period.open.time),
            [`to${i + 1}`]: period.close ? formatTime(period.close.time) : "24:00",
          }),
          {},
        );

        return { dayOfWeek: index + 1, closed: isEmpty(timings), ...timings };
      });

  return {
    addressExtra,
    name: place?.name || "",
    city:
      getAddrComp(addr, "locality", "long_name", "") ||
      getAddrComp(addr, "postal_town", "long_name", "") ||
      getAddrComp(addr, "administrative_area_level_1", "long_name", ""),
    country: options?.useRegularCountryCode
      ? getAddrComp(addr, "country", "short_name", "NL")
      : getUberallCountryCode(getAddrComp(addr, "country", "short_name", "NL")),
    streetAndNumber: streetAndNum,
    zip: getAddrComp(addr, "postal_code", "long_name"),
    lat: loc?.lat || NaN,
    lng: loc?.lng || NaN,
    website: place?.website || "",
    phone: place?.international_phone_number || "",
    openingHours: periods && hours,
  };
};

export const getStepCategory = (stepKey, steps) =>
  steps.find(({ key }) => key === stepKey).category;

export const getStepNumberLists = (steps) => {
  const toReturn = { [INFO_CATEGORY_BASIC]: [], [INFO_CATEGORY_ADDITIONAL]: [] };
  steps.forEach((item) => {
    toReturn[item.category].push(item.key);
  });
  return toReturn;
};

export const getStepNumber = (stepKey, steps, divided = true) => {
  const stepCategory = getStepCategory(stepKey, steps);
  if (divided) {
    return getStepNumberLists(steps)[stepCategory].findIndex((key) => key === stepKey) + 1;
  }
  return steps.findIndex(({ key }) => key === stepKey) + 1;
};

export const getStepTitle = (stepKey, steps) => steps.find(({ key }) => key === stepKey).title;

export const getStepNumberedTitle = (stepKey, title, steps) =>
  `${getStepNumber(stepKey, steps)}. ${title}`;

export const getProgressWidth = (stepKey, steps) => {
  const stepCategory = getStepCategory(stepKey, steps);
  const stepNumber = getStepNumber(stepKey, steps);
  return (stepNumber / getStepNumberLists(steps)[stepCategory].length) * 100;
};

export const fieldNameMap = (t) => ({
  name: t("Location name"),
  street: t("Street"),
  streetNo: t("Street number"),
  zip: t("Postal code"),
  city: t("City"),
  country: t("Country"),
  addressDisplay: t("Address Display"),
  serviceAreas: t("Service areas"),
  categories: t("Primary category"),
  email: t("Email address"),
  phone: t("Phone"),
  cellphone: t("Mobile Phone"),
  fax: t("Fax number"),
  website: t("Website"),
  languages: t("Language"),
  addressExtra: t("Address line extra"),
  openingHours: t("Opening hours"),
  specialOpeningHours: t("Special opening hours"),
  keywords: t("Keywords"),
  descriptionShort: t("Short description"),
  descriptionLong: t("Long description"),
  lat: t("Latitude"),
  lng: t("Longitude"),
  socialProfiles: t("Social accounts"),
  services: t("Services"),
  brands: t("Brands"),
  attributes: t("Attributes"),
  paymentOptions: t("Payment options"),
  photos: t("Images"),
  doctorCategories: t("Doctor categories"),
  practiceName: t("Practice Name"),
  providerTitle: t("Title"),
  providerFirstname: t("First Name"),
  providerMiddlename: t("Middle Name"),
  providerSurname: t("Surname"),
  npi: t("NPI"),
  credentials: t("Credentials/Degrees"),
  university: t("University"),
  hospitalAffiliations: t("Hospital Affiliations"),
  insurancesAccepted: t("Insurances Accepted"),
});

export const fieldsStepMap = [
  {
    key: STEP_DETAILS,
    fields: [
      "name",
      "street",
      "streetNo",
      "zip",
      "city",
      "country",
      "categories",
      "addressExtra",
      "addressDisplay",
      "serviceAreas",
    ],
  },
  {
    key: STEP_CONTACT_DETAILS,
    fields: ["phone", "cellphone", "fax", "email", "website", "languages"],
  },
  {
    key: STEP_HOURS,
    fields: ["openingHours"],
  },
  {
    key: STEP_SPECIAL_HOURS,
    fields: ["specialOpeningHours"],
  },
  {
    key: STEP_HEALTHCARE,
    fields: [
      "npi",
      "doctorCategories",
      "practiceName",
      "providerTitle",
      "providerFirstname",
      "providerMiddlename",
      "providerSurname",
      "credentials",
      "university",
      "hospitalAffiliations",
      "insurancesAccepted",
    ],
  },
  {
    key: STEP_DESC,
    fields: ["keywords", "descriptionShort", "descriptionLong"],
  },
  {
    key: STEP_LOCATION,
    fields: ["lat", "lng"],
  },
  {
    key: STEP_SOCIAL,
    fields: ["socialProfiles"],
  },
  {
    key: STEP_AMENITIES,
    fields: ["services", "brands"],
  },
  {
    key: STEP_ATTRIBUTES,
    fields: ["attributes"],
  },
  {
    key: STEP_PAYMENT_METHODS,
    fields: ["paymentOptions"],
  },
  {
    key: STEP_IMAGES,
    fields: ["photos"],
  },
  {
    key: STEP_CAT,
    fields: ["categories"],
  },
];

export const findStepFromField = (field) => {
  return fieldsStepMap.find((stepObj) => stepObj.fields.includes(field))?.key;
};

function hasMissingDetails(location) {
  const requiredFields = [
    location.name,
    location.streetAndNumber,
    location.zip,
    location.city,
    location.country,
    location.phone,
    location.email,
    location.website,
    location.languages,
    location.categories,
  ];
  return requiredFields.filter((param) => isEmpty(param)).length > 0;
}

function hasMissingDescriptions(location) {
  const requiredFields = [location.keywords, location.descriptionShort, location.descriptionLong];
  return requiredFields.filter((param) => isEmpty(param)).length > 0;
}

export function getFinishLaterTrackingPayload(location) {
  return {
    // missing fields
    missing_company_details: hasMissingDetails(location),
    missing_opening_hours: isEmpty(location.openingHours),
    identifier: location.identifier,
    name: location.name,
    streetAndNumber: location.streetAndNumber,
    addressExtra: location.addressExtra,
    zip: location.zip,
    city: location.city,
    country: location.country,
    phone: location.phone,
    fax: location.fax,
    email: location.email,
    website: location.website,
    languages: location.languages,
    categories: location.categories, // first category is the main one
    // opening hours tab
    openingHours: location.openingHours,
  };
}

/**
 * Location result object details
 * @see https://uberall.com/en/developers/resources#Location
 */
export function getLocationUpdatedTrackingPayload(location) {
  return {
    // missing fields
    missing_company_details: hasMissingDetails(location),
    missing_opening_hours: isEmpty(location.openingHours),
    missing_description: hasMissingDescriptions(location),
    missing_photos: isEmpty(location.photos),
    missing_additional_categories: !location.categories || location.categories.length <= 1,
    // company details tab
    identifier: location.identifier,
    name: location.name,
    streetAndNumber: location.streetAndNumber,
    addressExtra: location.addressExtra,
    zip: location.zip,
    city: location.city,
    country: location.country,
    phone: location.phone,
    fax: location.fax,
    email: location.email,
    website: location.website,
    languages: location.languages,
    categories: location.categories, // first category is the main one
    // opening hours tab
    openingHours: location.openingHours,
    // special opening hours tab
    specialOpeningHours: location.specialOpeningHours,
    // company description tab
    keywords: location.keywords,
    descriptionShort: location.descriptionShort,
    descriptionLong: location.descriptionLong,
    // location coordinates tab
    lat: location.lat,
    lng: location.lng,
    // social accounts tab
    socialProfiles: location.socialProfiles,
    // services tab
    services: location.services,
    // payment options tab
    paymentOptions: location.paymentOptions,
    // images tab
    photos: location.photos,
    // profile completeness percentage
    profileCompleteness: location.profileCompleteness,
    // sync details
    lastSyncStarted: location.lastSyncStarted,
    autoSync: location.autoSync,
    // missing directory sync (facebook, google)
    directoriesMissingConnect: location.directoriesMissingConnect,
  };
}

const suggestionFieldTypes = (t) => [
  {
    id: "tag",
    fields: ["email", "website", "descriptionShort", "descriptionLong", "phone"],
    label: t("Tags"),
  },
  { id: "photos", fields: ["photos"], label: t("Images") },
  { id: "hours", fields: ["openingHours"], label: t("Opening hours") },
  { id: "attributes", fields: ["attributes", "services"], label: t("Attributes") },
];

export function groupSuggestionFields(suggestionFields, t) {
  const types = suggestionFieldTypes(t);
  const toReturn = suggestionFields.reduce((acc, current) => {
    const { id, label } = types.find(({ fields }) => fields.includes(current?.fieldName)) ?? {};
    if (id) {
      if (!acc[id]) {
        acc[id] = { fields: [], label };
      }
      acc[id].fields = [...acc[id].fields, current];
    } else {
      captureMessage(`Found fieldName that is not mapped out: ${current}`);
    }
    return acc;
  }, {});
  return toReturn;
}

export function filterUnknowPhotoTypes(suggestionsToFilter = [], t) {
  // Filters out type of photos that we currently don't support.
  // suggestionsToFilter
  const returnSuggestions = [];
  suggestionsToFilter?.forEach((suggestion) => {
    if (suggestion?.fieldName === "photos") {
      const photoOptions = photoTypes(t);
      const { suggestions: photoSuggestions, ...restSuggestion } = suggestion;
      const resultPhotoSuggestions = [];

      // loop over all the suggestions
      photoSuggestions.forEach((photoSuggestion) => {
        // filter out all the unsupported value suggestions
        const suggestionValue = photoSuggestion.value.filter(({ type }) => !!photoOptions[type]);

        // if there are value suggestions that are supported add them to the result
        if (suggestionValue.length) {
          const newPhotoSuggestion = { ...photoSuggestion };
          newPhotoSuggestion.value = suggestionValue;
          resultPhotoSuggestions.push(newPhotoSuggestion);
        }
      });

      if (resultPhotoSuggestions.length) {
        returnSuggestions.push({ ...restSuggestion, suggestions: resultPhotoSuggestions });
      }

      // if fieldName isn't photos
    } else {
      returnSuggestions.push(suggestion);
    }
  });
  return returnSuggestions;
}

export function countSuggestions(suggestions, t) {
  if (suggestions === null) {
    return 0;
  }
  let count = 0;
  filterUnknowPhotoTypes(suggestions, t).forEach(
    ({ suggestions: dirSuggestions = [], fieldName }) => {
      if (suggestionFieldTypes(t).some(({ fields }) => fields.includes(fieldName))) {
        dirSuggestions.forEach(({ value }) => {
          count += Array.isArray(value) ? value?.length : 1;
        });
      }
    },
  );

  return count;
}

export function getAllSuggestionIds(suggestionsForFields) {
  const suggestionIds = [];
  suggestionsForFields.forEach(({ suggestions }) => {
    suggestions.forEach(({ id }) => {
      suggestionIds.push(Number(id));
    });
  });
  return suggestionIds;
}

const SUGGESTION_STATUS_ACCEPTED = "ACCEPTED";
const SUGGESTION_STATUS_DECLINED = "DECLINED";
const SUGGESTION_STATUS_ACTIVE = "ACTIVE";

export const suggestionStatuses = {
  accepted: SUGGESTION_STATUS_ACCEPTED,
  declined: SUGGESTION_STATUS_DECLINED,
  active: SUGGESTION_STATUS_ACTIVE,
};

export const npiValidation = (value) => {
  // validate length is 10 and number type
  if (!/^\d{10}$/.test(value)) {
    return false;
  }
  const digits = value.split("");

  let checkBit = digits.pop();
  checkBit = Number(checkBit);
  let npiDouble = [];

  digits.forEach((elm, i) => {
    if (i % 2 === 0) {
      npiDouble[i] = Number(elm) * 2;
    }
    if (i % 2 === 1) {
      npiDouble[i] = Number(elm);
    }
  });

  npiDouble = npiDouble.join("").split("");

  let totalSum = npiDouble.reduce((sum, num) => {
    // eslint-disable-next-line no-param-reassign
    sum += Number(num);
    return sum;
  }, 0);

  totalSum += 24; // Magic Bit
  const final = Math.ceil(totalSum / 10) * 10;
  return final - totalSum === checkBit;
};

export const waitPassAllvalidation = ({ formState, currentStep, setFormState }) => {
  const { attr } = formState;

  return new Promise((resolve, reject) => {
    if (currentStep === STEP_HEALTHCARE) {
      if (attr.doctorComData) {
        let allEmpty = true;
        Object.keys(attr.doctorComData).forEach((attrKey) => {
          // don't check isProvider since we now always set this to true
          if (attrKey !== "isProvider") {
            // The validation allows the field value to be false.
            // Because it is possible the field is not visible on UI, but the value is false.
            // Healthcare isProvider is in this case.
            if (attr.doctorComData[attrKey] !== null && attr.doctorComData[attrKey] !== undefined) {
              allEmpty = false;
            }
          }
        });

        if (allEmpty) {
          // if all fields empty, go to next step
          const newAttr = cloneDeep(attr);
          delete newAttr.doctorComData;

          setFormState(
            {
              attr: newAttr,
              errors: {},
            },
            () => {
              resolve();
            },
          );
        } else {
          // some fields not empty, will need validation
          reject();
        }
      } else {
        // for first time in medical fields section
        resolve();
      }
    } else {
      // just do validation
      reject();
    }
  });
};

export const locationStatusses = (t) => ({
  ACTIVE: t("Active"),
  INACTIVE: t("Inactive"),
  CANCELLED: t("Cancelled"),
  CLOSED: t("Closed"),
});
