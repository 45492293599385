import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

const CardFooter = (props) => {
  const { children, className } = props;
  const classes = cx("card-footer", className);
  return <div className={classes}>{children}</div>;
};

CardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardFooter.defaultProps = {
  children: "",
  className: "",
};

export default CardFooter;
