import { z } from "zod";

import { phoneRegex } from "utils/fieldValidation";
import { productNameV3 } from "utils/products";

import paginationSchema from "./utilSchemas";

export const accountCompanyInfoSchema = z.object({
  name: z.string({ required_error: "Company name is required" }),
  email: z.string().email().nullable().optional().or(z.literal("")),
  website: z.string().url().nullable().optional().or(z.literal("")),
  phone: z.string().nullable().optional(),
  address: z
    .object({
      address_line: z.string().nullable().optional(),
      address_line_extra: z.string().nullable().optional(),
      postal_code: z.string().nullable().optional(),
      city: z.string().nullable().optional(),
      country: z.string().nullable().optional(),
    })
    .optional(),
  place_id: z.string().nullable().optional(),
  external_reference: z.string().nullable().optional(),
});

export const accountLocalizationSchema = z.object({
  localization: z.object({
    locale: z.string({
      required_error: "Locale is required",
      invalid_type_error: "Locale is required",
    }),
    date_format: z.string().nullable(),
    timezone: z.string().nullable().optional(),
  }),
});

export const accountAdvancedSchema = z.object({
  reseller_id: z
    .number({
      required_error: "Partner is required",
      invalid_type_error: "Partner is required",
    })
    .min(1, "Partner is required"),
  advanced_settings: z.object({
    is_high_profile: z.boolean(),
    is_demo: z.boolean(),
  }),
  listings_settings: z.object({
    plan_id: z
      .number({
        required_error: "Please select a Listings plan",
        invalid_type_error: "Please select a Listings plan",
      })
      .min(1, "Please select a Listings plan"),
    business_id: z.number(),
  }),
});

export const updateAccountSchema = z.union([
  accountCompanyInfoSchema,
  accountLocalizationSchema,
  accountAdvancedSchema,
]);

export const accountAddressSchema = z
  .object({
    address_line: z.string().nullable().optional(),
    address_line_extra: z.string().nullable().optional(),
    postal_code: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
  })
  .optional();

export const accountSchema = z.object({
  id: z.number(),
  name: z.string(),
  phone: z.string().nullable().optional(),
  website: z.string().nullable().optional(),
  email: z.string().email().nullable().optional().or(z.literal("")),
  external_reference: z.string().nullable().optional(),
  place_id: z.string().nullable().optional(),
  address: accountAddressSchema,
  listings_settings: z.object({ plan_id: z.number(), business_id: z.number() }),
  localization: z.object({
    locale: z.string().nullable(),
    date_format: z.string().nullable(),
    timezone: z.string().nullable().optional(),
  }),
  advanced_settings: z.object({
    is_high_profile: z.boolean(),
    is_demo: z.boolean(),
  }),
  permissions: z.array(z.string()),
  reseller: z.object({ id: z.number(), name: z.string() }),
  primary_user: z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string().nullable().optional(),
    email: z.string(),
  }),
});

export const accountsSchema = z.object({
  data: z.array(
    z.object({
      country: z.string().nullable(),
      created_at: z.string(),
      external_reference: z.string().nullable(),
      id: z.number(),
      locations_count: z.number(),
      name: z.string(),
      phone: z.string().nullable(),
      primary_user: z
        .object({
          id: z.number(),
          first_name: z.string(),
          last_name: z.string().nullable(),
          email: z.string(),
        })
        .nullable(),
      reseller: z.object({ id: z.number(), name: z.string() }),
    }),
  ),
  meta: paginationSchema,
});

export const createAccountSchema = z.object({
  address: z.object({
    address_line: z.string().nullable(),
    address_line_extra: z.string().nullable(),
    city: z.string().nullable(),
    country: z.string().nullish(),
    postal_code: z.string().nullable(),
  }),
  name: z.string().min(1),
  external_reference: z.string().nullable().optional(),
  phone: z.string().regex(phoneRegex).nullable(),
  place_id: z.string().nullable(),
  primary_user: z.object({
    first_name: z.string().min(1),
    last_name: z.string().nullable(),
    email: z.string().email(),
    language: z.string().nullish(),
  }),
  reseller_id: z.number().nullish(),
  website: z
    .string()
    .nullable()
    .refine((website) => {
      if (website) {
        return z.string().url().parse(website);
      }
      return true;
    }),
});

export const createAccountFormSchema = createAccountSchema
  .extend({
    reseller_required: z.boolean().nullable().optional(),
    external_reference_required: z.boolean().nullable().optional(),
  })
  .refine(
    ({ reseller_required, reseller_id }) => {
      if (reseller_required) {
        return Boolean(String(reseller_id));
      }
      return true;
    },
    {
      message: "Partner is required",
      path: ["reseller_id"],
    },
  )
  .refine(
    ({ external_reference_required, external_reference }) => {
      if (external_reference_required) {
        return Boolean(String(external_reference));
      }
      return true;
    },
    {
      message: "External reference is required",
      path: ["external_reference"],
    },
  );

export const accountCheckSchema = z.object({
  place_id: z.object({
    locations: z.array(
      accountSchema
        .pick({
          address: true,
          id: true,
          name: true,
          place_id: true,
        })
        .extend({
          account: accountSchema
            .pick({
              address: true,
              external_reference: true,
              id: true,
              name: true,
              place_id: true,
            })
            .extend({ created_at: z.string().nullable() })
            .optional(),
        }),
    ),
    accounts: z.array(
      accountSchema.pick({
        address: true,
        id: true,
        name: true,
        place_id: true,
        external_reference: true,
        created_at: true,
      }),
    ),
  }),
  name: z.object({
    accounts: z.array(
      accountSchema.pick({
        address: true,
        id: true,
        name: true,
        place_id: true,
        external_reference: true,
        created_at: true,
      }),
    ),
  }),
  total: z.number(),
  place_id_details: z.union([
    z.object({
      name: z.string(),
      address_line: z.string(),
      address_line_extra: z.null(),
      postal_code: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string(),
      phone: z.string().nullable(),
      website: z.string().nullable(),
    }),
    z.array(z.any()),
    z.null(),
  ]),
});

const FileTypeEnum = z.enum(["contract", "invoice", "other"], {
  errorMap: () => ({ message: "Please select a type" }),
});

export type FileType = z.infer<typeof FileTypeEnum>;

export const accountFileSchema = z.object({
  created_at: z.string().nullable(),
  description: z.string(),
  extension: z.string().nullable(),
  id: z.number().transform((id) => String(id)),
  name: z.string().nullable(),
  size: z.number().nullable(),
  type: FileTypeEnum,
});

export const updateAccountFileSchema = z.object({
  file: z.instanceof(File, { message: "Please select a file" }),
  description: z.string().optional(),
  type: FileTypeEnum,
});

export const accountFilesSchema = z.object({
  data: z.array(accountFileSchema),
  meta: paginationSchema,
});

export type LocationProducts = Omit<typeof productNameV3, "WEBSITEOPTIMIZER">;
const locationProducts = () => {
  const products = { ...productNameV3 } as Record<string, string>;
  delete products.WEBSITEOPTIMIZER;
  return products as LocationProducts;
};
export const accountResourcesSchema = z.object({
  data: z.array(
    z.object({
      id: z.number().transform((id) => String(id)),
      type: z.literal("location"),
      description: z.string(),
      active_subscriptions: z.array(
        z.object({
          id: z.number(),
          product: z.nativeEnum(locationProducts()),
          end_date: z.coerce.date().nullable(),
          start_date: z.coerce.date().nullable(),
          canceled_at: z.coerce.date().nullable(),
          product_details: z
            .object({
              integration_id: z.string(),
              onboarded: z.boolean(),
            })
            .nullable(),
        }),
      ),
    }),
  ),
  meta: paginationSchema,
});

export default accountSchema;
