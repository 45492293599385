import { captureMessage } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { getV3Host } from "actions/fetch";

const fetchCSRFCookie = async () => {
  await fetch(`${getV3Host()}/csrf-cookie`);
};

export const useCSRFCookie = (csrfTokenExist: boolean) => {
  return useQuery({
    queryKey: ["csrfCookie", csrfTokenExist],
    queryFn: fetchCSRFCookie,
    enabled: !csrfTokenExist,
    retry: 3,
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error("Fetch CSRF cookie error:", error);
      captureMessage("Fetch CSRF cookie error", { extra: { errorReason: error } });
    },
  });
};

export default useCSRFCookie;
