import store from "store";

import { SET_ITEM, REMOVE_ITEM, READ_FROM_STORE } from "../actions";

const getInitialState = () => {
  const initState = {};
  store.each((value, key) => {
    initState[key] = value;
  });
  return initState;
};

export default function storage(state = {}, action) {
  let newState;
  switch (action.type) {
    case READ_FROM_STORE:
      return getInitialState();
    case SET_ITEM:
      store.set(action.key, action.item);
      return { ...state, [action.key]: action.item };
    case REMOVE_ITEM:
      store.remove(action.key);
      newState = { ...state };
      delete newState[action.key];
      return newState;
    default:
      return state;
  }
}
