import React, { useEffect, useRef, useState } from "react";

import ChevronDown from "assets/icons/teya/ChevronDown";
import ChevronUp from "assets/icons/teya/ChevronUp";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useInfiniteAccountListingsResources } from "hooks/queries/useAccountQueries";
import { useBreakpointDown } from "hooks/useWindowDimensions";

import {
  ComposedModal,
  Loading,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverContent,
} from "@carbon/react";

import ModalPortal from "../commons/ModalPortal";
import StoreItem from "./StoreItem";

type StoreSelectorProps = {
  selectedLocationId: number | null;
  selectedLocationName?: string;
  accountId: number;
};

const StoreSelector = ({
  selectedLocationId,
  selectedLocationName,
  accountId,
}: StoreSelectorProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointDown("md");
  const { ref, inView } = useInView();
  const listingsInfiniteData = useInfiniteAccountListingsResources(accountId, {
    per_page: "50",
    sort: "requires_attention",
  });
  const { fetchNextPage, hasNextPage } = listingsInfiniteData;

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const handleClickOutside = (event: MouseEvent) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectorRef]);

  useEffect(() => {
    if (open && isMobile) {
      // when modal visible, hide body scrollbar
      document.body.style.overflow = "hidden";
    } else {
      // when modal visible, show body scrollbar
      document.body.style.overflow = "unset";
    }
  }, [open, isMobile]);

  return (
    <>
      {isMobile ? (
        <div
          className="tw-mx-4 tw-flex"
          onClick={() => {
            setOpen((val) => {
              return !val;
            });
          }}
        >
          <span
            className="tw-line-clamp-1 tw-body-semibold hover:tw-underline"
            data-testid="mobile-store-selector-title"
          >
            {selectedLocationName}
          </span>
          {selectedLocationName && listingsInfiniteData.data && (
            <>
              <ChevronUp className={cx({ "tw-block": open, "tw-hidden": !open })} />
              <ChevronDown className={cx({ "tw-block": !open, "tw-hidden": open })} />
            </>
          )}
          <ModalPortal>
            <ComposedModal
              open={open}
              preventCloseOnClickOutside
              // @ts-ignore onClose typing incorrect
              onClose={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                setOpen(false);
              }}
              className="store-selector-modal"
              data-testid="store-selector-modal"
            >
              <ModalHeader
                className="tw-m-0 tw-border-b tw-border-solid tw-border-primary-charcoal-10 tw-bg-primary-off-white tw-px-4 tw-py-3"
                title={t("Store")}
              />
              <ModalBody className="tw-p-0">
                <div className="tw-mb-4 tw-flex tw-flex-col tw-justify-between">
                  {listingsInfiniteData.data &&
                    listingsInfiniteData.data.pages.map((group) => {
                      return group.result.data.map((store) => {
                        const listingsSubscription = store.active_subscriptions.find(
                          (s) => s.product === "listings",
                        );
                        const isOnboard = listingsSubscription?.product_details?.onboarded;
                        const isSelected = Number(store.id) === selectedLocationId;

                        if (listingsSubscription) {
                          return (
                            <StoreItem
                              storeId={store.id}
                              isSelected={isSelected}
                              description={store.description}
                              isOnboard={isOnboard}
                              isMobile={isMobile}
                            />
                          );
                        }

                        return false;
                      });
                    })}
                </div>
                <div
                  className={cx("tw-flex tw-justify-center", { "tw-p-3": hasNextPage })}
                  ref={ref}
                >
                  {hasNextPage ? <Loading withOverlay={false} className="tw-h-6 tw-w-6" /> : ""}
                </div>
              </ModalBody>
            </ComposedModal>
          </ModalPortal>
        </div>
      ) : (
        <>
          {selectedLocationId && (
            <Popover open={open} className="tw-mx-4">
              <div
                className="tw-flex tw-cursor-pointer tw-select-none tw-items-center "
                ref={selectorRef}
                onClick={() => {
                  setOpen((val) => !val);
                }}
              >
                <div
                  className="tw-mr-2 tw-line-clamp-1 tw-body-semibold hover:tw-underline"
                  data-testid="store-selector-title"
                >
                  {selectedLocationName}
                </div>
                {selectedLocationName && listingsInfiniteData.data && (
                  <>
                    <ChevronUp className={cx({ "tw-block": open, "tw-hidden": !open })} />
                    <ChevronDown className={cx({ "tw-block": !open, "tw-hidden": open })} />
                  </>
                )}
              </div>
              <PopoverContent className="tw-h-60 tw-overflow-y-auto  tw-shadow-md">
                {listingsInfiniteData.data &&
                  listingsInfiniteData.data.pages.map((group) => {
                    return group.result.data.map((store) => {
                      const listingsSubscription = store.active_subscriptions.find(
                        (s) => s.product === "listings",
                      );
                      const isOnboard = listingsSubscription?.product_details?.onboarded;
                      const isSelected = Number(store.id) === selectedLocationId;

                      if (listingsSubscription) {
                        return (
                          <StoreItem
                            storeId={store.id}
                            isSelected={isSelected}
                            description={store.description}
                            isOnboard={isOnboard}
                          />
                        );
                      }

                      return false;
                    });
                  })}
                <div
                  className={cx("tw-flex tw-justify-center", { "tw-p-3": hasNextPage })}
                  ref={ref}
                >
                  {hasNextPage ? <Loading withOverlay={false} className="tw-h-6 tw-w-6" /> : ""}
                </div>
              </PopoverContent>
            </Popover>
          )}
        </>
      )}
    </>
  );
};

export default StoreSelector;
