import React, { SVGProps } from "react";

import cx from "classnames";

const User = (props?: SVGProps<SVGSVGElement>) => {
  const { className, ...otherProps } = props ?? {};
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("tw-fill-primary-charcoal-300", className)}
      {...otherProps}
    >
      <path d="M13.0917 10.5917C13.9087 9.94894 14.505 9.06749 14.7977 8.07C15.0904 7.07252 15.0649 6.00861 14.7248 5.02628C14.3847 4.04394 13.7469 3.19205 12.9001 2.5891C12.0532 1.98615 11.0395 1.66214 10 1.66214C8.96046 1.66214 7.94676 1.98615 7.09994 2.5891C6.25312 3.19205 5.61529 4.04394 5.27518 5.02628C4.93507 6.00861 4.90959 7.07252 5.2023 8.07C5.49501 9.06749 6.09134 9.94894 6.90833 10.5917C5.5084 11.1526 4.28691 12.0828 3.37408 13.2833C2.46125 14.4838 1.8913 15.9095 1.725 17.4084C1.71296 17.5178 1.7226 17.6286 1.75335 17.7343C1.78411 17.84 1.83539 17.9386 1.90426 18.0245C2.04335 18.198 2.24565 18.3091 2.46667 18.3334C2.68768 18.3577 2.9093 18.2932 3.08277 18.1541C3.25624 18.015 3.36736 17.8127 3.39167 17.5917C3.57465 15.9627 4.35141 14.4582 5.57352 13.3657C6.79563 12.2732 8.37742 11.6692 10.0167 11.6692C11.6559 11.6692 13.2377 12.2732 14.4598 13.3657C15.6819 14.4582 16.4587 15.9627 16.6417 17.5917C16.6643 17.7965 16.762 17.9856 16.9159 18.1226C17.0698 18.2595 17.269 18.3346 17.475 18.3334H17.5667C17.7851 18.3082 17.9848 18.1978 18.1221 18.0261C18.2595 17.8544 18.3234 17.6354 18.3 17.4167C18.1329 15.9136 17.5599 14.4842 16.6424 13.2819C15.7249 12.0796 14.4974 11.1496 13.0917 10.5917ZM10 10.0001C9.34073 10.0001 8.69626 9.80456 8.1481 9.43828C7.59994 9.07201 7.17269 8.55142 6.9204 7.94233C6.66811 7.33324 6.6021 6.66302 6.73072 6.01642C6.85933 5.36982 7.1768 4.77587 7.64298 4.3097C8.10915 3.84352 8.7031 3.52605 9.3497 3.39744C9.9963 3.26882 10.6665 3.33483 11.2756 3.58712C11.8847 3.83941 12.4053 4.26665 12.7716 4.81482C13.1378 5.36298 13.3333 6.00745 13.3333 6.66672C13.3333 7.55077 12.9821 8.39862 12.357 9.02374C11.7319 9.64886 10.8841 10.0001 10 10.0001Z" />
    </svg>
  );
};

export default User;
