import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

const Card = (props) => {
  const { children, className, shadow } = props;
  const classes = cx("card", { "card-shadow": shadow }, className);
  return <div className={classes}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  shadow: PropTypes.bool,
};

Card.defaultProps = {
  children: "",
  className: "",
  shadow: false,
};

export default Card;
