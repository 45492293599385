/* eslint-disable no-underscore-dangle */
/**
 * logtracker
 * Sends events to the console logger
 * Can be turned on/off by setting the TRACKING_DEBUG value in .env
 * @type {{track: function(), identify: function()}}
 */

const logTracker = {
  track: (event, payload) => {
    console.log({ event, payload });
  },
  identify: (payload) => {
    const { userId: id, ...data } = payload;
    console.log({ id, ...data });
  },
  pageView: (url, payload) => {
    console.log({ url, payload });
  },
};
export default logTracker;
