const langCodeMapping = {
  af: "Afrikaanca",
  am: "Amharca",
  an: "Aragonca",
  ar: "Arap\u00e7a",
  as: "Assamca",
  az: "Azerbaycan dili",
  be: "Belarus\u00e7a",
  bg: "Bulgarca",
  bn: "Bengalce",
  br: "Bretonca",
  bs: "Bo\u015fnak\u00e7a",
  ca: "Katalanca",
  cs: "\u00c7ek\u00e7e",
  cy: "Galce",
  da: "Danca",
  de: "Almanca",
  dz: "Dzongkha",
  el: "Yunanca",
  en: "\u0130ngilizce",
  eo: "Esperanto",
  es: "\u0130spanyolca",
  et: "Estonca",
  eu: "Bask\u00e7a",
  fa: "Fars\u00e7a",
  fi: "Fince",
  fo: "Faroe dili",
  fr: "Frans\u0131zca",
  ga: "\u0130rlandaca",
  gl: "Gali\u00e7yaca",
  gu: "G\u00fccerat\u00e7a",
  he: "\u0130branice",
  hi: "Hint\u00e7e",
  hr: "H\u0131rvat\u00e7a",
  ht: "Haiti Kreyolu",
  hu: "Macarca",
  hy: "Ermenice",
  id: "Endonezce",
  is: "\u0130zlandaca",
  it: "\u0130talyanca",
  ja: "Japonca",
  jv: "Cava dili",
  ka: "G\u00fcrc\u00fcce",
  kk: "Kazak\u00e7a",
  km: "Khmer dili",
  kn: "Kannada dili",
  ko: "Korece",
  ku: "K\u00fcrt\u00e7e",
  ky: "K\u0131rg\u0131zca",
  la: "Latince",
  lb: "L\u00fcksemburgca",
  lo: "Lao dili",
  lt: "Litvanca",
  lv: "Letonca",
  mg: "Malga\u015f\u00e7a",
  mk: "Makedonca",
  ml: "Malayalam dili",
  mn: "Mo\u011folca",
  mr: "Marathi dili",
  ms: "Malayca",
  mt: "Maltaca",
  nb: "Norve\u00e7\u00e7e Bokm\u00e5l",
  ne: "Nepalce",
  nl: "Felemenk\u00e7e",
  nn: "Norve\u00e7\u00e7e Nynorsk",
  no: "Norve\u00e7\u00e7e",
  oc: "Oksitan dili",
  or: "Oriya Dili",
  pa: "Pencap\u00e7a",
  pl: "Leh\u00e7e",
  ps: "Pe\u015ftuca",
  pt: "Portekizce",
  qu: "Ke\u00e7uva dili",
  ro: "Rumence",
  ru: "Rus\u00e7a",
  rw: "Kinyarwanda",
  se: "Kuzey Laponcas\u0131",
  si: "Sinhali dili",
  sk: "Slovak\u00e7a",
  sl: "Slovence",
  sq: "Arnavut\u00e7a",
  sr: "S\u0131rp\u00e7a",
  sv: "\u0130sve\u00e7\u00e7e",
  sw: "Svahili dili",
  ta: "Tamilce",
  te: "Telugu dili",
  th: "Tayca",
  tl: "Tagalogca",
  tr: "T\u00fcrk\u00e7e",
  ug: "Uygurca",
  uk: "Ukraynaca",
  ur: "Urduca",
  vi: "Vietnamca",
  vo: "Volap\u00fck",
  wa: "Valonca",
  xh: "Zosa dili",
  zh: "\u00c7ince",
  zu: "Zuluca",
};

export default langCodeMapping;
