import {
  useQuery,
  useQueryClient,
  useMutation,
  QueryFunctionContext,
  useInfiniteQuery,
  QueryClient,
} from "@tanstack/react-query";
import { reactQueryFetch as fetch, getHost, getV3Host } from "actions/fetch";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { stringify } from "query-string";
import accountSchema, {
  accountCheckSchema,
  accountResourcesSchema,
  createAccountSchema,
  updateAccountSchema,
} from "schemas/accountSchemas";
import { z } from "zod";

import { TError } from "utils/errors";
import { validId, requestAndValidate, PaginationType } from "utils/general";
import { productNameV3 } from "utils/products";
import { stringifyFilterParams } from "utils/string";

type AllAccountsParams = {
  "filter[partner]": number;
  "filter[query]": string;
  sort: string;
} & PaginationType;

type AccountResourcesParams = {
  "filter[products]": string;
  "filter[query]": string;
  sort: string;
} & PaginationType;

export type AccountFilesParams = {
  "filter[query]": string;
} & PaginationType;

type AccountResourceParams = Omit<AccountResourcesParams, "filter[products]">;
type AccountCheckParams = { name?: string; placeId?: string };
const accountKeys = {
  account: (accountId: number | null) => ["account", { accountId }] as const,
  allAccounts: (params: Partial<AllAccountsParams>) => ["allAccounts", { params }] as const,
  accountFiles: (accountId: number, params: Partial<AccountFilesParams>) =>
    ["accountsFiles", { accountId, params }] as const,
  accountCheck: (params: AccountCheckParams) => ["accountCheck", { params }] as const,
  accountResources: (accountId: number, params: Partial<AccountResourcesParams>) =>
    ["accountResources", { accountId, params }] as const,
  accountInfiniteListingsResources: (accountId: number, params: Partial<AccountResourcesParams>) =>
    ["accountInfiniteListingsResources", { accountId, params }] as const,
  accountLocationResources: (accountId: number, params: Partial<AccountResourceParams>) =>
    [
      ...accountKeys.accountResources(accountId, {
        "filter[products]": `${productNameV3.LISTINGS},${productNameV3.REVIEWS},${productNameV3.WIFI}`,
        ...params,
      }),
    ] as const,
  accountWebsiteResources: (accountId: number, params: Partial<AccountResourceParams>) =>
    [
      ...accountKeys.accountResources(accountId, {
        ...params,
        ...{
          "filter[products]": `${productNameV3.WEBSITEOPTIMIZER}`,
        },
      }),
    ] as const,

  // legacy api
  legacyCustomerAccounts: (customerId: number, params: Record<string, string | number>) =>
    ["customerAccounts", { customerId, params }] as const,
} as const;

type QueryReturnType<K extends keyof typeof accountKeys> = QueryFunctionContext<
  ReturnType<(typeof accountKeys)[K]>
>;

async function fetchAccount({ queryKey }: QueryReturnType<"account">) {
  const [, { accountId }] = queryKey;
  return requestAndValidate({
    requestUrl: `${getHost()}/accounts/${accountId}`,
    schema: accountSchema,
    invalidMessage: "Account data not match with spec",
  });
}

async function fetchAllAccounts({ queryKey }: QueryReturnType<"allAccounts">) {
  const [, { params }] = queryKey;
  const data = await fetch(`${getHost()}/accounts?${stringify(params)}`);
  return data.json();
}

async function fetchAccountResources({ queryKey }: QueryReturnType<"accountResources">) {
  const [, { accountId, params }] = queryKey;
  const defaultParams = {
    page: "1",
    per_page: "10",
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  return requestAndValidate({
    invalidMessage: "Account resources data not match with spec",
    requestUrl: `${getV3Host()}/customers/${accountId}/resources?${urlParams}`,
    schema: accountResourcesSchema,
  });
}

async function fetchInfiniteAccountResources({
  pageParam = 1,
  queryKey,
}: QueryReturnType<"accountInfiniteListingsResources">) {
  const [, { accountId, params }] = queryKey;
  const defaultParams = {
    page: pageParam,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  const result = await requestAndValidate({
    invalidMessage: "Account resources data not match with spec",
    requestUrl: `${getV3Host()}/customers/${accountId}/resources?${urlParams}`,
    schema: accountResourcesSchema,
  });
  let nextPageId = null;
  if (result.data.length !== 0) {
    nextPageId = pageParam + 1;
  }

  return {
    result,
    nextPageId,
  };
}

async function legacyFetchCustomerAccounts({
  queryKey,
}: QueryReturnType<"legacyCustomerAccounts">) {
  const [, { customerId, params = { name: "" } }] = queryKey;
  const urlParams = stringifyFilterParams(params);
  const data = await fetch(`${getHost()}/customers/${customerId}/accounts?${urlParams}`);
  return data.json();
}

async function fetchAccountFiles({ queryKey }: QueryReturnType<"accountFiles">) {
  const [, { accountId, params }] = queryKey;
  const defaultParams = {
    page: "1",
    per_page: "10",
    sort: "-created_at",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  const data = await fetch(`${getHost()}/accounts/${accountId}/files?${urlParams}`);
  return data.json();
}

export const useAccount = (accountId: number | null) =>
  useQuery({
    queryKey: accountKeys.account(accountId),
    queryFn: fetchAccount,
    enabled: validId(accountId),
    staleTime: 30 * 1000,
  });

export const useAllAccounts = (params: Partial<AllAccountsParams>, enabled = true) => {
  const defaultParams = {
    page: "1",
    per_page: "10",
    sort: "",
    "filter[query]": "",
  };

  const urlParams = omitBy({ ...defaultParams, ...params }, (val) => val === "");

  return useQuery({
    queryKey: accountKeys.allAccounts(urlParams),
    queryFn: fetchAllAccounts,
    enabled,
  });
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    TError | undefined,
    {
      accountId: number;
      data: z.infer<typeof updateAccountSchema>;
    }
  >({
    mutationFn: ({ accountId, data }) => {
      return fetch(`${getHost()}/accounts/${accountId}`, {
        method: "PATCH",
        body: JSON.stringify(data, (key, value) => (value === "" ? null : value)),
      });
    },
    onSuccess: async (_, { accountId }) => {
      await queryClient.invalidateQueries({ queryKey: ["allAccounts"] });
      queryClient.invalidateQueries(accountKeys.account(accountId));
    },
  });
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accountId: number) =>
      fetch(`${getHost()}/accounts/${accountId}`, { method: "DELETE" }),
    onSuccess: async (_, accountId) => {
      await queryClient.invalidateQueries({ queryKey: ["allAccounts"] });
      queryClient.invalidateQueries(accountKeys.account(accountId));
    },
  });
};

export const useAccountResources = (accountId: number, params: Partial<AccountResourcesParams>) =>
  useQuery({
    queryKey: accountKeys.accountResources(accountId, params),
    queryFn: fetchAccountResources,
    enabled: !isNil(accountId),
  });

export const useAccountLocationResources = (
  accountId: number,
  params: Partial<AccountResourceParams>,
) =>
  useQuery({
    queryKey: accountKeys.accountLocationResources(accountId, params),
    queryFn: fetchAccountResources,
    enabled: !isNil(accountId),
  });

export const prefetchAccountLocationResources = async (
  accountId: number,
  queryClient: QueryClient,
  params: Partial<AccountResourceParams>,
) => {
  await queryClient.prefetchQuery({
    queryKey: accountKeys.accountLocationResources(accountId, params),
    queryFn: fetchAccountResources,
  });
};

export const useInfiniteAccountListingsResources = (
  accountId: number,
  params: Partial<AccountResourceParams>,
) =>
  useInfiniteQuery({
    queryKey: accountKeys.accountInfiniteListingsResources(accountId, params),
    queryFn: fetchInfiniteAccountResources,
    enabled: !isNil(accountId),
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageId ?? undefined;
    },
  });

export const useAccountWebsiteResources = (
  accountId: number,
  hasWebsiteProduct: boolean,
  params: Partial<AccountResourceParams>,
) =>
  useQuery({
    queryKey: accountKeys.accountWebsiteResources(accountId, params),
    queryFn: fetchAccountResources,
    enabled: !isNil(accountId) && hasWebsiteProduct,
  });

export const useLegacyCustomerAccounts = (
  customerId: number,
  params: Record<string, string | number>,
) =>
  useQuery({
    queryKey: accountKeys.legacyCustomerAccounts(customerId, params),
    queryFn: legacyFetchCustomerAccounts,
    enabled: !isNil(customerId),
    staleTime: 6000,
  });

export const useCreateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, TError | undefined, z.infer<typeof createAccountSchema>>({
    mutationFn: (newAccount) =>
      fetch(`${getHost()}/accounts`, {
        method: "POST",
        body: JSON.stringify(newAccount),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["accounts"] });
    },
  });
};

const fetchAccountCheck = async (params: AccountCheckParams) => {
  const { name, placeId } = params;
  return requestAndValidate({
    invalidMessage: "accountcheck data not up to spec",
    requestUrl: `${getHost()}/accounts/check?name=${name}${placeId ? `&place_id=${placeId}` : ""}`,
    schema: accountCheckSchema,
  });
};

export const useAccountCheck = () => {
  return useMutation({
    mutationFn: fetchAccountCheck,
  });
};

export const useAccountFiles = (accountId: number, params: Partial<AccountFilesParams>) => {
  return useQuery({
    queryKey: accountKeys.accountFiles(accountId, params),
    queryFn: fetchAccountFiles,
    enabled: validId(accountId),
  });
};

export const useUploadAccountFile = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    TError | undefined,
    {
      accountId: string;
      formData: FormData;
    }
  >({
    mutationFn: ({ accountId, formData }) => {
      return fetch(`${getHost()}/accounts/${accountId}/files`, {
        headers: {
          Accept: "application/json",
        },
        method: "POST",
        body: formData,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["accountsFiles"] });
    },
  });
};

export const useDeleteAccountFile = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Response,
    TError | undefined,
    {
      accountId: string;
      fileId: string | null;
    }
  >({
    mutationFn: ({ accountId, fileId }) =>
      fetch(`${getHost()}/accounts/${accountId}/files/${fileId}`, {
        method: "DELETE",
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["accountsFiles"] });
    },
  });
};
