import moment from "moment";
import { stringify } from "query-string";

export function capitalizeFirstLetter(string) {
  if (!string || typeof string !== "string") {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const replaceAll = (text, searchStr, replaceStr) => {
  // escape regexp special characters in search string
  const strEsc = searchStr.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

  return text.replace(new RegExp(strEsc, "g"), replaceStr);
};

export function toSnakeCase(string) {
  let s;
  s = string.replace(/[^\w\s]/g, "");
  s = s.replace(/\s+/g, " ");
  return s.toLowerCase().split(" ").join("_");
}

export function createUrl(url, params) {
  return new URL(`${url}?${stringify(params)}`);
}

export function stringifyFilterParams(params) {
  return stringify(
    Object.keys(params || {}).reduce(
      (acc, param) => ({ ...acc, [`filter[${param}]`]: params[param] }),
      {},
    ),
  );
}

// High-order function that returns a function that will,
// based on the quantity return the singular or plural version of a word
export const inflect =
  (singular, plural = `${singular}s`) =>
  (quantity) =>
    Math.abs(quantity) === 1 ? singular : plural;

// Email should be masked on the back-end, but just in case
export function maskEmail(string, maskLength) {
  const [username, domain] = string.split("@");
  let newUsername = "";
  if (username.length <= maskLength) {
    newUsername = Array(username.length).fill("*").join("");
  } else {
    newUsername = username.substring(0, maskLength).padEnd(username.length, "*");
  }
  return `${newUsername}@${domain}`;
}

export function formatNumber(num) {
  switch (true) {
    // convert to K for number from > 1000 < 1 million
    case num > 999 && num < 1000000:
      return `${(num / 1000).toFixed(1)}K`;
    // convert to M for number from > 1 million
    case num > 1000000:
      return `${(num / 1000000).toFixed(1)}M`;
    default:
      return num.toFixed(0);
  }
}
/**
 *
 * @param {string|null|number|undefined} period
 * @param {string|undefined} language
 * @param {function} t
 * @param {boolean} useNewTranslationFunction
 * @returns
 */
export function getLocalDuration(
  period = "1 months",
  language,
  t,
  useNewTranslationFunction = false,
) {
  if (period === null) {
    return t("none");
  }
  const [value, unit] = String(period)?.split(" ") ?? [String(period), ""];
  if (Number.isInteger(period)) {
    if (useNewTranslationFunction) {
      return period === 1 ? t("1 day") : t("{{amountOfDays}} days", { amountOfDays: period });
    }
    return period === 1 ? t("1 day") : t("{amountOfDays} days", { amountOfDays: period });
  }
  if (value === "1") {
    return unit?.includes("year") ? t("1 year") : t("1 month");
  }
  if (unit?.includes("year")) {
    return moment
      .duration(value, "years")
      .locale(language ?? "en")
      .humanize();
  }
  return moment
    .duration(value, "months")
    .locale(language ?? "en")
    .humanize();
}
