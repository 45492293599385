import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

import { CardHeader } from ".";

const CardHeaderAction = (props) => {
  const { children, className, action, ...otherProps } = props;
  const classes = cx("d-flex justify-content-between", {}, className);
  return (
    <CardHeader className={classes} {...otherProps}>
      <h6 className="font-weight-bold mb-0">{children}</h6>
      {action}
    </CardHeader>
  );
};

CardHeaderAction.propTypes = {
  children: PropTypes.node,
  action: PropTypes.node,
  className: PropTypes.string,
};

CardHeaderAction.defaultProps = {
  children: "",
  action: "",
  className: "",
};

export default CardHeaderAction;
