const langCodeMapping = {
  af: "Afrikaans",
  am: "Amharisch",
  an: "Aragonesisch",
  ar: "Arabisch",
  as: "Assamesisch",
  az: "Aserbaidschanisch",
  be: "Wei\u00dfrussisch",
  bg: "Bulgarisch",
  bn: "Bengalisch",
  br: "Bretonisch",
  bs: "Bosnisch",
  ca: "Katalanisch",
  cs: "Tschechisch",
  cy: "Walisisch",
  da: "D\u00e4nisch",
  de: "Deutsch",
  dz: "Dzongkha",
  el: "Griechisch",
  en: "Englisch",
  eo: "Esperanto",
  es: "Spanisch",
  et: "Estnisch",
  eu: "Baskisch",
  fa: "Persisch",
  fi: "Finnisch",
  fo: "F\u00e4r\u00f6isch",
  fr: "Franz\u00f6sisch",
  ga: "Irisch",
  gl: "Galicisch",
  gu: "Gujarati",
  he: "Hebr\u00e4isch",
  hi: "Hindi",
  hr: "Kroatisch",
  ht: "Haiti-Kreolisch",
  hu: "Ungarisch",
  hy: "Armenisch",
  id: "Indonesisch",
  is: "Isl\u00e4ndisch",
  it: "Italienisch",
  ja: "Japanisch",
  jv: "Javanisch",
  ka: "Georgisch",
  kk: "Kasachisch",
  km: "Khmer",
  kn: "Kannada",
  ko: "Koreanisch",
  ku: "Kurdisch",
  ky: "Kirgisisch",
  la: "Latein",
  lb: "Luxemburgisch",
  lo: "Laotisch",
  lt: "Litauisch",
  lv: "Lettisch",
  mg: "Madagassisch",
  mk: "Mazedonisch",
  ml: "Malayalam",
  mn: "Mongolisch",
  mr: "Marathi",
  ms: "Malaiisch",
  mt: "Maltesisch",
  nb: "Norwegisch Bokm\u00e5l",
  ne: "Nepalesisch",
  nl: "Niederl\u00e4ndisch",
  nn: "Norwegisch Nynorsk",
  no: "Norwegisch",
  oc: "Okzitanisch",
  or: "Oriya",
  pa: "Punjabi",
  pl: "Polnisch",
  ps: "Paschtu",
  pt: "Portugiesisch",
  qu: "Quechua",
  ro: "Rum\u00e4nisch",
  ru: "Russisch",
  rw: "Kinyarwanda",
  se: "Nordsamisch",
  si: "Singhalesisch",
  sk: "Slowakisch",
  sl: "Slowenisch",
  sq: "Albanisch",
  sr: "Serbisch",
  sv: "Schwedisch",
  sw: "Suaheli",
  ta: "Tamil",
  te: "Telugu",
  th: "Thail\u00e4ndisch",
  tl: "Tagalog",
  tr: "T\u00fcrkisch",
  ug: "Uigurisch",
  uk: "Ukrainisch",
  ur: "Urdu",
  vi: "Vietnamesisch",
  vo: "Volap\u00fck",
  wa: "Wallonisch",
  xh: "Xhosa",
  zh: "Chinesisch",
  zu: "Zulu",
};

export default langCodeMapping;
