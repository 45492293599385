import { z } from "zod";

import paginationSchema from "./utilSchemas";

export const partnerUserSchema = z.object({
  created_at: z.string().nullable(),
  email: z.string().email(),
  first_name: z.string().min(1, "First name is required"),
  id: z.number().transform((val) => String(val)),
  is_primary: z.boolean(),
  language: z.string(),
  last_name: z.string().nullable(),
  role: z.union([z.literal("account_manager"), z.literal("super_admin")]),
});

export const partnerUsersSchema = z.object({
  data: z.array(partnerUserSchema.omit({ language: true })),
  meta: paginationSchema,
});

export const accountUserSchema = z.object({
  created_at: z.string().nullable(),
  email: z.string(),
  first_name: z.string(),
  id: z.number().transform((val) => String(val)),
  is_primary: z.boolean(),
  language: z.string(),
  last_name: z.string().nullable(),
  locations: z.array(z.object({ id: z.number(), name: z.string() })).optional(),
  role: z.union([z.literal("account_manager"), z.literal("location_manager")]),
});

export const accountUsersSchema = z.object({
  data: z.array(accountUserSchema.omit({ language: true, locations: true, updated_at: true })),
  meta: paginationSchema,
});

const baseCreateUserSchema = partnerUserSchema
  .omit({ created_at: true, is_primary: true, updated_at: true, id: true })
  .extend({
    send_set_password: z.boolean().nullish(),
    password: z.string().nullish(),
  })
  .partial({ last_name: true });

export const createPartnerUserSchema = baseCreateUserSchema
  .extend({
    language: z.string({
      required_error: "Language is required",
      invalid_type_error: "Language is required",
    }),
  })
  .refine((data) => data.send_set_password || data.password, {
    message: "Password is required",
    path: ["password"],
  });

export const createAccountUserSchema = baseCreateUserSchema
  .extend({
    locations: z.array(z.object({ id: z.number(), name: z.string() })).nullish(),
    locations_ids: z.array(z.number()).nullish(),
    role: z.union([z.literal("account_manager"), z.literal("location_manager")]),
    language: z.string({
      required_error: "Language is required",
      invalid_type_error: "Language is required",
    }),
  })
  .refine((data) => data.send_set_password || data.password, {
    message: "Password is required",
    path: ["password"],
  });

export const bspSchema = z.object({
  data: z
    .object({
      id: z.string(),
      name: z.string(),
      is_custom: z.boolean(),
    })
    .array(),
  meta: z
    .object({
      current_page: z.number().nullable(),
      per_page: z.number().nullable(),
      total: z.number().nullable(),
    })
    .nullable(),
});

export default bspSchema;
