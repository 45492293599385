import { isTeyaDomain } from "actions/fetch";
import listingsIcon from "assets/icons/products/listings.svg";
import listingsBigIcon from "assets/icons/products/listingsBigIcon.svg";
import reviewsIcon from "assets/icons/products/reviews.svg";
import reviewsBigIcon from "assets/icons/products/reviewsBig.svg";
import websiteOptimizerIcon from "assets/icons/products/websiteOptimizer.svg";
import websiteOptimizerBigIcon from "assets/icons/products/websiteOptimizerBig.svg";
import wifiMarketingIcon from "assets/icons/products/wifiMarketing.svg";
import wifiMarketingBigIcon from "assets/icons/products/wifiMarketingBig.svg";
import { TFunction } from "react-i18next";
import { allowedProductsSchema } from "schemas/meSchema";
import { z } from "zod";

export const productName = {
  LISTING: "listing",
  SOREVIEW: "soreview",
  WEBOPTIMIZER: "weboptimizer",
  SOWIFI: "sowifi",
} as const;

export const productNameV3 = {
  LISTINGS: "listings",
  REVIEWS: "reviews",
  WEBSITEOPTIMIZER: "website-optimizer",
  WIFI: "wifi",
} as const;

export type AllowedProducts = z.infer<typeof allowedProductsSchema>;
export type ProductNamesV3Type = (typeof productNameV3)[keyof typeof productNameV3];
export type ProductNamesV2Type = (typeof productName)[keyof typeof productName];

export const getV2ProductName = (v3ProductName: ProductNamesV3Type) => {
  enum productNameKeyMap {
    listings = "LISTING",
    reviews = "SOREVIEW",
    "website-optimizer" = "WEBOPTIMIZER",
    wifi = "SOWIFI",
  }

  const v2Key = productNameKeyMap[v3ProductName];
  return productName[v2Key];
};

export const getV3ProductName = (v2ProductName: ProductNamesV2Type) => {
  enum productNameV2ToV3KeyMap {
    listing = "LISTINGS",
    soreview = "REVIEWS",
    "weboptimizer" = "WEBSITEOPTIMIZER",
    sowifi = "WIFI",
  }

  const v3Key = productNameV2ToV3KeyMap[v2ProductName];
  return productNameV3[v3Key];
};

export const isSowifi = (productId: string) => productId === productNameV3.WIFI;
export const isReviews = (productId: string) => productId === productNameV3.REVIEWS;
export const isListings = (productId: string) => productId === productNameV3.LISTINGS;
export const isWebsiteOptimizer = (productId: string) =>
  productId === productNameV3.WEBSITEOPTIMIZER;

export const productsList = (t: TFunction) =>
  [
    {
      id: productName.LISTING,
      v3Id: productNameV3.LISTINGS,
      title: t("Listings"),
      description: t("Instantly update business details on +50 platforms"),
      icon: listingsIcon,
      iconBig: listingsBigIcon,
      link: (locationId: number | string, customerId: number | string) =>
        isTeyaDomain()
          ? `/admin/app/location/${locationId}/listings/results`
          : `/admin/app/customer/${customerId}/location/${locationId}/listings/details`,
      onboardingUrl: (locationId: number | string, customerId: number | string) => {
        return isTeyaDomain()
          ? `/admin/app/onboarding/${locationId}/store`
          : `/admin/app/customer/${customerId}/location/${locationId}/listings/onboarding`;
      },
      enableNowUrl: (accountId: number | string, locationId: number) =>
        `/admin/app/accounts/${accountId}/products/add/${productNameV3.LISTINGS}?locationId=${locationId}`,
      isAnchor: false,
    },
    {
      id: productName.SOREVIEW,
      v3Id: productNameV3.REVIEWS,
      title: t("Reviews"),
      description: t("Boost your start ratings and search ranking"),
      icon: reviewsIcon,
      iconBig: reviewsBigIcon,
      link: (locationId: number | string, customerId: number | string) =>
        `/admin/app/customer/${customerId}/location/${locationId}/reviews/overview`,
      onboardingUrl: (locationId: number | string, customerId: number | string) =>
        `/admin/app/customer/${customerId}/location/${locationId}/reviews/onboarding`,
      enableNowUrl: (accountId: number | string, locationId: number) =>
        `/admin/app/accounts/${accountId}/products/add/${productNameV3.REVIEWS}?locationId=${locationId}`,
      isAnchor: false,
    },
    {
      id: productName.WEBOPTIMIZER,
      v3Id: productNameV3.WEBSITEOPTIMIZER,
      title: t("Website Optimizer"),
      description: t("Great looking web experience to your visitors"),
      icon: websiteOptimizerIcon,
      iconBig: websiteOptimizerBigIcon,
      link: (websiteId: number | string) => `/admin/website-optimizer/${websiteId}`,
      isAnchor: true,
      enableNowUrl: null,
    },
    {
      id: productName.SOWIFI,
      v3Id: productNameV3.WIFI,
      title: t("WIFI Marketing"),
      description: t("Returning customers are your bread & butter"),
      icon: wifiMarketingIcon,
      iconBig: wifiMarketingBigIcon,
      link: (locationId: number | string) => `/admin/reports?search_locations=${locationId}`,
      isAnchor: true,
      enableNowUrl: null,
    },
  ] as const;

export default productName;
