import { useState, useEffect } from "react";

import debounce from "lodash/debounce";

import { breakpoints, baseFontSize } from "@carbon/layout";

export const useGetWindowSize = (debounceTime = 250) => {
  const [windowSizes, setWindowSizes] = useState([window.innerWidth, window.innerHeight]);
  const [width, height] = windowSizes;
  useEffect(() => {
    const updateWindowSizes = () => {
      setWindowSizes([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", debounce(updateWindowSizes, debounceTime));
    return () => {
      window.removeEventListener("resize", debounce(updateWindowSizes, debounceTime));
    };
  }, [debounceTime, height, width]);
  return { width, height };
};

export const carbonBreakpoints = breakpoints;

export const useBreakpointUp = (breakpointKey, debounceTime = 250) => {
  const { width } = useGetWindowSize(debounceTime);
  if (!breakpointKey) {
    throw new Error("useBreakpointUp must be used with a breakpoint");
  }
  const carbonBreakpoint = breakpoints[breakpointKey];
  if (!carbonBreakpoint) {
    throw new Error("used breakpoint not one of the carbon breakpoints");
  }
  // convert rem value to px using carbon baseFontSize
  const pxWidth = carbonBreakpoint.width.split("rem")[0] * baseFontSize;
  return width > pxWidth;
};

export const useBreakpointDown = (breakpointKey, debounceTime = 250) => {
  const { width } = useGetWindowSize(debounceTime);
  if (!breakpointKey) {
    throw new Error("useBreakpointDown must be used with a breakpoint");
  }
  const carbonBreakpoint = breakpoints[breakpointKey];
  if (!carbonBreakpoint) {
    throw new Error("used breakpoint not one of the carbon breakpoints");
  }
  // convert rem value to px using carbon baseFontSize
  const pxWidth = carbonBreakpoint.width.split("rem")[0] * baseFontSize;
  return width < pxWidth;
};

export default useGetWindowSize;
