import React from "react";

import PropTypes from "prop-types";

import PopupWrapper from "./PopupWrapper";

const PopupPage = ({ children }) => (
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <PopupWrapper>{children}</PopupWrapper>
      </div>
    </div>
  </div>
);

PopupPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PopupWrapper;
